/* eslint-disable */
import * as types from "./graphql";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	"\n\tmutation StoreContentPreviewData($path: String!, $data: String!) {\n\t\tstoreContentPreview(data: $data, path: $path) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.StoreContentPreviewDataDocument,
	"\n\tfragment ApplePayPaymentMethod on PaymentMethod {\n\t\tid\n\t\tname\n\t\tprovider\n\t\textra {\n\t\t\t... on ApplePayExtra {\n\t\t\ttype\n\t\t\tbrands\n\t\t\tconfiguration {\n\t\t\t\tmerchantId\n\t\t\t\tmerchantName\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ApplePayPaymentMethodFragmentDoc,
	"\n\tmutation ValidateApplePayPayment($storeContext: StoreContextInput!, $paymentContext: ApplePayPaymentContextInput!) {\n\t\tvalidateApplePayPayment(storeContext: $storeContext, paymentContext: $paymentContext) {\n\t\t\tdata\n\t\t}\n\t}\n":
		types.ValidateApplePayPaymentDocument,
	"\n\tmutation CreateApplePayOrder($storeContext: StoreContextInput!) {\n\t\tcheckoutComplete(storeContext: $storeContext) {\n\t\t\torderId\n\t\t\torderNumber\n\t\t}\n\t}\n":
		types.CreateApplePayOrderDocument,
	"\n\tmutation ApplePayPaymentCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$paymentContext: PaymentContextInput!\n\t) {\n\t\tcreatePayment(\n\t\t\tstoreContext: $storeContext\n\t\t\tpaymentContext: $paymentContext\n\t\t) {\n\t\t\tredirectURL\n\t\t}\n\t}\n":
		types.ApplePayPaymentCreateDocument,
	"\n\tmutation UpdateCheckoutAddress(\n\t\t$storeContext: StoreContextInput!\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t\t$setShippingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setShippingAddress\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t) {\n\t\t\t...CheckoutCartFragment\n\t\t}\n\t}\n":
		types.UpdateCheckoutAddressDocument,
	"\n\tquery CheckoutCustomerExists($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerExists(storeContext: $storeContext, email: $email) {\n\t\t\tresult\n\t\t}\n\t}\n":
		types.CheckoutCustomerExistsDocument,
	"\n\tmutation CustomerCreateEmailStep($storeContext: StoreContextInput!, $customer: CustomerCreateInput!) {\n\t\tcustomerCreate(storeContext: $storeContext, customer: $customer) {\n\t\t\tcreatedAt\n\t\t}\n\t}\n":
		types.CustomerCreateEmailStepDocument,
	"\n\tmutation checkoutCartUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t\t$setShippingAddress: AddressInput\n\t\t$setPaymentMethod: PaymentMethodInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setShippingAddress\n\t\t\tsetPaymentMethod: $setPaymentMethod\n\t\t) {\n\t\t\t...CheckoutCartFragment\n\t\t}\n\t}\n":
		types.CheckoutCartUpdateDocument,
	"\n  query GetCartForCheckout($storeContext: StoreContextInput!) {\n    cart(storeContext: $storeContext) {\n      ...CheckoutCartFragment\n\t\t}\n\t}\n":
		types.GetCartForCheckoutDocument,
	"\n\tfragment CheckoutCartFragment on Cart {\n\t\tid {\n\t\t\tversion\n\t\t\tid\n\t\t}\n\t\tavailablePaymentMethods(device: web) {\n\t\t\tname\n\t\t\tpaymentMethod {\n\t\t\t\t...ApplePayPaymentMethod\n\t\t\t}\n\t\t}\n\t\tbillingAddress {\n\t\t\tid\n\t\t}\n\t\tcustomerEmail\n\t\tlineItems {\n\t\t\tid\n\t\t\tproductName\n\t\t\tquantity\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\ttotal {\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.CheckoutCartFragmentFragmentDoc,
	"\n\tfragment FooterInfoPagesFragment on Site {\n\t\tinfoPages {\n\t\t\tprivacy {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t\tterms {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t\tcookies {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n":
		types.FooterInfoPagesFragmentFragmentDoc,
	"\n\tquery GetSimpleSite(\n\t\t$storeContext: StoreContextInput!\n\t\t$version: ContentVersion!\n\t\t$previewID: String\n\t) {\n\t\tsite(\n\t\t\tstoreContext: $storeContext\n\t\t\tversion: $version\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...HeaderFragment\n\t\t\t...FooterInfoPagesFragment\n\t\t}\n\t}\n":
		types.GetSimpleSiteDocument,
	"\n\tfragment CheckoutSummaryFragment on Cart {\n\t\tid {\n\t\t\tid\n\t\t\tversion\n\t\t}\n\n\t\tlineItems {\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\taddedAt\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t}\n":
		types.CheckoutSummaryFragmentFragmentDoc,
	"\n\tfragment BillingAddressFragment on Address {\n\t\tid\n\t\temail\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n":
		types.BillingAddressFragmentFragmentDoc,
	"\n\tquery GetCheckoutInformation($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...CheckoutSummaryFragment\n\n\t\t\tbillingAddress {\n\t\t\t\t...BillingAddressFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.GetCheckoutInformationDocument,
	"\n\tmutation UpdateCheckoutInformation(\n\t\t$storeContext: StoreContextInput!\n\t\t$setBillingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setBillingAddress\n\t\t) {\n\t\t\tbillingAddress {\n\t\t\t\t...BillingAddressFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.UpdateCheckoutInformationDocument,
	"\n\tquery CustomerExists($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerExists(storeContext: $storeContext, email: $email) {\n\t\t\tresult\n\t\t}\n\t}\n":
		types.CustomerExistsDocument,
	"\n\tquery GetCheckoutLogin($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...CheckoutSummaryFragment\n\n\t\t\tcustomerEmail\n\n\t\t\tbillingAddress {\n\t\t\t\t...BillingAddressFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.GetCheckoutLoginDocument,
	"\n\tmutation UpdateCheckoutLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t) {\n\t\t\tid {\n\t\t\t\tid\n\t\t\t\tversion\n\t\t\t}\n\t\t}\n\t}\n":
		types.UpdateCheckoutLoginDocument,
	"\n\tmutation CheckoutCustomerLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerLogin(\n\t\t\tstoreContext: $storeContext\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CheckoutCustomerLoginDocument,
	"\n\tquery GetCheckoutPayment($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...CheckoutSummaryFragment\n\n\t\t\tavailablePaymentMethods(device: web) {\n\t\t\t\tname\n\t\t\t\tpaymentMethod {\n\t\t\t\t\t...PaymentMethod\n\t\t\t\t}\n\t\t\t}\n\t\t\tselectedPaymentMethod {\n\t\t\t\t...PaymentMethod\n\t\t\t}\n\t\t}\n\t}\n":
		types.GetCheckoutPaymentDocument,
	"\n\tfragment PaymentMethod on PaymentMethod {\n\t\tid\n\t\tname\n\t\tprovider\n\t\tissuers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n":
		types.PaymentMethodFragmentDoc,
	"\n\tmutation PaymentCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$paymentContext: PaymentContextInput!\n\t) {\n\t\tcreatePayment(\n\t\t\tstoreContext: $storeContext\n\t\t\tpaymentContext: $paymentContext\n\t\t) {\n\t\t\tredirectURL\n\t\t}\n\t}\n":
		types.PaymentCreateDocument,
	"\n\tmutation CreateOrder($storeContext: StoreContextInput!) {\n\t\tcheckoutComplete(storeContext: $storeContext) {\n\t\t\torderId\n\t\t\torderNumber\n\t\t}\n\t}\n":
		types.CreateOrderDocument,
	"\n\tfragment FacetsFragment on Facet {\n\t\t...OptionsFacetFragment\n\t\t...RangeFacetFragment\n\t\t...ToggleFacetFragment\n\t}\n":
		types.FacetsFragmentFragmentDoc,
	"\n\tfragment OptionsFacetFragment on OptionsFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\tkey\n\t\t\tlabel\n\t\t\tcount\n\t\t\tselected\n\t\t}\n\t}\n":
		types.OptionsFacetFragmentFragmentDoc,
	"\n\tfragment RangeFacetFragment on RangeFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\tmin\n\t\tmax\n\t\tselectedMin\n\t\tselectedMax\n\t}\n":
		types.RangeFacetFragmentFragmentDoc,
	"\n\tfragment ToggleFacetFragment on ToggleFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\tkey\n\t\t\tlabel\n\t\t\tcount\n\t\t\tselected\n\t\t}\n\t}\n":
		types.ToggleFacetFragmentFragmentDoc,
	"\n\tfragment ProductListingConfig on ProductListingConfig {\n\t\tcategoryId\n\t\tdefaultOrder\n\t\tprefilters {\n\t\t\tkey\n\t\t\tvalue\n\t\t}\n\t}\n":
		types.ProductListingConfigFragmentDoc,
	"\n\tfragment ProductsResult on ProductsResult {\n\t\ttotal\n\t\tresults {\n\t\t\t...ProductCard\n\t\t}\n\t\tfacets {\n\t\t\t...FacetsFragment\n\t\t}\n\t}\n":
		types.ProductsResultFragmentDoc,
	"\n\tquery GetCategoryPage($storeContext: StoreContextInput!, $path: String!) {\n\t\tcategoryPageByPath(storeContext: $storeContext, path: $path) {\n\t\t\t...CategoryPageFragment\n\t\t}\n\t}\n":
		types.GetCategoryPageDocument,
	"\n\tfragment CategoryPageFragment on CategoryPage {\n\t\tmeta {\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tkeywords\n\t\t}\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\tcategory {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t}\n\t\tproductListingConfig {\n\t\t\t...ProductListingConfig\n\t\t}\n\t}\n":
		types.CategoryPageFragmentFragmentDoc,
	"\n\tquery GetProductsForCategory(\n\t\t$storeContext: StoreContextInput!\n\t\t$filters: [FacetFilterInput!]\n\t\t$prefilters: [FilterConditionInput!]\n\t\t$categoryId: String!\n\t\t$searchTerm: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t\t$sort: ProductSortOrder!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tfilters: $filters\n\t\t\tprefilters: $prefilters\n\t\t\tcategoryId: $categoryId\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t\tsort: $sort\n\t\t) {\n\t\t\t...ProductsResult\n\t\t}\n\t}\n":
		types.GetProductsForCategoryDocument,
	"\n\tquery GetProductListingPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$path: String!\n\t\t$previewID: String\n\t) {\n\t\tproductListingPage(\n\t\t\tstoreContext: $storeContext\n\t\t\tpath: $path\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...ProductListingPage\n\t\t}\n\t}\n":
		types.GetProductListingPageDocument,
	"\n\tfragment ProductListingPage on ProductListingPage {\n\t\t__typename\n\t\tid\n\t\tname\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tseo {\n\t\t\t__typename\n\t\t\ttitle\n\t\t\tdescription\n\t\t\togTitle\n\t\t\togDescription\n\t\t\togImage\n\t\t\ttwitterTitle\n\t\t\ttwitterDescription\n\t\t\ttwitterImage\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\t\tproductListingConfig {\n\t\t\t...ProductListingConfig\n\t\t}\n\t\tteaserRow {\n\t\t\t...TeaserRowFragment\n\t\t}\n\t}\n":
		types.ProductListingPageFragmentDoc,
	"\n\tquery GetProductsOld(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t) {\n\t\t\t...ProductsResult\n\t\t}\n\t}\n":
		types.GetProductsOldDocument,
	"\n\tquery GetProducts(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t) {\n\t\t\t...ProductsResult\n\t\t}\n\t}\n":
		types.GetProductsDocument,
	"\n\tfragment FooterMenuFragment on MenuBlock {\n\t\tid\n\t\tlabel\n\t\tmenuLinks {\n\t\t\tname\n\t\t\turl\n\t\t}\n\t}\n":
		types.FooterMenuFragmentFragmentDoc,
	"\n\tfragment PaymentFragment on Site {\n\t\tpaymentMethods {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t}\n":
		types.PaymentFragmentFragmentDoc,
	"\n\tfragment FooterSocialsFragment on Site {\n\t\tsocials {\n\t\t\tfacebook\n\t\t\tinstagram\n\t\t\tlinkedIn\n\t\t\ttwitter\n\t\t\tyoutube\n\t\t}\n\t}\n":
		types.FooterSocialsFragmentFragmentDoc,
	"\n\tquery CustomerBusinessUnitsQuery($storeContext: StoreContextInput!) {\n\t\tcustomer(storeContext: $storeContext) {\n\t\t\t...BusinessUnits\n\t\t}\n\t}\n":
		types.CustomerBusinessUnitsQueryDocument,
	"\n\tquery GetCartQuantity($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tlineItems {\n\t\t\t\tquantity\n\t\t\t}\n\t\t}\n\t}\n":
		types.GetCartQuantityDocument,
	"\n\tfragment HeaderFragment on Site {\n\t\tlogo {\n\t\t\talt\n\t\t\tfilename\n\t\t}\n\n\t\t...HeaderInfoPagesFragment\n\t\t...MainMenuFragment\n\t}\n":
		types.HeaderFragmentFragmentDoc,
	"\n\tfragment MainMenuFragment on Site {\n\t\tmainMenu {\n\t\t\t...TopMenuItemFragment\n\t\t}\n\t}\n":
		types.MainMenuFragmentFragmentDoc,
	"\n\tfragment TopMenuItemFragment on MenuItem {\n\t\t...MenuItemFieldsFragment\n\t\tsubMenuItems {\n\t\t\t...SubMenuItemFragment\n\t\t}\n\t}\n":
		types.TopMenuItemFragmentFragmentDoc,
	"\n\tfragment SubMenuItemFragment on MenuItem {\n\t\t...MenuItemFieldsFragment\n\t\tsubMenuItems {\n\t\t\t...MenuItemFieldsFragment\n\t\t}\n\t}\n":
		types.SubMenuItemFragmentFragmentDoc,
	"\n\tfragment MenuItemFieldsFragment on MenuItem {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\ttarget {\n\t\t\t... on ResourceLink {\n\t\t\t\turl\n\t\t\t\t__typename\n\t\t\t}\n\t\t\t... on ProductCategory {\n\t\t\t\tname\n\t\t\t\tpath\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t}\n":
		types.MenuItemFieldsFragmentFragmentDoc,
	"\n\tfragment HeaderInfoPagesFragment on Site {\n\t\tinfoPages {\n\t\t\tbusiness {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t\tservice {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n":
		types.HeaderInfoPagesFragmentFragmentDoc,
	"\n\tquery GetSite(\n\t\t$storeContext: StoreContextInput!\n\t\t$version: ContentVersion!\n\t\t$previewID: String\n\t) {\n\t\tsite(\n\t\t\tstoreContext: $storeContext\n\t\t\tversion: $version\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...Site\n\t\t}\n\t}\n":
		types.GetSiteDocument,
	"\n\tfragment Site on Site {\n\t\t...HeaderFragment\n\t\t...PaymentFragment\n\t\t...FooterInfoPagesFragment\n\t\t...FooterSocialsFragment\n\n\t\tfooterMenu {\n\t\t\t...FooterMenuFragment\n\t\t}\n\t}\n":
		types.SiteFragmentDoc,
	"\n\tmutation CustomerLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerLogin(\n\t\t\tstoreContext: $storeContext\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerLoginDocument,
	"\n\tmutation CustomerPasswordResetRequest($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerPasswordResetRequest(storeContext: $storeContext, email: $email)\n\t}\n":
		types.CustomerPasswordResetRequestDocument,
	"\n\tmutation CustomerPasswordReset(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$token: String!\n\t) {\n\t\tcustomerPasswordReset(storeContext: $storeContext, password: $password, token: $token) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerPasswordResetDocument,
	"\n\tmutation CustomerCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$customer: CustomerCreateInput!\n\t) {\n\t\tcustomerCreate(storeContext: $storeContext, customer: $customer) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerCreateDocument,
	"\n\tfragment BusinessUnits on Customer {\n\t\tbusinessUnits {\n\t\t\tid\n\t\t\tname\n\t\t\tstores {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n":
		types.BusinessUnitsFragmentDoc,
	"\n\tmutation SetBusinessContext(\n\t\t$businessUnitId: ID!\n\t\t$storeKey: String!\n\t) {\n\t\tsetActiveBusinessContext(\n\t\t\tbusinessUnitId: $businessUnitId\n\t\t\tstoreKey: $storeKey\n\t\t)\n\t}\n":
		types.SetBusinessContextDocument,
	"\n\tquery PreviewOrdersQuery($storeContext: StoreContextInput!) {\n\t\torders(storeContext: $storeContext, pageSize: 3) {\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\t...OrderCardFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.PreviewOrdersQueryDocument,
	"\n\tmutation CustomerAddressCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$address: AddressInput!\n\t) {\n\t\tcustomerAddressCreate(\n\t\t\tstoreContext: $storeContext\n\t\t\taddress: $address\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerAddressCreateDocument,
	"\n\tfragment CustomerAddressFragment on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n":
		types.CustomerAddressFragmentFragmentDoc,
	"\n\tmutation CustomerAddressUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$addressId: ID!\n\t\t$address: AddressInput!\n\t) {\n\t\tcustomerAddressUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\taddressId: $addressId\n\t\t\taddress: $address\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerAddressUpdateDocument,
	"\n\tquery CustomerAddressQuery($storeContext: StoreContextInput!) {\n\t\tcustomer(storeContext: $storeContext) {\n\t\t\tgivenName\n\t\t\taddresses {\n\t\t\t\tid\n\t\t\t\temail\n\t\t\t\tsalutation\n\t\t\t\tgivenName\n\t\t\t\tfamilyName\n\t\t\t\tstreetName\n\t\t\t\tstreetNumber\n\t\t\t\tstreetNumberSuffix\n\t\t\t\tpostalCode\n\t\t\t\tcity\n\t\t\t\tphone\n\t\t\t\tcountry\n\t\t\t\tcompany\n\t\t\t}\n\t\t}\n\t}\n":
		types.CustomerAddressQueryDocument,
	"\n\tfragment OrderDetailsFragment on Order {\n\t\tid {\n\t\t\tid\n\t\t}\n\t\tlineItems {\n\t\t\t...OrderLineItemFragment\n\t\t}\n\t\t...OrderCardFragment\n\t\t...OrderMetadataFragment\n\t\t...OrderTotalsFragment\n\t}\n":
		types.OrderDetailsFragmentFragmentDoc,
	"\n\tfragment OrderLineItemFragment on LineItem {\n\t\tproductName\n\t\tquantity\n\t\tprice {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n":
		types.OrderLineItemFragmentFragmentDoc,
	"\n\tfragment OrderMetadataFragment on Order {\n\t\tcreatedAt\n\t\torderNumber\n\t\torderStatus\n\t}\n":
		types.OrderMetadataFragmentFragmentDoc,
	"\n\tfragment OrderTotalsFragment on Order {\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.OrderTotalsFragmentFragmentDoc,
	"\n\tquery OrderDetailQuery($storeContext: StoreContextInput!, $orderId: String!) {\n\t\torder(storeContext: $storeContext, orderId: $orderId) {\n\t\t\t...OrderDetailsFragment\n\t\t}\n\t}\n":
		types.OrderDetailQueryDocument,
	"\n\tfragment OrderCardFragment on Order {\n\t\tid {\n\t\t\tid\n\t\t}\n\t\torderNumber\n\t\torderStatus\n\t\tcreatedAt\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tlineItems {\n\t\t\tid\n\t\t\tvariant {\n\t\t\t\tprimaryImage {\n\t\t\t\t\talt\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.OrderCardFragmentFragmentDoc,
	"\n\tquery OverviewOrdersQuery($storeContext: StoreContextInput!) {\n\t\torders(storeContext: $storeContext) {\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\t...OrderCardFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.OverviewOrdersQueryDocument,
	"\n\tfragment BillingAddressDisplayFragment on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n":
		types.BillingAddressDisplayFragmentFragmentDoc,
	"\n\tquery GetCustomer($storeContext: StoreContextInput!) {\n\t\tcustomer(storeContext: $storeContext) {\n\t\t\t...Customer\n\t\t}\n\t}\n\tfragment Customer on Customer {\n\t\tgivenName\n\t\temail\n\t\tvatNumber\n\t\tbillingAddress {\n\t\t\t...Address\n\t\t\t...BillingAddressDisplayFragment\n\t\t}\n\t\taddresses {\n\t\t\t...Address\n\t\t}\n\t}\n\tfragment Address on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n":
		types.GetCustomerDocument,
	"\n\tmutation CustomerBillingAddressUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$billingAddress: AddressInput!\n\t) {\n\t\tcustomerInformationUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tbillingAddress: $billingAddress\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerBillingAddressUpdateDocument,
	"\n\tmutation CustomerEmailUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerEmailUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerEmailUpdateDocument,
	"\n\tmutation CustomerPasswordUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$newPassword: String!\n\t) {\n\t\tcustomerPasswordUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tpassword: $password\n\t\t\tnewPassword: $newPassword\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n":
		types.CustomerPasswordUpdateDocument,
	"\nfragment CartOverviewFragment on Cart {\n\tlineItems {\n\t\tid\n\t...LineItemFragment\n\t}\n\ttotal {\n\t\tnet {\n\t\t\t...PriceFragment\n\t\t}\n\t}\n\t...CartSummaryFragment\n}\n\t":
		types.CartOverviewFragmentFragmentDoc,
	"\n\tfragment CartSummaryFragment on Cart {\n\t\t...RemoveDiscountCodeButtonFragment\n\t\tlineItems {\n\t\t\tquantity\n\t\t}\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\t...CartTaxesFragment\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t}\n\n\t\tdiscountCodes {\n\t\t\tid\n\t\t\tcode\n\t\t\tamount {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.CartSummaryFragmentFragmentDoc,
	"\n\tfragment CartTaxesFragment on Tax {\n\t\trate\n\t\tvalue {\n\t\t\t...PriceFragment\n\t\t}\n\t}\n":
		types.CartTaxesFragmentFragmentDoc,
	"\n  mutation AddDiscountCode($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $code: String!) {\n    cartDiscountCodeAdd(storeContext: $storeContext, id: $id, code: $code) {\n      ...Cart\n    }\n  }\n":
		types.AddDiscountCodeDocument,
	"\n\tfragment LineItemFragment on LineItem {\n\t\t...StockIndicatorFragment\n\t\tid\n\t\tquantity\n\t\tproductSlug\n\t\tproductName\n\t\taddedAt\n\t\tprice {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n":
		types.LineItemFragmentFragmentDoc,
	"\n\tfragment PriceDisplayFragment on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n":
		types.PriceDisplayFragmentFragmentDoc,
	"\n  mutation RemoveDiscountCode($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $discountCodeId: ID!) {\n    cartDiscountCodeRemove(storeContext: $storeContext, id: $id, discountCodeId: $discountCodeId) {\n      ...Cart\n    }\n  }\n":
		types.RemoveDiscountCodeDocument,
	"\n  fragment RemoveDiscountCodeButtonFragment on Cart {\n    id {\n\t\t\tid\n\t\t\tversion\n\t\t}\n  }\n":
		types.RemoveDiscountCodeButtonFragmentFragmentDoc,
	"\n\tfragment StockIndicatorFragment on LineItem {\n\t\tavailableStock\n\t}":
		types.StockIndicatorFragmentFragmentDoc,
	"\n\tfragment Cart on Cart {\n\t\t...CartOverviewFragment\n\t\t# Base fields required for react-query logic\n\t\tid {\n\t\t\tid\n\t\t\tversion\n\t\t}\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t}\n":
		types.CartFragmentDoc,
	"\n\tquery GetCart($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...Cart\n\t\t}\n\t}\n":
		types.GetCartDocument,
	"\n\tmutation CartLineItemsRemove(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$lineItemIds: [String!]!\n\t) {\n\t\tcartLineItemsRemove(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tlineItemIds: $lineItemIds\n\t\t) {\n\t\t\t...Cart\n\t\t}\n\t}\n":
		types.CartLineItemsRemoveDocument,
	"\n\tmutation CartLineItemsUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$lineItems: [CartLineItemUpdateInput!]!\n\t) {\n\t\tcartLineItemsUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tlineItems: $lineItems\n\t\t) {\n\t\t\t...Cart\n\t\t}\n\t}\n":
		types.CartLineItemsUpdateDocument,
	"\n\tfragment ColumnBlockFragment on ColumnBlock {\n\t\t...RichTextFragment\n\t}\n":
		types.ColumnBlockFragmentFragmentDoc,
	"\n\tfragment ThreeColumnFragment on ThreeColumns {\n\t\t__typename\n\t\tid\n\t\tcolumn1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn3 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n":
		types.ThreeColumnFragmentFragmentDoc,
	"\n\tfragment TwoColumnFragment on TwoColumns {\n\t\t__typename\n\t\tid\n\t\tratio\n\t\tcolumn1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n":
		types.TwoColumnFragmentFragmentDoc,
	"\n\tfragment ContentTeaserFragment on ContentTeaser {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcontent\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t\timagePosition\n\t}\n":
		types.ContentTeaserFragmentFragmentDoc,
	"\n\tfragment FaqItemListFragment on FaqItemList {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tfaqItems: items {\n\t\t\t...FaqItemFragment\n\t\t}\n\t}\n":
		types.FaqItemListFragmentFragmentDoc,
	"\n\tfragment RichTextFragment on RichText {\n\t\t__typename\n\t\tid\n\t\trenderer\n\t\tcontent\n\t}\n":
		types.RichTextFragmentFragmentDoc,
	"\n\tfragment UspListFragment on UspList {\n\t\t__typename\n\t\tid\n\t\tusps: items {\n\t\t\t...UspFragment\n\t\t}\n\t}\n":
		types.UspListFragmentFragmentDoc,
	"\n\tquery GetContentPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$path: String!\n\t\t$previewID: String\n\t) {\n\t\tcontentPage(\n\t\t\tstoreContext: $storeContext\n\t\t\tpath: $path\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...ContentPageFragment\n\t\t}\n\t}\n":
		types.GetContentPageDocument,
	"\n\tfragment ContentPageFragment on ContentPage {\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\t__typename\n\t\tid\n\t\tname\n\t\tseo {\n\t\t\t__typename\n\t\t\ttitle\n\t\t\tdescription\n\t\t\togTitle\n\t\t\togDescription\n\t\t\togImage\n\t\t\ttwitterTitle\n\t\t\ttwitterDescription\n\t\t\ttwitterImage\n\t\t}\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\t\tbody {\n\t\t\t...TeaserRowFragment\n\t\t\t...RichTextFragment\n\t\t\t...TwoColumnFragment\n\t\t\t...ThreeColumnFragment\n\t\t\t...ProductRowFragment\n\t\t\t...FaqItemListFragment\n\t\t\t...UspListFragment\n\t\t\t...ContentTeaserFragment\n\t\t}\n\t}\n":
		types.ContentPageFragmentFragmentDoc,
	"\n\tfragment ProductBreadcrumbFragment on Product {\n\t\tname\n\t\tslug\n\t\tprimaryCategory {\n\t\t\tname\n\t\t\tpath\n\t\t\tancestors {\n\t\t\t\tname\n\t\t\t\tpath\n\t\t\t}\n\t\t}\n\t}\n":
		types.ProductBreadcrumbFragmentFragmentDoc,
	"\n\tfragment ButtonSelectorGroupFieldFragment on BaseProductVariantDimensionOption {\n\t\tvalue\n\t\tlabel\n\t\tselected\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\tvariant {\n\t\t\tsku\n\t\t}\n\t}\n":
		types.ButtonSelectorGroupFieldFragmentFragmentDoc,
	"\n\tfragment ColorSelectorGroupFieldFragment on ProductVariantDimensionColorOption {\n\t\t__typename\n\t\tvalue\n\t\tlabel\n\t\tselected\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\tvariant {\n\t\t\tsku\n\t\t}\n\t\tcolor\n\t}\n":
		types.ColorSelectorGroupFieldFragmentFragmentDoc,
	"\n\tfragment ImageSelectorGroupFieldFragment on BaseProductVariantDimensionOption {\n\t\t__typename\n\t\tvalue\n\t\tlabel\n\t\tselected\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\tvariant {\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n":
		types.ImageSelectorGroupFieldFragmentFragmentDoc,
	"\n\tfragment ProductFormFragment on ProductVariantDimension {\n\t\tname\n\t\tlabel\n\t\toptions {\n\t\t\t__typename\n\t\t\t...ColorSelectorGroupFieldFragment\n\t\t\t...ButtonSelectorGroupFieldFragment\n\t\t\t...ImageSelectorGroupFieldFragment\n\t\t}\n\t}\n":
		types.ProductFormFragmentFragmentDoc,
	"\n\tfragment ProductGalleryImageFragment on ProductImage {\n\t\turl\n\t\talt\n\t}\n":
		types.ProductGalleryImageFragmentFragmentDoc,
	"\n\tquery GetProductDetailPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$slug: String!\n\t\t$selectedSku: String\n\t) {\n\t\tproduct(storeContext: $storeContext, slug: $slug) {\n\t\t\t...ProductDetailPageFragment\n\n\t\t\tlocalizedSlugs {\n\t\t\t\tlocale\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n":
		types.GetProductDetailPageDocument,
	"\n\tfragment ProductDetailPageFragment on Product {\n\t\tmeta {\n\t\t\ttitle\n\t\t\tdescription\n\t\t}\n\n\t\tusps {\n\t\t\tid\n\t\t\tusp1\n\t\t\tusp2\n\t\t\tusp3\n\t\t\tusp4\n\t\t}\n\n\t\tname\n\t\tslug\n\n\t\tbrand\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\timages {\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\n\t\tprimaryCategory {\n\t\t\tname\n\t\t\tslug\n\t\t}\n\n\t\tvariant(sku: $selectedSku) {\n\t\t\t...ProductVariantFragment\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\tnet {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tvariantDimensions(selectedSku: $selectedSku) {\n\t\t\t...ProductFormFragment\n\t\t}\n\n\t\treviews {\n\t\t\tamount\n\t\t\taverageRating\n\t\t}\n\n\t\t...ProductBreadcrumbFragment\n\t}\n":
		types.ProductDetailPageFragmentFragmentDoc,
	"\n\tfragment SpecificationAttributeFragment on ProductSpecificationAttribute {\n\t\t... on ProductSpecificationBooleanAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tboolValue: value\n\t\t}\n\t\t... on ProductSpecificationNumberAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tnumberValue: value\n\t\t}\n\t\t... on ProductSpecificationStringAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tstringValue: value\n\t\t}\n\t\t... on ProductSpecificationEnumAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tenumValue: value {\n\t\t\t\tlabel\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t\t... on ProductSpecificationEnumListAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tenumListValue: values {\n\t\t\t\tlabel\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t\t... on ProductSpecificationDateTimeAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tdateTimeValue: value\n\t\t}\n\t}\n":
		types.SpecificationAttributeFragmentFragmentDoc,
	"\n\tfragment ProductVariantFragment on ProductVariant {\n\t\tid\n\t\tname\n\t\tsku\n\t\tavailability\n\t\timages {\n\t\t\t...ProductGalleryImageFragment\n\t\t}\n\n\t\t# only needed for the main product\n\t\tdeliveryTime\n\t\tdescription\n\t\tseller\n\t\tspecifications {\n\t\t\tname\n\t\t\tlabel\n\t\t\tattributes {\n\t\t\t\t...SpecificationAttributeFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.ProductVariantFragmentFragmentDoc,
	'\n\tquery GetHomePage(\n\t\t$storeContext: StoreContextInput!\n\t\t$version: ContentVersion!\n\t\t$previewID: String\n\t) {\n\t\tcontentPage(\n\t\t\tpath: ""\n\t\t\tstoreContext: $storeContext\n\t\t\tversion: $version\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t__typename\n\t\t\tid\n\t\t\tname\n\t\t\tseo {\n\t\t\t\t__typename\n\t\t\t\ttitle\n\t\t\t\tdescription\n\t\t\t\togTitle\n\t\t\t\togDescription\n\t\t\t\togImage\n\t\t\t\ttwitterTitle\n\t\t\t\ttwitterDescription\n\t\t\t\ttwitterImage\n\t\t\t}\n\t\t\thero {\n\t\t\t\t...HeroFragment\n\t\t\t}\n\t\t\tusps {\n\t\t\t\t...UspsFragment\n\t\t\t}\n\t\t\tbody {\n\t\t\t\t...TeaserRowFragment\n\t\t\t\t...RichTextFragment\n\t\t\t\t...TwoColumnFragment\n\t\t\t\t...ThreeColumnFragment\n\t\t\t}\n\t\t}\n\t}\n':
		types.GetHomePageDocument,
	"\n\tquery GetContentSlugs(\n\t\t$storeContext: StoreContextInput!\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t\t$version: ContentVersion\n\t) {\n\t\tpages(\n\t\t\tstoreContext: $storeContext\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t\tversion: $version\n\t\t) {\n\t\t\tresults {\n\t\t\t\t... on AbstractPage {\n\t\t\t\t\t__typename\n\t\t\t\t\tname\n\t\t\t\t\tpath\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n":
		types.GetContentSlugsDocument,
	"\n\tquery GetProductSlugs(\n\t\t$storeContext: StoreContextInput!\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproducts(storeContext: $storeContext, pageSize: $pageSize, page: $page) {\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n":
		types.GetProductSlugsDocument,
	"\n\tfragment ContextLineItemFragment on LineItem {\n\t\tid\n\t\tquantity\n\t\tproductSlug\n\t\tproductName\n\t\taddedAt\n\t\tprice {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n":
		types.ContextLineItemFragmentFragmentDoc,
	"\n\tfragment BreadcrumbFragment on Breadcrumb {\n\t\tname\n\t\tslug\n\t\ttype\n\t}\n":
		types.BreadcrumbFragmentFragmentDoc,
	"\n\tfragment CrossSellProductCardFragment on Product {\n\t\tname\n\t\tslug\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\t\timage {\n\t\t\turl\n\t\t\talt\n\t\t}\n\t\tprimaryVariant {\n\t\t\tid\n\t\t\tsku\n\t\t\tavailability\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.CrossSellProductCardFragmentFragmentDoc,
	"\n\tfragment FaqItemFragment on FaqItem {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcontent {\n\t\t\t...RichTextFragment\n\t\t}\n\t}\n":
		types.FaqItemFragmentFragmentDoc,
	"\n\tfragment HeroFragment on Hero {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\timagePosition\n\t\timageStyle\n\t\tbodyText {\n\t\t\t...RichTextFragment\n\t\t}\n\t\tbuttons {\n\t\t\t__typename\n\t\t\tid\n\t\t\ttitle\n\t\t\tvariant\n\t\t\tlink {\n\t\t\t\turl\n\t\t\t\tnewTab\n\t\t\t\thash\n\t\t\t}\n\t\t}\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t}\n":
		types.HeroFragmentFragmentDoc,
	"\n\tfragment PriceFragment on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n":
		types.PriceFragmentFragmentDoc,
	"\n\tfragment ProductCard on Product {\n\t\tname\n\t\tslug\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\t\timage {\n\t\t\turl\n\t\t\talt\n\t\t}\n\t\tstartingPrice {\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tprimaryVariant {\n\t\t\tid\n\t\t\tsku\n\t\t\tavailability\n\t\t\tseller\n\t\t\tprice {\n\t\t\t\tnet {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ProductCardFragmentDoc,
	"\n\tfragment ProductRowFragment on ProductRow {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tviewMoreLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tproducts {\n\t\t\t__typename\n\t\t\tid\n\t\t\t...ProductCard\n\t\t}\n\t}\n":
		types.ProductRowFragmentFragmentDoc,
	"\n\tfragment TeaserRowFragment on TeaserRow {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tviewMoreLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\titems {\n\t\t\t__typename\n\t\t\tid\n\t\t\tcontent\n\t\t\timage {\n\t\t\t\talt\n\t\t\t\tfilename\n\t\t\t}\n\t\t\tlink {\n\t\t\t\turl\n\t\t\t}\n\t\t\ttitle\n\t\t}\n\t}\n":
		types.TeaserRowFragmentFragmentDoc,
	"\n\tfragment UspsFragment on Usps {\n\t\tid\n\t\tusp1\n\t\tusp2\n\t\tusp3\n\t\tusp4\n\t}\n":
		types.UspsFragmentFragmentDoc,
	"\n\tfragment UspFragment on Usp {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n":
		types.UspFragmentFragmentDoc,
	"\n\tmutation CartLineItemsAdd(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput\n\t\t$lineItems: [CartLineItemCreateInput!]!\n\t) {\n\t\tcartLineItemsAdd(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tlineItems: $lineItems\n\t\t) {\n\t\t\t...Cart\n\t\t}\n\t}\n":
		types.CartLineItemsAddDocument,
	"\n\tfragment AddressValidationResult on AddressValidationResult {\n\t\tvalid\n\t\tsuggestions {\n\t\t\tcity\n\t\t\tcountry\n\t\t\tstreetNumber\n\t\t\tstreetNumberSuffix\n\t\t\tpostalCode\n\t\t\tstreetName\n\t\t}\n\t}\n":
		types.AddressValidationResultFragmentDoc,
	"\n\tquery GetAddressValidate(\n\t\t$storeContext: StoreContextInput!\n\t\t$address: AddressInput!\n\t) {\n\t\taddressValidate(storeContext: $storeContext, address: $address) {\n\t\t\t...AddressValidationResult\n\t\t}\n\t}\n":
		types.GetAddressValidateDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation StoreContentPreviewData($path: String!, $data: String!) {\n\t\tstoreContentPreview(data: $data, path: $path) {\n\t\t\tid\n\t\t}\n\t}\n",
): typeof import("./graphql").StoreContentPreviewDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ApplePayPaymentMethod on PaymentMethod {\n\t\tid\n\t\tname\n\t\tprovider\n\t\textra {\n\t\t\t... on ApplePayExtra {\n\t\t\ttype\n\t\t\tbrands\n\t\t\tconfiguration {\n\t\t\t\tmerchantId\n\t\t\t\tmerchantName\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").ApplePayPaymentMethodFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation ValidateApplePayPayment($storeContext: StoreContextInput!, $paymentContext: ApplePayPaymentContextInput!) {\n\t\tvalidateApplePayPayment(storeContext: $storeContext, paymentContext: $paymentContext) {\n\t\t\tdata\n\t\t}\n\t}\n",
): typeof import("./graphql").ValidateApplePayPaymentDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CreateApplePayOrder($storeContext: StoreContextInput!) {\n\t\tcheckoutComplete(storeContext: $storeContext) {\n\t\t\torderId\n\t\t\torderNumber\n\t\t}\n\t}\n",
): typeof import("./graphql").CreateApplePayOrderDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation ApplePayPaymentCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$paymentContext: PaymentContextInput!\n\t) {\n\t\tcreatePayment(\n\t\t\tstoreContext: $storeContext\n\t\t\tpaymentContext: $paymentContext\n\t\t) {\n\t\t\tredirectURL\n\t\t}\n\t}\n",
): typeof import("./graphql").ApplePayPaymentCreateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation UpdateCheckoutAddress(\n\t\t$storeContext: StoreContextInput!\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t\t$setShippingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setShippingAddress\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t) {\n\t\t\t...CheckoutCartFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").UpdateCheckoutAddressDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery CheckoutCustomerExists($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerExists(storeContext: $storeContext, email: $email) {\n\t\t\tresult\n\t\t}\n\t}\n",
): typeof import("./graphql").CheckoutCustomerExistsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerCreateEmailStep($storeContext: StoreContextInput!, $customer: CustomerCreateInput!) {\n\t\tcustomerCreate(storeContext: $storeContext, customer: $customer) {\n\t\t\tcreatedAt\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerCreateEmailStepDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation checkoutCartUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t\t$setShippingAddress: AddressInput\n\t\t$setPaymentMethod: PaymentMethodInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setShippingAddress\n\t\t\tsetPaymentMethod: $setPaymentMethod\n\t\t) {\n\t\t\t...CheckoutCartFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").CheckoutCartUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n  query GetCartForCheckout($storeContext: StoreContextInput!) {\n    cart(storeContext: $storeContext) {\n      ...CheckoutCartFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCartForCheckoutDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CheckoutCartFragment on Cart {\n\t\tid {\n\t\t\tversion\n\t\t\tid\n\t\t}\n\t\tavailablePaymentMethods(device: web) {\n\t\t\tname\n\t\t\tpaymentMethod {\n\t\t\t\t...ApplePayPaymentMethod\n\t\t\t}\n\t\t}\n\t\tbillingAddress {\n\t\t\tid\n\t\t}\n\t\tcustomerEmail\n\t\tlineItems {\n\t\t\tid\n\t\t\tproductName\n\t\t\tquantity\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t\ttotal {\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").CheckoutCartFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FooterInfoPagesFragment on Site {\n\t\tinfoPages {\n\t\t\tprivacy {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t\tterms {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t\tcookies {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").FooterInfoPagesFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetSimpleSite(\n\t\t$storeContext: StoreContextInput!\n\t\t$version: ContentVersion!\n\t\t$previewID: String\n\t) {\n\t\tsite(\n\t\t\tstoreContext: $storeContext\n\t\t\tversion: $version\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...HeaderFragment\n\t\t\t...FooterInfoPagesFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").GetSimpleSiteDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CheckoutSummaryFragment on Cart {\n\t\tid {\n\t\t\tid\n\t\t\tversion\n\t\t}\n\n\t\tlineItems {\n\t\t\tid\n\t\t\tquantity\n\t\t\tproductSlug\n\t\t\tproductName\n\t\t\taddedAt\n\t\t\tvariant {\n\t\t\t\tname\n\t\t\t\tsku\n\t\t\t\tprimaryImage {\n\t\t\t\t\turl\n\t\t\t\t\talt\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").CheckoutSummaryFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment BillingAddressFragment on Address {\n\t\tid\n\t\temail\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n",
): typeof import("./graphql").BillingAddressFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCheckoutInformation($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...CheckoutSummaryFragment\n\n\t\t\tbillingAddress {\n\t\t\t\t...BillingAddressFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCheckoutInformationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation UpdateCheckoutInformation(\n\t\t$storeContext: StoreContextInput!\n\t\t$setBillingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t\tsetShippingAddress: $setBillingAddress\n\t\t) {\n\t\t\tbillingAddress {\n\t\t\t\t...BillingAddressFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").UpdateCheckoutInformationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery CustomerExists($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerExists(storeContext: $storeContext, email: $email) {\n\t\t\tresult\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerExistsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCheckoutLogin($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...CheckoutSummaryFragment\n\n\t\t\tcustomerEmail\n\n\t\t\tbillingAddress {\n\t\t\t\t...BillingAddressFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCheckoutLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation UpdateCheckoutLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$setCustomerEmail: String\n\t\t$setBillingAddress: AddressInput\n\t) {\n\t\tcheckoutUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tsetCustomerEmail: $setCustomerEmail\n\t\t\tsetBillingAddress: $setBillingAddress\n\t\t) {\n\t\t\tid {\n\t\t\t\tid\n\t\t\t\tversion\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").UpdateCheckoutLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CheckoutCustomerLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerLogin(\n\t\t\tstoreContext: $storeContext\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CheckoutCustomerLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCheckoutPayment($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...CheckoutSummaryFragment\n\n\t\t\tavailablePaymentMethods(device: web) {\n\t\t\t\tname\n\t\t\t\tpaymentMethod {\n\t\t\t\t\t...PaymentMethod\n\t\t\t\t}\n\t\t\t}\n\t\t\tselectedPaymentMethod {\n\t\t\t\t...PaymentMethod\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCheckoutPaymentDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment PaymentMethod on PaymentMethod {\n\t\tid\n\t\tname\n\t\tprovider\n\t\tissuers {\n\t\t\tid\n\t\t\tname\n\t\t}\n\t}\n",
): typeof import("./graphql").PaymentMethodFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation PaymentCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$paymentContext: PaymentContextInput!\n\t) {\n\t\tcreatePayment(\n\t\t\tstoreContext: $storeContext\n\t\t\tpaymentContext: $paymentContext\n\t\t) {\n\t\t\tredirectURL\n\t\t}\n\t}\n",
): typeof import("./graphql").PaymentCreateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CreateOrder($storeContext: StoreContextInput!) {\n\t\tcheckoutComplete(storeContext: $storeContext) {\n\t\t\torderId\n\t\t\torderNumber\n\t\t}\n\t}\n",
): typeof import("./graphql").CreateOrderDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FacetsFragment on Facet {\n\t\t...OptionsFacetFragment\n\t\t...RangeFacetFragment\n\t\t...ToggleFacetFragment\n\t}\n",
): typeof import("./graphql").FacetsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OptionsFacetFragment on OptionsFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\tkey\n\t\t\tlabel\n\t\t\tcount\n\t\t\tselected\n\t\t}\n\t}\n",
): typeof import("./graphql").OptionsFacetFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment RangeFacetFragment on RangeFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\tmin\n\t\tmax\n\t\tselectedMin\n\t\tselectedMax\n\t}\n",
): typeof import("./graphql").RangeFacetFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ToggleFacetFragment on ToggleFacet {\n\t\t__typename\n\t\tkey\n\t\tlabel\n\t\toptions {\n\t\t\tkey\n\t\t\tlabel\n\t\t\tcount\n\t\t\tselected\n\t\t}\n\t}\n",
): typeof import("./graphql").ToggleFacetFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductListingConfig on ProductListingConfig {\n\t\tcategoryId\n\t\tdefaultOrder\n\t\tprefilters {\n\t\t\tkey\n\t\t\tvalue\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductListingConfigFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductsResult on ProductsResult {\n\t\ttotal\n\t\tresults {\n\t\t\t...ProductCard\n\t\t}\n\t\tfacets {\n\t\t\t...FacetsFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductsResultFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCategoryPage($storeContext: StoreContextInput!, $path: String!) {\n\t\tcategoryPageByPath(storeContext: $storeContext, path: $path) {\n\t\t\t...CategoryPageFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCategoryPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CategoryPageFragment on CategoryPage {\n\t\tmeta {\n\t\t\ttitle\n\t\t\tdescription\n\t\t\tkeywords\n\t\t}\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\tcategory {\n\t\t\tid\n\t\t\tname\n\t\t\tdescription\n\t\t}\n\t\tproductListingConfig {\n\t\t\t...ProductListingConfig\n\t\t}\n\t}\n",
): typeof import("./graphql").CategoryPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetProductsForCategory(\n\t\t$storeContext: StoreContextInput!\n\t\t$filters: [FacetFilterInput!]\n\t\t$prefilters: [FilterConditionInput!]\n\t\t$categoryId: String!\n\t\t$searchTerm: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t\t$sort: ProductSortOrder!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tfilters: $filters\n\t\t\tprefilters: $prefilters\n\t\t\tcategoryId: $categoryId\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t\tsort: $sort\n\t\t) {\n\t\t\t...ProductsResult\n\t\t}\n\t}\n",
): typeof import("./graphql").GetProductsForCategoryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetProductListingPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$path: String!\n\t\t$previewID: String\n\t) {\n\t\tproductListingPage(\n\t\t\tstoreContext: $storeContext\n\t\t\tpath: $path\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...ProductListingPage\n\t\t}\n\t}\n",
): typeof import("./graphql").GetProductListingPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductListingPage on ProductListingPage {\n\t\t__typename\n\t\tid\n\t\tname\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tseo {\n\t\t\t__typename\n\t\t\ttitle\n\t\t\tdescription\n\t\t\togTitle\n\t\t\togDescription\n\t\t\togImage\n\t\t\ttwitterTitle\n\t\t\ttwitterDescription\n\t\t\ttwitterImage\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\t\tproductListingConfig {\n\t\t\t...ProductListingConfig\n\t\t}\n\t\tteaserRow {\n\t\t\t...TeaserRowFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductListingPageFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetProductsOld(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t) {\n\t\t\t...ProductsResult\n\t\t}\n\t}\n",
): typeof import("./graphql").GetProductsOldDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetProducts(\n\t\t$storeContext: StoreContextInput!\n\t\t$sort: ProductSortOrder!\n\t\t$filters: [FacetFilterInput!]\n\t\t$searchTerm: String\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproductSearch(\n\t\t\tstoreContext: $storeContext\n\t\t\tsort: $sort\n\t\t\tfilters: $filters\n\t\t\tsearchTerm: $searchTerm\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t) {\n\t\t\t...ProductsResult\n\t\t}\n\t}\n",
): typeof import("./graphql").GetProductsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FooterMenuFragment on MenuBlock {\n\t\tid\n\t\tlabel\n\t\tmenuLinks {\n\t\t\tname\n\t\t\turl\n\t\t}\n\t}\n",
): typeof import("./graphql").FooterMenuFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment PaymentFragment on Site {\n\t\tpaymentMethods {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t}\n",
): typeof import("./graphql").PaymentFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FooterSocialsFragment on Site {\n\t\tsocials {\n\t\t\tfacebook\n\t\t\tinstagram\n\t\t\tlinkedIn\n\t\t\ttwitter\n\t\t\tyoutube\n\t\t}\n\t}\n",
): typeof import("./graphql").FooterSocialsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery CustomerBusinessUnitsQuery($storeContext: StoreContextInput!) {\n\t\tcustomer(storeContext: $storeContext) {\n\t\t\t...BusinessUnits\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerBusinessUnitsQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCartQuantity($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\tlineItems {\n\t\t\t\tquantity\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCartQuantityDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment HeaderFragment on Site {\n\t\tlogo {\n\t\t\talt\n\t\t\tfilename\n\t\t}\n\n\t\t...HeaderInfoPagesFragment\n\t\t...MainMenuFragment\n\t}\n",
): typeof import("./graphql").HeaderFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment MainMenuFragment on Site {\n\t\tmainMenu {\n\t\t\t...TopMenuItemFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").MainMenuFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TopMenuItemFragment on MenuItem {\n\t\t...MenuItemFieldsFragment\n\t\tsubMenuItems {\n\t\t\t...SubMenuItemFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").TopMenuItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment SubMenuItemFragment on MenuItem {\n\t\t...MenuItemFieldsFragment\n\t\tsubMenuItems {\n\t\t\t...MenuItemFieldsFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").SubMenuItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment MenuItemFieldsFragment on MenuItem {\n\t\t__typename\n\t\tid\n\t\tlabel\n\t\ttarget {\n\t\t\t... on ResourceLink {\n\t\t\t\turl\n\t\t\t\t__typename\n\t\t\t}\n\t\t\t... on ProductCategory {\n\t\t\t\tname\n\t\t\t\tpath\n\t\t\t\t__typename\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").MenuItemFieldsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment HeaderInfoPagesFragment on Site {\n\t\tinfoPages {\n\t\t\tbusiness {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t\tservice {\n\t\t\t\turl\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").HeaderInfoPagesFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetSite(\n\t\t$storeContext: StoreContextInput!\n\t\t$version: ContentVersion!\n\t\t$previewID: String\n\t) {\n\t\tsite(\n\t\t\tstoreContext: $storeContext\n\t\t\tversion: $version\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...Site\n\t\t}\n\t}\n",
): typeof import("./graphql").GetSiteDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment Site on Site {\n\t\t...HeaderFragment\n\t\t...PaymentFragment\n\t\t...FooterInfoPagesFragment\n\t\t...FooterSocialsFragment\n\n\t\tfooterMenu {\n\t\t\t...FooterMenuFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").SiteFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerLogin(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerLogin(\n\t\t\tstoreContext: $storeContext\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerLoginDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerPasswordResetRequest($storeContext: StoreContextInput!, $email: String!) {\n\t\tcustomerPasswordResetRequest(storeContext: $storeContext, email: $email)\n\t}\n",
): typeof import("./graphql").CustomerPasswordResetRequestDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerPasswordReset(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$token: String!\n\t) {\n\t\tcustomerPasswordReset(storeContext: $storeContext, password: $password, token: $token) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerPasswordResetDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$customer: CustomerCreateInput!\n\t) {\n\t\tcustomerCreate(storeContext: $storeContext, customer: $customer) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerCreateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment BusinessUnits on Customer {\n\t\tbusinessUnits {\n\t\t\tid\n\t\t\tname\n\t\t\tstores {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").BusinessUnitsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation SetBusinessContext(\n\t\t$businessUnitId: ID!\n\t\t$storeKey: String!\n\t) {\n\t\tsetActiveBusinessContext(\n\t\t\tbusinessUnitId: $businessUnitId\n\t\t\tstoreKey: $storeKey\n\t\t)\n\t}\n",
): typeof import("./graphql").SetBusinessContextDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery PreviewOrdersQuery($storeContext: StoreContextInput!) {\n\t\torders(storeContext: $storeContext, pageSize: 3) {\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\t...OrderCardFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").PreviewOrdersQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerAddressCreate(\n\t\t$storeContext: StoreContextInput!\n\t\t$address: AddressInput!\n\t) {\n\t\tcustomerAddressCreate(\n\t\t\tstoreContext: $storeContext\n\t\t\taddress: $address\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerAddressCreateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CustomerAddressFragment on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n",
): typeof import("./graphql").CustomerAddressFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerAddressUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$addressId: ID!\n\t\t$address: AddressInput!\n\t) {\n\t\tcustomerAddressUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\taddressId: $addressId\n\t\t\taddress: $address\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerAddressUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery CustomerAddressQuery($storeContext: StoreContextInput!) {\n\t\tcustomer(storeContext: $storeContext) {\n\t\t\tgivenName\n\t\t\taddresses {\n\t\t\t\tid\n\t\t\t\temail\n\t\t\t\tsalutation\n\t\t\t\tgivenName\n\t\t\t\tfamilyName\n\t\t\t\tstreetName\n\t\t\t\tstreetNumber\n\t\t\t\tstreetNumberSuffix\n\t\t\t\tpostalCode\n\t\t\t\tcity\n\t\t\t\tphone\n\t\t\t\tcountry\n\t\t\t\tcompany\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerAddressQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OrderDetailsFragment on Order {\n\t\tid {\n\t\t\tid\n\t\t}\n\t\tlineItems {\n\t\t\t...OrderLineItemFragment\n\t\t}\n\t\t...OrderCardFragment\n\t\t...OrderMetadataFragment\n\t\t...OrderTotalsFragment\n\t}\n",
): typeof import("./graphql").OrderDetailsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OrderLineItemFragment on LineItem {\n\t\tproductName\n\t\tquantity\n\t\tprice {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").OrderLineItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OrderMetadataFragment on Order {\n\t\tcreatedAt\n\t\torderNumber\n\t\torderStatus\n\t}\n",
): typeof import("./graphql").OrderMetadataFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OrderTotalsFragment on Order {\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").OrderTotalsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery OrderDetailQuery($storeContext: StoreContextInput!, $orderId: String!) {\n\t\torder(storeContext: $storeContext, orderId: $orderId) {\n\t\t\t...OrderDetailsFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").OrderDetailQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment OrderCardFragment on Order {\n\t\tid {\n\t\t\tid\n\t\t}\n\t\torderNumber\n\t\torderStatus\n\t\tcreatedAt\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tlineItems {\n\t\t\tid\n\t\t\tvariant {\n\t\t\t\tprimaryImage {\n\t\t\t\t\talt\n\t\t\t\t\turl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").OrderCardFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery OverviewOrdersQuery($storeContext: StoreContextInput!) {\n\t\torders(storeContext: $storeContext) {\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\t...OrderCardFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").OverviewOrdersQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment BillingAddressDisplayFragment on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n",
): typeof import("./graphql").BillingAddressDisplayFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCustomer($storeContext: StoreContextInput!) {\n\t\tcustomer(storeContext: $storeContext) {\n\t\t\t...Customer\n\t\t}\n\t}\n\tfragment Customer on Customer {\n\t\tgivenName\n\t\temail\n\t\tvatNumber\n\t\tbillingAddress {\n\t\t\t...Address\n\t\t\t...BillingAddressDisplayFragment\n\t\t}\n\t\taddresses {\n\t\t\t...Address\n\t\t}\n\t}\n\tfragment Address on Address {\n\t\tid\n\t\temail\n\t\tsalutation\n\t\tgivenName\n\t\tfamilyName\n\t\tstreetName\n\t\tstreetNumber\n\t\tstreetNumberSuffix\n\t\tpostalCode\n\t\tcity\n\t\tphone\n\t\tcountry\n\t\tcompany\n\t}\n",
): typeof import("./graphql").GetCustomerDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerBillingAddressUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$billingAddress: AddressInput!\n\t) {\n\t\tcustomerInformationUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tbillingAddress: $billingAddress\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerBillingAddressUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerEmailUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$email: String!\n\t\t$password: String!\n\t) {\n\t\tcustomerEmailUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerEmailUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CustomerPasswordUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$password: String!\n\t\t$newPassword: String!\n\t) {\n\t\tcustomerPasswordUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tpassword: $password\n\t\t\tnewPassword: $newPassword\n\t\t) {\n\t\t\t...Customer\n\t\t}\n\t}\n",
): typeof import("./graphql").CustomerPasswordUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\nfragment CartOverviewFragment on Cart {\n\tlineItems {\n\t\tid\n\t...LineItemFragment\n\t}\n\ttotal {\n\t\tnet {\n\t\t\t...PriceFragment\n\t\t}\n\t}\n\t...CartSummaryFragment\n}\n\t",
): typeof import("./graphql").CartOverviewFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CartSummaryFragment on Cart {\n\t\t...RemoveDiscountCodeButtonFragment\n\t\tlineItems {\n\t\t\tquantity\n\t\t}\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\t...CartTaxesFragment\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t}\n\n\t\tdiscountCodes {\n\t\t\tid\n\t\t\tcode\n\t\t\tamount {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").CartSummaryFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CartTaxesFragment on Tax {\n\t\trate\n\t\tvalue {\n\t\t\t...PriceFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").CartTaxesFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n  mutation AddDiscountCode($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $code: String!) {\n    cartDiscountCodeAdd(storeContext: $storeContext, id: $id, code: $code) {\n      ...Cart\n    }\n  }\n",
): typeof import("./graphql").AddDiscountCodeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment LineItemFragment on LineItem {\n\t\t...StockIndicatorFragment\n\t\tid\n\t\tquantity\n\t\tproductSlug\n\t\tproductName\n\t\taddedAt\n\t\tprice {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").LineItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment PriceDisplayFragment on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n",
): typeof import("./graphql").PriceDisplayFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n  mutation RemoveDiscountCode($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $discountCodeId: ID!) {\n    cartDiscountCodeRemove(storeContext: $storeContext, id: $id, discountCodeId: $discountCodeId) {\n      ...Cart\n    }\n  }\n",
): typeof import("./graphql").RemoveDiscountCodeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n  fragment RemoveDiscountCodeButtonFragment on Cart {\n    id {\n\t\t\tid\n\t\t\tversion\n\t\t}\n  }\n",
): typeof import("./graphql").RemoveDiscountCodeButtonFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment StockIndicatorFragment on LineItem {\n\t\tavailableStock\n\t}",
): typeof import("./graphql").StockIndicatorFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment Cart on Cart {\n\t\t...CartOverviewFragment\n\t\t# Base fields required for react-query logic\n\t\tid {\n\t\t\tid\n\t\t\tversion\n\t\t}\n\t\tsubTotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\ttotal {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttaxes {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\n\t\tshippingCosts {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t... PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").CartFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetCart($storeContext: StoreContextInput!) {\n\t\tcart(storeContext: $storeContext) {\n\t\t\t...Cart\n\t\t}\n\t}\n",
): typeof import("./graphql").GetCartDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CartLineItemsRemove(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$lineItemIds: [String!]!\n\t) {\n\t\tcartLineItemsRemove(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tlineItemIds: $lineItemIds\n\t\t) {\n\t\t\t...Cart\n\t\t}\n\t}\n",
): typeof import("./graphql").CartLineItemsRemoveDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CartLineItemsUpdate(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput!\n\t\t$lineItems: [CartLineItemUpdateInput!]!\n\t) {\n\t\tcartLineItemsUpdate(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tlineItems: $lineItems\n\t\t) {\n\t\t\t...Cart\n\t\t}\n\t}\n",
): typeof import("./graphql").CartLineItemsUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ColumnBlockFragment on ColumnBlock {\n\t\t...RichTextFragment\n\t}\n",
): typeof import("./graphql").ColumnBlockFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ThreeColumnFragment on ThreeColumns {\n\t\t__typename\n\t\tid\n\t\tcolumn1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn3 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").ThreeColumnFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TwoColumnFragment on TwoColumns {\n\t\t__typename\n\t\tid\n\t\tratio\n\t\tcolumn1 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t\tcolumn2 {\n\t\t\t...ColumnBlockFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").TwoColumnFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ContentTeaserFragment on ContentTeaser {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcontent\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t\timagePosition\n\t}\n",
): typeof import("./graphql").ContentTeaserFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FaqItemListFragment on FaqItemList {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tfaqItems: items {\n\t\t\t...FaqItemFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").FaqItemListFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment RichTextFragment on RichText {\n\t\t__typename\n\t\tid\n\t\trenderer\n\t\tcontent\n\t}\n",
): typeof import("./graphql").RichTextFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment UspListFragment on UspList {\n\t\t__typename\n\t\tid\n\t\tusps: items {\n\t\t\t...UspFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").UspListFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetContentPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$path: String!\n\t\t$previewID: String\n\t) {\n\t\tcontentPage(\n\t\t\tstoreContext: $storeContext\n\t\t\tpath: $path\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t...ContentPageFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").GetContentPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ContentPageFragment on ContentPage {\n\t\ttranslatedPaths {\n\t\t\tlocale\n\t\t\tpath\n\t\t}\n\t\tbreadcrumbs {\n\t\t\t...BreadcrumbFragment\n\t\t}\n\t\t__typename\n\t\tid\n\t\tname\n\t\tseo {\n\t\t\t__typename\n\t\t\ttitle\n\t\t\tdescription\n\t\t\togTitle\n\t\t\togDescription\n\t\t\togImage\n\t\t\ttwitterTitle\n\t\t\ttwitterDescription\n\t\t\ttwitterImage\n\t\t}\n\t\thero {\n\t\t\t...HeroFragment\n\t\t}\n\t\tusps {\n\t\t\t...UspsFragment\n\t\t}\n\t\tbody {\n\t\t\t...TeaserRowFragment\n\t\t\t...RichTextFragment\n\t\t\t...TwoColumnFragment\n\t\t\t...ThreeColumnFragment\n\t\t\t...ProductRowFragment\n\t\t\t...FaqItemListFragment\n\t\t\t...UspListFragment\n\t\t\t...ContentTeaserFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").ContentPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductBreadcrumbFragment on Product {\n\t\tname\n\t\tslug\n\t\tprimaryCategory {\n\t\t\tname\n\t\t\tpath\n\t\t\tancestors {\n\t\t\t\tname\n\t\t\t\tpath\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductBreadcrumbFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ButtonSelectorGroupFieldFragment on BaseProductVariantDimensionOption {\n\t\tvalue\n\t\tlabel\n\t\tselected\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\tvariant {\n\t\t\tsku\n\t\t}\n\t}\n",
): typeof import("./graphql").ButtonSelectorGroupFieldFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ColorSelectorGroupFieldFragment on ProductVariantDimensionColorOption {\n\t\t__typename\n\t\tvalue\n\t\tlabel\n\t\tselected\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\tvariant {\n\t\t\tsku\n\t\t}\n\t\tcolor\n\t}\n",
): typeof import("./graphql").ColorSelectorGroupFieldFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ImageSelectorGroupFieldFragment on BaseProductVariantDimensionOption {\n\t\t__typename\n\t\tvalue\n\t\tlabel\n\t\tselected\n\t\tproduct {\n\t\t\tslug\n\t\t}\n\t\tvariant {\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").ImageSelectorGroupFieldFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductFormFragment on ProductVariantDimension {\n\t\tname\n\t\tlabel\n\t\toptions {\n\t\t\t__typename\n\t\t\t...ColorSelectorGroupFieldFragment\n\t\t\t...ButtonSelectorGroupFieldFragment\n\t\t\t...ImageSelectorGroupFieldFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductFormFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductGalleryImageFragment on ProductImage {\n\t\turl\n\t\talt\n\t}\n",
): typeof import("./graphql").ProductGalleryImageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetProductDetailPage(\n\t\t$storeContext: StoreContextInput!\n\t\t$slug: String!\n\t\t$selectedSku: String\n\t) {\n\t\tproduct(storeContext: $storeContext, slug: $slug) {\n\t\t\t...ProductDetailPageFragment\n\n\t\t\tlocalizedSlugs {\n\t\t\t\tlocale\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").GetProductDetailPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductDetailPageFragment on Product {\n\t\tmeta {\n\t\t\ttitle\n\t\t\tdescription\n\t\t}\n\n\t\tusps {\n\t\t\tid\n\t\t\tusp1\n\t\t\tusp2\n\t\t\tusp3\n\t\t\tusp4\n\t\t}\n\n\t\tname\n\t\tslug\n\n\t\tbrand\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\n\t\tprimaryVariant {\n\t\t\timages {\n\t\t\t\turl\n\t\t\t}\n\t\t}\n\n\t\tprimaryCategory {\n\t\t\tname\n\t\t\tslug\n\t\t}\n\n\t\tvariant(sku: $selectedSku) {\n\t\t\t...ProductVariantFragment\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\tnet {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tvariantDimensions(selectedSku: $selectedSku) {\n\t\t\t...ProductFormFragment\n\t\t}\n\n\t\treviews {\n\t\t\tamount\n\t\t\taverageRating\n\t\t}\n\n\t\t...ProductBreadcrumbFragment\n\t}\n",
): typeof import("./graphql").ProductDetailPageFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment SpecificationAttributeFragment on ProductSpecificationAttribute {\n\t\t... on ProductSpecificationBooleanAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tboolValue: value\n\t\t}\n\t\t... on ProductSpecificationNumberAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tnumberValue: value\n\t\t}\n\t\t... on ProductSpecificationStringAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tstringValue: value\n\t\t}\n\t\t... on ProductSpecificationEnumAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tenumValue: value {\n\t\t\t\tlabel\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t\t... on ProductSpecificationEnumListAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tenumListValue: values {\n\t\t\t\tlabel\n\t\t\t\tvalue\n\t\t\t}\n\t\t}\n\t\t... on ProductSpecificationDateTimeAttribute {\n\t\t\t__typename\n\t\t\tlabel\n\t\t\tname\n\t\t\tdescription\n\t\t\tdateTimeValue: value\n\t\t}\n\t}\n",
): typeof import("./graphql").SpecificationAttributeFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductVariantFragment on ProductVariant {\n\t\tid\n\t\tname\n\t\tsku\n\t\tavailability\n\t\timages {\n\t\t\t...ProductGalleryImageFragment\n\t\t}\n\n\t\t# only needed for the main product\n\t\tdeliveryTime\n\t\tdescription\n\t\tseller\n\t\tspecifications {\n\t\t\tname\n\t\t\tlabel\n\t\t\tattributes {\n\t\t\t\t...SpecificationAttributeFragment\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductVariantFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tquery GetHomePage(\n\t\t$storeContext: StoreContextInput!\n\t\t$version: ContentVersion!\n\t\t$previewID: String\n\t) {\n\t\tcontentPage(\n\t\t\tpath: ""\n\t\t\tstoreContext: $storeContext\n\t\t\tversion: $version\n\t\t\tpreviewID: $previewID\n\t\t) {\n\t\t\t__typename\n\t\t\tid\n\t\t\tname\n\t\t\tseo {\n\t\t\t\t__typename\n\t\t\t\ttitle\n\t\t\t\tdescription\n\t\t\t\togTitle\n\t\t\t\togDescription\n\t\t\t\togImage\n\t\t\t\ttwitterTitle\n\t\t\t\ttwitterDescription\n\t\t\t\ttwitterImage\n\t\t\t}\n\t\t\thero {\n\t\t\t\t...HeroFragment\n\t\t\t}\n\t\t\tusps {\n\t\t\t\t...UspsFragment\n\t\t\t}\n\t\t\tbody {\n\t\t\t\t...TeaserRowFragment\n\t\t\t\t...RichTextFragment\n\t\t\t\t...TwoColumnFragment\n\t\t\t\t...ThreeColumnFragment\n\t\t\t}\n\t\t}\n\t}\n',
): typeof import("./graphql").GetHomePageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetContentSlugs(\n\t\t$storeContext: StoreContextInput!\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t\t$version: ContentVersion\n\t) {\n\t\tpages(\n\t\t\tstoreContext: $storeContext\n\t\t\tpageSize: $pageSize\n\t\t\tpage: $page\n\t\t\tversion: $version\n\t\t) {\n\t\t\tresults {\n\t\t\t\t... on AbstractPage {\n\t\t\t\t\t__typename\n\t\t\t\t\tname\n\t\t\t\t\tpath\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotal\n\t\t}\n\t}\n",
): typeof import("./graphql").GetContentSlugsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetProductSlugs(\n\t\t$storeContext: StoreContextInput!\n\t\t$pageSize: Int!\n\t\t$page: Int!\n\t) {\n\t\tproducts(storeContext: $storeContext, pageSize: $pageSize, page: $page) {\n\t\t\ttotal\n\t\t\tresults {\n\t\t\t\tslug\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").GetProductSlugsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ContextLineItemFragment on LineItem {\n\t\tid\n\t\tquantity\n\t\tproductSlug\n\t\tproductName\n\t\taddedAt\n\t\tprice {\n\t\t\tnet {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t\ttax {\n\t\t\t\tvalue {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\trate\n\t\t\t}\n\t\t}\n\t\tvariant {\n\t\t\tname\n\t\t\tsku\n\t\t\tprimaryImage {\n\t\t\t\turl\n\t\t\t\talt\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").ContextLineItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment BreadcrumbFragment on Breadcrumb {\n\t\tname\n\t\tslug\n\t\ttype\n\t}\n",
): typeof import("./graphql").BreadcrumbFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment CrossSellProductCardFragment on Product {\n\t\tname\n\t\tslug\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\t\timage {\n\t\t\turl\n\t\t\talt\n\t\t}\n\t\tprimaryVariant {\n\t\t\tid\n\t\t\tsku\n\t\t\tavailability\n\t\t\tprice {\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").CrossSellProductCardFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment FaqItemFragment on FaqItem {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tcontent {\n\t\t\t...RichTextFragment\n\t\t}\n\t}\n",
): typeof import("./graphql").FaqItemFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment HeroFragment on Hero {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\timagePosition\n\t\timageStyle\n\t\tbodyText {\n\t\t\t...RichTextFragment\n\t\t}\n\t\tbuttons {\n\t\t\t__typename\n\t\t\tid\n\t\t\ttitle\n\t\t\tvariant\n\t\t\tlink {\n\t\t\t\turl\n\t\t\t\tnewTab\n\t\t\t\thash\n\t\t\t}\n\t\t}\n\t\timage {\n\t\t\tfilename\n\t\t\talt\n\t\t}\n\t}\n",
): typeof import("./graphql").HeroFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment PriceFragment on Money {\n\t\tcentAmount\n\t\tcurrency\n\t}\n",
): typeof import("./graphql").PriceFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductCard on Product {\n\t\tname\n\t\tslug\n\t\tribbon {\n\t\t\tkind\n\t\t\tlabel\n\t\t}\n\t\timage {\n\t\t\turl\n\t\t\talt\n\t\t}\n\t\tstartingPrice {\n\t\t\tgross {\n\t\t\t\t...PriceFragment\n\t\t\t}\n\t\t}\n\t\tprimaryVariant {\n\t\t\tid\n\t\t\tsku\n\t\t\tavailability\n\t\t\tseller\n\t\t\tprice {\n\t\t\t\tnet {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t\tgross {\n\t\t\t\t\t...PriceFragment\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductCardFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ProductRowFragment on ProductRow {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tviewMoreLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\tproducts {\n\t\t\t__typename\n\t\t\tid\n\t\t\t...ProductCard\n\t\t}\n\t}\n",
): typeof import("./graphql").ProductRowFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment TeaserRowFragment on TeaserRow {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tviewMoreLink {\n\t\t\turl\n\t\t\tnewTab\n\t\t}\n\t\titems {\n\t\t\t__typename\n\t\t\tid\n\t\t\tcontent\n\t\t\timage {\n\t\t\t\talt\n\t\t\t\tfilename\n\t\t\t}\n\t\t\tlink {\n\t\t\t\turl\n\t\t\t}\n\t\t\ttitle\n\t\t}\n\t}\n",
): typeof import("./graphql").TeaserRowFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment UspsFragment on Usps {\n\t\tid\n\t\tusp1\n\t\tusp2\n\t\tusp3\n\t\tusp4\n\t}\n",
): typeof import("./graphql").UspsFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment UspFragment on Usp {\n\t\t__typename\n\t\tid\n\t\ttitle\n\t\tlink {\n\t\t\turl\n\t\t\tnewTab\n\t\t\thash\n\t\t}\n\t}\n",
): typeof import("./graphql").UspFragmentFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation CartLineItemsAdd(\n\t\t$storeContext: StoreContextInput!\n\t\t$id: CartIdentifierInput\n\t\t$lineItems: [CartLineItemCreateInput!]!\n\t) {\n\t\tcartLineItemsAdd(\n\t\t\tstoreContext: $storeContext\n\t\t\tid: $id\n\t\t\tlineItems: $lineItems\n\t\t) {\n\t\t\t...Cart\n\t\t}\n\t}\n",
): typeof import("./graphql").CartLineItemsAddDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment AddressValidationResult on AddressValidationResult {\n\t\tvalid\n\t\tsuggestions {\n\t\t\tcity\n\t\t\tcountry\n\t\t\tstreetNumber\n\t\t\tstreetNumberSuffix\n\t\t\tpostalCode\n\t\t\tstreetName\n\t\t}\n\t}\n",
): typeof import("./graphql").AddressValidationResultFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery GetAddressValidate(\n\t\t$storeContext: StoreContextInput!\n\t\t$address: AddressInput!\n\t) {\n\t\taddressValidate(storeContext: $storeContext, address: $address) {\n\t\t\t...AddressValidationResult\n\t\t}\n\t}\n",
): typeof import("./graphql").GetAddressValidateDocument;

export function graphql(source: string) {
	return (documents as any)[source] ?? {};
}
