/* eslint-disable */
import { DocumentTypeDecoration } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
	T extends { [key: string]: unknown },
	K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
	| T
	| {
			[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
	  };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	Date: { input: string; output: string };
	DateTime: { input: string; output: string };
	join__FieldSet: { input: any; output: any };
	link__Import: { input: any; output: any };
};

export type AbstractPage = {
	breadcrumbs?: Maybe<Array<Breadcrumb>>;
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	path: Scalars["String"]["output"];
	seo?: Maybe<ContentSeo>;
	storeContext: StoreContext;
	translatedPaths?: Maybe<Array<TranslatedPath>>;
};

export type AbstractProductSpecificationAttribute = {
	/** Optional descriptive information about the attribute, Localized to the current locale */
	description?: Maybe<Scalars["String"]["output"]>;
	/** The label of the attribute, e.g. 'Color'. Localized to the current locale */
	label: Scalars["String"]["output"];
	/** The name of the attribute, e.g. 'color'. Consistent across all locales */
	name: Scalars["String"]["output"];
};

/** Represents the basic structure for an address, supporting both private and company addresses. */
export type Address = {
	/** Extra information regarding the address, such as building number, floor, or apartment number. */
	additionalAddressInfo?: Maybe<Scalars["String"]["output"]>;
	/** The city where the address is located. */
	city?: Maybe<Scalars["String"]["output"]>;
	/** The company name for business addresses. This field may be omitted for personal addresses. */
	company?: Maybe<Scalars["String"]["output"]>;
	/** The country where the address is located. Must be provided. */
	country: Scalars["String"]["output"];
	/** The email address for contact, optional for both personal and business addresses. */
	email?: Maybe<Scalars["String"]["output"]>;
	/** The family name (last name) of the individual. May be left empty for company addresses. */
	familyName?: Maybe<Scalars["String"]["output"]>;
	/** The given name (first name) of the individual. May be left empty for company addresses. */
	givenName?: Maybe<Scalars["String"]["output"]>;
	id?: Maybe<Scalars["ID"]["output"]>;
	/** The phone number associated with this address, typically including the international code. */
	phone?: Maybe<Scalars["String"]["output"]>;
	/** The postal code or ZIP code for the address, depending on the country. */
	postalCode: Scalars["String"]["output"];
	/** The region, state, or province for the address. Used to represent subdivisions of countries. */
	region?: Maybe<Scalars["String"]["output"]>;
	/** The salutation or title for the person associated with the address. */
	salutation?: Maybe<Salutation>;
	/** The unstructured full street address, including street name, number, and optional suffix. */
	streetAddress?: Maybe<Scalars["String"]["output"]>;
	/** The name of the street where the address is located. */
	streetName?: Maybe<Scalars["String"]["output"]>;
	/** The number of the building on the street. This field represents the main address number. */
	streetNumber?: Maybe<Scalars["String"]["output"]>;
	/**
	 * Additional suffixes to the street number, often used in countries like the Netherlands.
	 * Example: "A", "B", or "1/2".
	 */
	streetNumberSuffix?: Maybe<Scalars["String"]["output"]>;
};

/** Represents the basic structure for inputting an address, supporting both personal and business addresses. */
export type AddressInput = {
	/** Extra information regarding the address, such as building number, floor, or apartment number. */
	additionalAddressInfo?: InputMaybe<Scalars["String"]["input"]>;
	/** The city where the address is located. Required. */
	city: Scalars["String"]["input"];
	/** The company name for business addresses. This field is required for business addresses. */
	company?: InputMaybe<Scalars["String"]["input"]>;
	/** The country where the address is located. Must be a valid 2-letter ISO country code. */
	country: Scalars["String"]["input"];
	/** The email address for contact, optional for both personal and business addresses. Must be a valid email format. */
	email?: InputMaybe<Scalars["String"]["input"]>;
	/** The family name (last name) of the individual. May be left empty for company addresses. */
	familyName?: InputMaybe<Scalars["String"]["input"]>;
	/** The given name (first name) of the individual. May be left empty for company addresses. */
	givenName?: InputMaybe<Scalars["String"]["input"]>;
	/** The phone number associated with this address, typically including the international code. Optional. */
	phone?: InputMaybe<Scalars["String"]["input"]>;
	/** The postal code or ZIP code for the address, depending on the country. Required. */
	postalCode: Scalars["String"]["input"];
	/** The region, state, or province for the address. Used to represent subdivisions of countries. Optional. */
	region?: InputMaybe<Scalars["String"]["input"]>;
	/** The salutation or title for the person associated with the address (e.g., Mr., Ms.). Optional. */
	salutation?: InputMaybe<Salutation>;
	/** The unstructured full street address, including street name, number, and optional suffix. */
	streetAddress?: InputMaybe<Scalars["String"]["input"]>;
	/** The name of the street where the address is located. Required. */
	streetName?: InputMaybe<Scalars["String"]["input"]>;
	/** The number of the building on the street. This field represents the main address number. Required. */
	streetNumber?: InputMaybe<Scalars["String"]["input"]>;
	/**
	 * Additional suffixes to the street number, often used in countries like the Netherlands.
	 * Example: "A", "B", or "1/2".
	 */
	streetNumberSuffix?: InputMaybe<Scalars["String"]["input"]>;
};

export type AddressSuggestion = {
	city?: Maybe<Scalars["String"]["output"]>;
	country?: Maybe<Scalars["String"]["output"]>;
	postalCode?: Maybe<Scalars["String"]["output"]>;
	streetName?: Maybe<Scalars["String"]["output"]>;
	streetNumber?: Maybe<Scalars["String"]["output"]>;
	streetNumberSuffix?: Maybe<Scalars["String"]["output"]>;
};

export type AddressValidationResult = {
	suggestions?: Maybe<Array<Maybe<AddressSuggestion>>>;
	valid: Scalars["Boolean"]["output"];
};

export type ApplePayExtra = PaymentMethodExtraFields & {
	brands?: Maybe<Array<Scalars["String"]["output"]>>;
	configuration?: Maybe<PaymentMethodApplePayConfiguration>;
	type: Scalars["String"]["output"];
};

export type ApplePayPaymentContextInput = {
	displayName: Scalars["String"]["input"];
	domainName: Scalars["String"]["input"];
	merchantIdentifier: Scalars["String"]["input"];
};

export type ApplePayPaymentData = {
	data: Scalars["String"]["output"];
};

export type AssetFileTypes = "audios" | "images" | "texts" | "videos";

export type BaseProductVariantDimensionOption = {
	availability?: Maybe<ProductAvailability>;
	label: Scalars["String"]["output"];
	product?: Maybe<Product>;
	selected: Scalars["Boolean"]["output"];
	value: Scalars["String"]["output"];
	variant?: Maybe<ProductVariant>;
};

export type Block =
	| Buttons
	| ContentTeaser
	| FaqItemList
	| ProductRow
	| RichText
	| TeaserRow
	| ThreeColumns
	| TwoColumns
	| UspList;

export type BlockType = "contentType" | "nestable" | "universal";

export type Breadcrumb = {
	name: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
	type: BreadcrumbType;
};

export type BreadcrumbType = "CATEGORY" | "CONTENT" | "PRODUCT";

export type BusinessUnit = {
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	stores?: Maybe<Array<Store>>;
};

export type Button = {
	id: Scalars["ID"]["output"];
	link: ResourceLink;
	title: Scalars["String"]["output"];
	variant: ButtonVariant;
};

export type ButtonSize = "lg" | "xl" | "xxl";

export type ButtonVariant = "primary" | "secondary" | "tertiary";

export type Buttons = {
	buttons: Array<Button>;
	id: Scalars["ID"]["output"];
};

export type CacheControlScope = "PRIVATE" | "PUBLIC";

export type Cart = {
	availablePaymentMethods: Array<PaymentMethodGroup>;
	billingAddress?: Maybe<Address>;
	createdAt: Scalars["DateTime"]["output"];
	currency: Scalars["String"]["output"];
	customerEmail?: Maybe<Scalars["String"]["output"]>;
	/** Discount codes that are applied to the cart */
	discountCodes: Array<CartDiscountCode>;
	/** Sum of all discounts */
	discountTotal?: Maybe<Money>;
	id: CartIdentifier;
	isGuest: Scalars["Boolean"]["output"];
	lastModifiedAt: Scalars["DateTime"]["output"];
	lineItems: Array<LineItem>;
	selectedPaymentMethod?: Maybe<PaymentMethod>;
	shippingAddress?: Maybe<Address>;
	/** Shipping costs, if any */
	shippingCosts?: Maybe<TaxedPrice>;
	/** Sum of all line items (i.e. price excluding all shipping and payment costs) */
	subTotal: TaxedTotalPrice;
	/** Sum of all line items (i.e. price including all shipping and payment costs) */
	total: TaxedTotalPrice;
};

export type CartAvailablePaymentMethodsArgs = {
	device: ClientDevice;
};

/** A CartDiscountCode represents a discount that is applied to the cart. These are identified by their discount code. */
export type CartDiscountCode = {
	amount: Money;
	code: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
};

export type CartIdentifier = {
	id: Scalars["ID"]["output"];
	version: Scalars["Int"]["output"];
};

export type CartIdentifierInput = {
	id: Scalars["ID"]["input"];
	version: Scalars["Int"]["input"];
};

export type CartLineItemCreateInput = {
	quantity: Scalars["Int"]["input"];
	sku: Scalars["String"]["input"];
};

export type CartLineItemUpdateInput = {
	id: Scalars["String"]["input"];
	quantity: Scalars["Int"]["input"];
};

export type CategoriesResult = {
	results: Array<CategoryPage>;
	total: Scalars["Int"]["output"];
};

export type CategoryOverviewPage = {
	/** The breadcrumbs representing the navigation path to the page. */
	breadcrumbs: Array<Breadcrumb>;
	category?: Maybe<ProductCategory>;
	childCategories: Array<ProductCategory>;
	/** Indicates whether the page is indexable for search engines. */
	indexable?: Maybe<Scalars["Boolean"]["output"]>;
	meta?: Maybe<PageMeta>;
	/** The name of the page. */
	name?: Maybe<Scalars["String"]["output"]>;
	path: Scalars["String"]["output"];
	/** The locale, currency, and store key associated with the page. */
	storeContext: StoreContext;
	translatedPaths?: Maybe<Array<TranslatedPath>>;
};

export type CategoryPage = {
	/** The breadcrumbs representing the navigation path to the page. */
	breadcrumbs: Array<Breadcrumb>;
	category: ProductCategory;
	/** Indicates whether the page is indexable for search engines. */
	indexable?: Maybe<Scalars["Boolean"]["output"]>;
	meta?: Maybe<PageMeta>;
	/** The name of the page. */
	name?: Maybe<Scalars["String"]["output"]>;
	path: Scalars["String"]["output"];
	productListingConfig: ProductListingConfig;
	/** The locale, currency, and store key associated with the page. */
	storeContext: StoreContext;
	translatedPaths?: Maybe<Array<TranslatedPath>>;
};

export type CategorySortOrder =
	/** Sort by name in descending order */
	| "nameAscending"
	/** Sort by name in ascending order */
	| "nameDescending";

export type CheckoutCompleted = {
	orderId: Scalars["String"]["output"];
	orderNumber: Scalars["String"]["output"];
};

export type ClientDevice = "android" | "ios" | "web";

export type ColumnBlock = Buttons | FaqItemList | RichText | UspList;

export type ContainerSize = "large" | "medium" | "small";

export type ContentAsset = {
	alt?: Maybe<Scalars["String"]["output"]>;
	filename: Scalars["String"]["output"];
	isExternalUrl?: Maybe<Scalars["Boolean"]["output"]>;
	name?: Maybe<Scalars["String"]["output"]>;
	title?: Maybe<Scalars["String"]["output"]>;
};

export type ContentPage = AbstractPage & {
	body?: Maybe<Array<Maybe<Block>>>;
	breadcrumbs?: Maybe<Array<Breadcrumb>>;
	hero?: Maybe<Hero>;
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	path: Scalars["String"]["output"];
	seo?: Maybe<ContentSeo>;
	storeContext: StoreContext;
	translatedPaths?: Maybe<Array<TranslatedPath>>;
	usps?: Maybe<Usps>;
};

export type ContentSeo = {
	description?: Maybe<Scalars["String"]["output"]>;
	ogDescription?: Maybe<Scalars["String"]["output"]>;
	ogImage?: Maybe<Scalars["String"]["output"]>;
	ogTitle?: Maybe<Scalars["String"]["output"]>;
	title?: Maybe<Scalars["String"]["output"]>;
	twitterDescription?: Maybe<Scalars["String"]["output"]>;
	twitterImage?: Maybe<Scalars["String"]["output"]>;
	twitterTitle?: Maybe<Scalars["String"]["output"]>;
};

export type ContentTable = {
	tbody?: Maybe<Array<Array<Scalars["String"]["output"]>>>;
	thead?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ContentTeaser = {
	content: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	image: ContentAsset;
	imagePosition: TeaserImagePosition;
	link: ResourceLink;
	title: Scalars["String"]["output"];
};

export type ContentVersion = "draft" | "published";

export type Currency = "EUR" | "USD";

export type Customer = {
	addresses?: Maybe<Array<Address>>;
	billingAddress?: Maybe<Address>;
	businessUnits?: Maybe<Array<BusinessUnit>>;
	company?: Maybe<Scalars["String"]["output"]>;
	createdAt: Scalars["DateTime"]["output"];
	email: Scalars["String"]["output"];
	familyName?: Maybe<Scalars["String"]["output"]>;
	givenName?: Maybe<Scalars["String"]["output"]>;
	salutation?: Maybe<Salutation>;
	vatNumber?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerCreateInput = {
	email: Scalars["String"]["input"];
	familyName: Scalars["String"]["input"];
	givenName: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
	salutation?: InputMaybe<Salutation>;
};

export type CustomerExistsResult = {
	result: Scalars["Boolean"]["output"];
};

export type Facet = OptionsFacet | RangeFacet | ToggleFacet;

export type FacetFilterInput = {
	key: Scalars["String"]["input"];
	/** Required for Range Facets */
	max?: InputMaybe<Scalars["Float"]["input"]>;
	/** Required for Range Facets */
	min?: InputMaybe<Scalars["Float"]["input"]>;
	/** Required for Options Facets */
	selections?: InputMaybe<Array<Scalars["String"]["input"]>>;
	/** Required for Toggle Facets */
	toggle?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FacetValue = {
	count: Scalars["Int"]["output"];
	key: Scalars["String"]["output"];
	label: Scalars["String"]["output"];
	selected: Scalars["Boolean"]["output"];
};

export type FaqItem = {
	content: RichText;
	id: Scalars["ID"]["output"];
	title: Scalars["String"]["output"];
};

export type FaqItemList = {
	id: Scalars["ID"]["output"];
	items: Array<FaqItem>;
	title: Scalars["String"]["output"];
};

export type FilterCondition = {
	key: Scalars["String"]["output"];
	value: Scalars["String"]["output"];
};

export type FilterConditionInput = {
	key: Scalars["String"]["input"];
	value: Scalars["String"]["input"];
};

export type Hero = {
	bodyText?: Maybe<RichText>;
	buttons?: Maybe<Array<Button>>;
	id: Scalars["ID"]["output"];
	image: ContentAsset;
	imagePosition: ImagePosition;
	imageStyle: ImageStyle;
	title: Scalars["String"]["output"];
};

export type ImagePosition =
	/** Full background */
	| "full"
	/** Right */
	| "right";

export type ImageStyle =
	/** Dark */
	| "dark"
	/** Light */
	| "light";

export type InfoPages = {
	business?: Maybe<ResourceLink>;
	cookies?: Maybe<ResourceLink>;
	privacy?: Maybe<ResourceLink>;
	service?: Maybe<ResourceLink>;
	terms?: Maybe<ResourceLink>;
};

export type LineItem = {
	addedAt?: Maybe<Scalars["String"]["output"]>;
	availableStock: Scalars["Int"]["output"];
	id: Scalars["String"]["output"];
	/**
	 * Represents the list price, also known as te Manufacturer's Suggested Retail
	 * Price (MSRP).  It's the highest price shown and could be used as the
	 * strikethrough price to indicate the original value of the product before any
	 * discounts.
	 */
	listPrice: TaxedPrice;
	/**
	 * The line-item is the final price after applying any additional discounts on
	 * top of the regular price. It's the final price that customers pay.
	 */
	price: TaxedPrice;
	productName: Scalars["String"]["output"];
	productSlug?: Maybe<Scalars["String"]["output"]>;
	quantity: Scalars["Int"]["output"];
	/**
	 * The regular selling price on the platform, potentially already discounted
	 * from the MSRP. It's the standard price at which the product is usually offered
	 * when there are no special sales or additional discounts.
	 */
	regularPrice: TaxedPrice;
	variant: ProductInfo;
};

export type LinkFeatures = "anchor" | "assets" | "email" | "newTab";

export type LocalizedSlug = {
	locale: Scalars["String"]["output"];
	slug: Scalars["String"]["output"];
};

export type Media = ProductImage | ProductImage360 | ProductYouTubeVideo;

export type MenuBlock = {
	id: Scalars["ID"]["output"];
	label: Scalars["String"]["output"];
	menuLinks: Array<ResourceLink>;
};

/** A top level menu item with a submenu */
export type MenuItem = {
	id: Scalars["ID"]["output"];
	label?: Maybe<Scalars["String"]["output"]>;
	subMenuItems?: Maybe<Array<MenuItem>>;
	target?: Maybe<MenuLinkTarget>;
};

export type MenuLinkTarget = ProductCategory | ResourceLink;

export type Money = {
	centAmount: Scalars["Int"]["output"];
	currency: Currency;
};

export type Mutation = {
	/** Add discount code to cart. */
	cartDiscountCodeAdd: Cart;
	/** Remove discount code from cart. */
	cartDiscountCodeRemove: Cart;
	/**
	 * Add new line items to the cart. The cart identifier is optional. If no
	 * identifier is passed a new cart is created
	 */
	cartLineItemsAdd: Cart;
	/** Remove line items from the cart */
	cartLineItemsRemove: Cart;
	/** Update line items in the cart. This is for changing the quantity of line items. */
	cartLineItemsUpdate: Cart;
	checkoutComplete: CheckoutCompleted;
	/**
	 * Update the cart metadata. This is for setting for example the shipping method.
	 * If no identifier is passed a new cart is created.
	 */
	checkoutUpdate: Cart;
	createPayment: PaymentData;
	customerAddressCreate: Customer;
	customerAddressDelete: Customer;
	customerAddressUpdate: Customer;
	customerCreate: Customer;
	customerDelete: Scalars["Boolean"]["output"];
	customerEmailUpdate: Customer;
	customerInformationUpdate: Customer;
	customerLogin: Customer;
	customerLogout: Scalars["Boolean"]["output"];
	customerPasswordReset: Customer;
	customerPasswordResetRequest?: Maybe<Scalars["Boolean"]["output"]>;
	customerPasswordUpdate: Customer;
	refreshToken: Scalars["Boolean"]["output"];
	setActiveBusinessContext: Scalars["Boolean"]["output"];
	storeContentPreview: StoreContentPreviewData;
	/**
	 * Validate an Apple Pay payment. This is used to validate the payment method and
	 * to get the payment data.
	 */
	validateApplePayPayment: ApplePayPaymentData;
};

export type MutationCartDiscountCodeAddArgs = {
	code: Scalars["String"]["input"];
	id: CartIdentifierInput;
	storeContext: StoreContextInput;
};

export type MutationCartDiscountCodeRemoveArgs = {
	discountCodeId: Scalars["ID"]["input"];
	id: CartIdentifierInput;
	storeContext: StoreContextInput;
};

export type MutationCartLineItemsAddArgs = {
	id?: InputMaybe<CartIdentifierInput>;
	lineItems: Array<CartLineItemCreateInput>;
	storeContext: StoreContextInput;
};

export type MutationCartLineItemsRemoveArgs = {
	id: CartIdentifierInput;
	lineItemIds: Array<Scalars["String"]["input"]>;
	storeContext: StoreContextInput;
};

export type MutationCartLineItemsUpdateArgs = {
	id: CartIdentifierInput;
	lineItems: Array<CartLineItemUpdateInput>;
	storeContext: StoreContextInput;
};

export type MutationCheckoutCompleteArgs = {
	storeContext: StoreContextInput;
};

export type MutationCheckoutUpdateArgs = {
	id?: InputMaybe<CartIdentifierInput>;
	setBillingAddress?: InputMaybe<AddressInput>;
	setCustomerEmail?: InputMaybe<Scalars["String"]["input"]>;
	setPaymentMethod?: InputMaybe<PaymentMethodInput>;
	setShippingAddress?: InputMaybe<AddressInput>;
	storeContext: StoreContextInput;
};

export type MutationCreatePaymentArgs = {
	paymentContext: PaymentContextInput;
	storeContext: StoreContextInput;
};

export type MutationCustomerAddressCreateArgs = {
	address: AddressInput;
	storeContext: StoreContextInput;
};

export type MutationCustomerAddressDeleteArgs = {
	addressId: Scalars["ID"]["input"];
	storeContext: StoreContextInput;
};

export type MutationCustomerAddressUpdateArgs = {
	address: AddressInput;
	addressId: Scalars["ID"]["input"];
	storeContext: StoreContextInput;
};

export type MutationCustomerCreateArgs = {
	customer: CustomerCreateInput;
	storeContext: StoreContextInput;
};

export type MutationCustomerDeleteArgs = {
	password: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type MutationCustomerEmailUpdateArgs = {
	email: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type MutationCustomerInformationUpdateArgs = {
	billingAddress: AddressInput;
	storeContext: StoreContextInput;
};

export type MutationCustomerLoginArgs = {
	email: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type MutationCustomerPasswordResetArgs = {
	password: Scalars["String"]["input"];
	storeContext: StoreContextInput;
	token: Scalars["String"]["input"];
};

export type MutationCustomerPasswordResetRequestArgs = {
	email: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type MutationCustomerPasswordUpdateArgs = {
	newPassword: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type MutationSetActiveBusinessContextArgs = {
	businessUnitId: Scalars["ID"]["input"];
	storeKey: Scalars["String"]["input"];
};

export type MutationStoreContentPreviewArgs = {
	data: Scalars["String"]["input"];
	path: Scalars["String"]["input"];
};

export type MutationValidateApplePayPaymentArgs = {
	paymentContext: ApplePayPaymentContextInput;
	storeContext: StoreContextInput;
};

export type OptionsFacet = {
	key: Scalars["String"]["output"];
	label: Scalars["String"]["output"];
	options: Array<FacetValue>;
};

export type Order = {
	billingAddress?: Maybe<Address>;
	createdAt: Scalars["DateTime"]["output"];
	currency: Scalars["String"]["output"];
	customerEmail?: Maybe<Scalars["String"]["output"]>;
	/** Discount codes that were applied to the cart */
	discountCodes: Array<OrderDiscountCode>;
	/** Sum of all discounts */
	discountTotal?: Maybe<Money>;
	id: OrderIdentifier;
	isGuest: Scalars["Boolean"]["output"];
	lineItems: Array<LineItem>;
	orderNumber?: Maybe<Scalars["String"]["output"]>;
	/** OrderStatus contains a general status of the order. This is useful for categorizing orders in the UI. */
	orderStatus?: Maybe<OrderStatus>;
	selectedPaymentMethod?: Maybe<PaymentMethod>;
	shippingAddress?: Maybe<Address>;
	shippingCosts?: Maybe<TaxedPrice>;
	shippingNotice?: Maybe<Scalars["String"]["output"]>;
	/** Sum of all line items (i.e. price excluding all shipping and payment costs) */
	subTotal: TaxedTotalPrice;
	total: TaxedTotalPrice;
	vatShifting?: Maybe<Money>;
};

/** A CartDiscountCode represents a discount that is applied to the cart. These are identified by their discount code. */
export type OrderDiscountCode = {
	amount: Money;
	code: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
};

export type OrderIdentifier = {
	id: Scalars["ID"]["output"];
	version: Scalars["Int"]["output"];
};

export type OrderResult = {
	results: Array<Order>;
	total: Scalars["Int"]["output"];
};

export type OrderStatus = "Open";

export type OtherPaymentMethodExtra = PaymentMethodExtraFields & {
	type: Scalars["String"]["output"];
};

export type Page = ContentPage | ProductListingPage;

export type PageMeta = {
	description?: Maybe<Scalars["String"]["output"]>;
	keywords?: Maybe<Array<Scalars["String"]["output"]>>;
	title?: Maybe<Scalars["String"]["output"]>;
};

export type PageSortOrder =
	/** Sort by name in descending order */
	| "nameAscending"
	/** Sort by name in ascending order */
	| "nameDescending";

export type PageType = "CONTENT" | "PRODUCT_LISTING";

export type PagesResult = {
	results: Array<Page>;
	total: Scalars["Int"]["output"];
};

export type Payment = {
	id: Scalars["ID"]["output"];
};

export type PaymentContextInput = {
	method: PaymentMethodInput;
	/**
	 * Optional order ID to associate the payment with. Only relevant if we create
	 * the order before the payment
	 */
	orderId?: InputMaybe<Scalars["String"]["input"]>;
	returnURL: Scalars["String"]["input"];
};

export type PaymentData = {
	/** URL to redirect the customer to */
	redirectURL: Scalars["String"]["output"];
};

export type PaymentMethod = {
	extra?: Maybe<PaymentMethodExtra>;
	/** Unique identifier of the payment method */
	id: Scalars["ID"]["output"];
	issuers?: Maybe<Array<PaymentMethodOption>>;
	/** Human readable name of the payment method (localized) */
	name: Scalars["String"]["output"];
	/** Payment method provider */
	provider: Scalars["String"]["output"];
};

export type PaymentMethodApplePayConfiguration = {
	merchantId: Scalars["String"]["output"];
	merchantName: Scalars["String"]["output"];
};

export type PaymentMethodArgumentInput = {
	/** Key of the argument */
	key: Scalars["String"]["input"];
	/** Value of the argument */
	value: Scalars["String"]["input"];
};

export type PaymentMethodExtra = ApplePayExtra | OtherPaymentMethodExtra;

export type PaymentMethodExtraFields = {
	type: Scalars["String"]["output"];
};

export type PaymentMethodGroup = {
	/** Human readable name of the payment method group (localized) */
	name: Scalars["String"]["output"];
	/** Payment methods in this group */
	paymentMethod: Array<PaymentMethod>;
};

export type PaymentMethodInput = {
	/**
	 * Optional arguments that can be passed to the payment provider. For example
	 * the credit card number and expiry date
	 */
	arguments?: InputMaybe<Array<PaymentMethodArgumentInput>>;
	/** The payment id as returned by the PaymentMethod */
	id: Scalars["String"]["input"];
	/** The provider that facilitates the payment. E.g. stripe or adyen */
	provider: Scalars["String"]["input"];
};

export type PaymentMethodOption = {
	/** Unique identifier of the payment method option */
	id: Scalars["ID"]["output"];
	/** Human readable name of the payment method (localized) */
	name: Scalars["String"]["output"];
};

export type Product = {
	articles?: Maybe<Array<ContentPage>>;
	brand?: Maybe<Scalars["String"]["output"]>;
	/** The categories to which the product belongs. */
	categories?: Maybe<Array<ProductCategory>>;
	id: Scalars["ID"]["output"];
	/** The primary image associated with the product. */
	image?: Maybe<ProductImage>;
	localizedSlugs: Array<LocalizedSlug>;
	meta?: Maybe<ResourceMeta>;
	name: Scalars["String"]["output"];
	/** The categories to which the product belongs. */
	primaryCategory?: Maybe<ProductCategory>;
	/**
	 * The primary variant, often used to show data in a product card
	 * @deprecated use variant
	 */
	primaryVariant: ProductVariant;
	/** Products that are similar. E.g. the same t-shirt in another color. */
	relatedProducts?: Maybe<Array<Product>>;
	reviews?: Maybe<Reviews>;
	/** The ribbon. For example: 'Boeket van de maand' etc. */
	ribbon?: Maybe<ProductRibbon>;
	slug: Scalars["String"]["output"];
	/** The lowest price of all variants, often used to communicate the price on the product list page. */
	startingPrice?: Maybe<TaxedPrice>;
	storeContext?: Maybe<StoreContext>;
	usps?: Maybe<Usps>;
	/** A specific variant, often used to show data in a product card. If no sku is given then the primary variant is returned. */
	variant: ProductVariant;
	variantDimensions?: Maybe<Array<Maybe<ProductVariantDimension>>>;
	/** A list of all variants, often used to show all available options in a product detail page. */
	variants: Array<ProductVariant>;
};

export type ProductLocalizedSlugsArgs = {
	locales?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ProductVariantArgs = {
	sku?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductVariantDimensionsArgs = {
	selectedSku?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductVariantsArgs = {
	sku?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ProductAttributeGroup = "GENERAL" | "MARKETING" | "TECHNICAL";

export type ProductAvailability =
	/**
	 * The product is temporarily out of stock, but customers can still place orders.
	 * The product will be shipped once it becomes available again.
	 */
	| "BACKORDER"
	/**
	 * The product has been permanently discontinued and is no longer available for
	 * purchase.
	 */
	| "DISCONTINUED"
	/** The product is currently in stock and available for purchase. */
	| "IN_STOCK"
	/**
	 * The product is not available for purchase.
	 * It might be discontinued or permanently out of stock.
	 */
	| "NOT_AVAILABLE"
	/**
	 * The product is temporarily unavailable because it is out of stock.
	 * Customers cannot purchase it at the moment.
	 */
	| "OUT_OF_STOCK"
	/**
	 * The product is not yet released but can be preordered.
	 * Customers can reserve their copy in advance.
	 */
	| "PREORDER";

export type ProductCategory = {
	ancestors: Array<ProductCategory>;
	description?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	key: Scalars["String"]["output"];
	name: Scalars["String"]["output"];
	path: Scalars["String"]["output"];
	/** @deprecated use path */
	slug: Scalars["String"]["output"];
	storeContext: StoreContext;
};

export type ProductEntityReference = {
	name?: Maybe<Scalars["String"]["output"]>;
	path?: Maybe<Scalars["String"]["output"]>;
};

export type ProductImage = {
	alt?: Maybe<Scalars["String"]["output"]>;
	description?: Maybe<Scalars["String"]["output"]>;
	/** The URL to the image. */
	url: Scalars["String"]["output"];
};

export type ProductImage360 = {
	alt?: Maybe<Scalars["String"]["output"]>;
	description?: Maybe<Scalars["String"]["output"]>;
	/** A list of URLs to the images. */
	urls: Array<Scalars["String"]["output"]>;
};

export type ProductInfo = {
	/** The name of the product. */
	name: Scalars["String"]["output"];
	/** The image associated with the product. */
	primaryImage?: Maybe<ProductInfoImage>;
	/** The SKU (stock keeping unit) for the variant. */
	sku: Scalars["String"]["output"];
};

export type ProductInfoImage = {
	alt?: Maybe<Scalars["String"]["output"]>;
	description?: Maybe<Scalars["String"]["output"]>;
	/** The URL to the image. */
	url: Scalars["String"]["output"];
};

export type ProductListingConfig = {
	categoryId: Scalars["String"]["output"];
	defaultOrder?: Maybe<ProductSortOrder>;
	enabledFacets?: Maybe<Array<Scalars["String"]["output"]>>;
	prefilters?: Maybe<Array<FilterCondition>>;
};

export type ProductListingPage = AbstractPage & {
	breadcrumbs?: Maybe<Array<Breadcrumb>>;
	categoryId?: Maybe<Scalars["String"]["output"]>;
	hero?: Maybe<Hero>;
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
	path: Scalars["String"]["output"];
	productListingConfig: ProductListingConfig;
	seo?: Maybe<ContentSeo>;
	storeContext: StoreContext;
	teaserRow?: Maybe<TeaserRow>;
	translatedPaths?: Maybe<Array<TranslatedPath>>;
	usps?: Maybe<Usps>;
};

export type ProductRibbon = {
	/** NEW, SALE, etc. */
	kind?: Maybe<Scalars["String"]["output"]>;
	/** Text in the ribbon */
	label?: Maybe<Scalars["String"]["output"]>;
};

export type ProductRow = {
	id: Scalars["ID"]["output"];
	products: Array<Product>;
	title: Scalars["String"]["output"];
	viewMoreLink?: Maybe<ResourceLink>;
};

export type ProductSortOrder =
	/** Sort by name in ascending order */
	| "nameAscending"
	/** Sort by name in descending order */
	| "nameDescending"
	/** Sort by popularity */
	| "popularity"
	/** Sort by price in ascending order */
	| "priceAscending"
	/** Sort by price in descending order */
	| "priceDescending";

export type ProductSpecificationAttribute =
	| ProductSpecificationBooleanAttribute
	| ProductSpecificationDateTimeAttribute
	| ProductSpecificationEnumAttribute
	| ProductSpecificationEnumListAttribute
	| ProductSpecificationNumberAttribute
	| ProductSpecificationStringAttribute;

export type ProductSpecificationBooleanAttribute =
	AbstractProductSpecificationAttribute & {
		/** Optional descriptive information about the attribute, Localized to the current locale */
		description?: Maybe<Scalars["String"]["output"]>;
		/** The label of the attribute, e.g. 'Is fragile'. Localized to the current locale */
		label: Scalars["String"]["output"];
		/** The name of the attribute, e.g. 'isFragile'. Consistent across all locales */
		name: Scalars["String"]["output"];
		/** The boolean value of the attribute, e.g. true */
		value: Scalars["Boolean"]["output"];
	};

export type ProductSpecificationDateTimeAttribute =
	AbstractProductSpecificationAttribute & {
		/** Optional descriptive information about the attribute, Localized to the current locale */
		description?: Maybe<Scalars["String"]["output"]>;
		/** The label of the attribute, e.g. 'Available from'. Localized to the current locale */
		label: Scalars["String"]["output"];
		/** The name of the attribute, e.g. 'availableFrom'. Consistent across all locales */
		name: Scalars["String"]["output"];
		/** The datetime value of the attribute, e.g. 2024-08-14T07:52:15.520Z */
		value: Scalars["DateTime"]["output"];
	};

export type ProductSpecificationEnumAttribute =
	AbstractProductSpecificationAttribute & {
		/** Optional descriptive information about the attribute, Localized to the current locale */
		description?: Maybe<Scalars["String"]["output"]>;
		/** The label of the attribute, e.g. 'Season'. Localized to the current locale */
		label: Scalars["String"]["output"];
		/** The name of the attribute, e.g. 'season'. Consistent across all locales */
		name: Scalars["String"]["output"];
		/** The enum value of the attribute */
		value: ProductSpecificationEnumValue;
	};

export type ProductSpecificationEnumListAttribute =
	AbstractProductSpecificationAttribute & {
		/** Optional descriptive information about the attribute, Localized to the current locale */
		description?: Maybe<Scalars["String"]["output"]>;
		/** The label of the attribute, e.g. 'Audiences'. Localized to the current locale */
		label: Scalars["String"]["output"];
		/** The name of the attribute, e.g. 'audiences'. Consistent across all locales */
		name: Scalars["String"]["output"];
		/** The enumc values of the attribute */
		values: Array<Maybe<ProductSpecificationEnumValue>>;
	};

export type ProductSpecificationEnumValue = {
	/** The label of the enum, e.g. 'green'. Consistent across all locales */
	label: Scalars["String"]["output"];
	/** The string value of the enum, e.g. 'Green' */
	value: Scalars["String"]["output"];
};

export type ProductSpecificationGroup = {
	/** Attributes for this variant group. These reflect a list of all the attributes associated with this specification group. */
	attributes: Array<ProductSpecificationAttribute>;
	/** Optional descriptive information about the attribute, Localized to the current locale */
	description?: Maybe<Scalars["String"]["output"]>;
	/** The label of the attribute, e.g. 'Color'. Localized to the current locale */
	label: Scalars["String"]["output"];
	/** The name of the attribute, e.g. 'color'. Consistent across all locales */
	name: Scalars["String"]["output"];
};

export type ProductSpecificationNumberAttribute =
	AbstractProductSpecificationAttribute & {
		/** Optional descriptive information about the attribute, Localized to the current locale */
		description?: Maybe<Scalars["String"]["output"]>;
		/** The label of the attribute, e.g. 'Length'. Localized to the current locale */
		label: Scalars["String"]["output"];
		/** The name of the attribute, e.g. 'length'. Consistent across all locales */
		name: Scalars["String"]["output"];
		/** The integer value of the attribute, e.g. 1 */
		value: Scalars["Float"]["output"];
	};

export type ProductSpecificationStringAttribute =
	AbstractProductSpecificationAttribute & {
		/** Optional descriptive information about the attribute, Localized to the current locale */
		description?: Maybe<Scalars["String"]["output"]>;
		/** The label of the attribute, e.g. 'Size'. Localized to the current locale */
		label: Scalars["String"]["output"];
		/** The name of the attribute, e.g. 'size'. Consistent across all locales */
		name: Scalars["String"]["output"];
		/** The string value of the attribute, e.g. 'XL'. Localized to the current locale */
		value: Scalars["String"]["output"];
	};

export type ProductVariant = {
	availability?: Maybe<ProductAvailability>;
	/**
	 * A description about when the product can be delivered. For example: Ordered
	 * before 23:00, delivered tomorrow.
	 */
	deliveryTime?: Maybe<Scalars["String"]["output"]>;
	/** The variant description, can be formatted using Markdown. */
	description?: Maybe<Scalars["String"]["output"]>;
	/** European Article Number (EAN) */
	ean?: Maybe<Scalars["String"]["output"]>;
	/** Harmonized System (HS) code for the variant. */
	hscode?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["ID"]["output"];
	/** All images associated with the product variant. */
	images?: Maybe<Array<ProductImage>>;
	/**
	 * Represents the list price, also known as te Manufacturer's Suggested Retail
	 * Price (MSRP).  It's the highest price shown and could be used as the
	 * strikethrough price to indicate the original value of the product before any
	 * discounts.
	 */
	listPrice?: Maybe<TaxedPrice>;
	/** The name of the variant. */
	name?: Maybe<Scalars["String"]["output"]>;
	/**
	 * The price on the platform after regular product discounts are applied, it's
	 * the price communicated to the customer in the storefront.
	 */
	price?: Maybe<TaxedPrice>;
	/** The main image associated with the product variant. */
	primaryImage?: Maybe<ProductImage>;
	/**
	 * The regular selling price on the platform, potentially already discounted
	 * from the MSRP. It's the standard price at which the product is usually offered
	 * when there are no special sales or additional discounts.
	 */
	regularPrice?: Maybe<TaxedPrice>;
	/**
	 * The seller of the product. Only used in combination with marketplace products.
	 *
	 * This is for the demo
	 */
	seller?: Maybe<Scalars["String"]["output"]>;
	/** The SKU (stock keeping unit) for the variant. */
	sku?: Maybe<Scalars["String"]["output"]>;
	/** Specifications for this variant. These reflect predefined groups of attributes that can be used to describe the variant on the frontend. */
	specifications?: Maybe<Array<ProductSpecificationGroup>>;
};

export type ProductVariantDimension = {
	label: Scalars["String"]["output"];
	name: Scalars["String"]["output"];
	options: Array<ProductVariantDimensionOption>;
};

export type ProductVariantDimensionColorOption =
	BaseProductVariantDimensionOption & {
		availability?: Maybe<ProductAvailability>;
		/** Hex value of the color used to display the option. */
		color: Scalars["String"]["output"];
		label: Scalars["String"]["output"];
		product?: Maybe<Product>;
		selected: Scalars["Boolean"]["output"];
		value: Scalars["String"]["output"];
		variant?: Maybe<ProductVariant>;
	};

export type ProductVariantDimensionImageOption =
	BaseProductVariantDimensionOption & {
		availability?: Maybe<ProductAvailability>;
		label: Scalars["String"]["output"];
		product?: Maybe<Product>;
		selected: Scalars["Boolean"]["output"];
		value: Scalars["String"]["output"];
		variant?: Maybe<ProductVariant>;
	};

export type ProductVariantDimensionOption =
	| ProductVariantDimensionColorOption
	| ProductVariantDimensionImageOption
	| ProductVariantDimensionTextOption;

export type ProductVariantDimensionTextOption =
	BaseProductVariantDimensionOption & {
		availability?: Maybe<ProductAvailability>;
		label: Scalars["String"]["output"];
		product?: Maybe<Product>;
		selected: Scalars["Boolean"]["output"];
		value: Scalars["String"]["output"];
		variant?: Maybe<ProductVariant>;
	};

export type ProductYouTubeVideo = {
	alt?: Maybe<Scalars["String"]["output"]>;
	description?: Maybe<Scalars["String"]["output"]>;
	/** The YouTube video ID. */
	videoId: Scalars["String"]["output"];
};

export type ProductsResult = {
	facets?: Maybe<Array<Facet>>;
	results: Array<Product>;
	total: Scalars["Int"]["output"];
};

export type Query = {
	addressValidate?: Maybe<AddressValidationResult>;
	businessUnits: Array<BusinessUnit>;
	cart?: Maybe<Cart>;
	/** Get a list of categories */
	categories: CategoriesResult;
	categoryPageByPath?: Maybe<CategoryPage>;
	contentPage?: Maybe<ContentPage>;
	customer?: Maybe<Customer>;
	customerExists: CustomerExistsResult;
	/** Returns the requested order for the current user. */
	order?: Maybe<Order>;
	/** Returns the requested order overview for a global user. This is meant for anonymous viewing of orders. */
	orderConfirmation?: Maybe<Order>;
	/**
	 * Returns the list of orders for the current user. If a search term is provided the results will be filtered to match
	 * the search term. The results will be sorted with the most recent order shown first.
	 */
	orders?: Maybe<OrderResult>;
	page?: Maybe<Page>;
	pages: PagesResult;
	product?: Maybe<Product>;
	productListingPage?: Maybe<ProductListingPage>;
	productSearch: ProductsResult;
	products: ProductsResult;
	site?: Maybe<Site>;
};

export type QueryAddressValidateArgs = {
	address: AddressInput;
	storeContext: StoreContextInput;
};

export type QueryBusinessUnitsArgs = {
	storeContext: StoreContextInput;
};

export type QueryCartArgs = {
	storeContext: StoreContextInput;
};

export type QueryCategoriesArgs = {
	page?: Scalars["Int"]["input"];
	pageSize?: Scalars["Int"]["input"];
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	sort?: InputMaybe<CategorySortOrder>;
	storeContext: StoreContextInput;
};

export type QueryCategoryPageByPathArgs = {
	path: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type QueryContentPageArgs = {
	path: Scalars["String"]["input"];
	previewID?: InputMaybe<Scalars["String"]["input"]>;
	storeContext: StoreContextInput;
	version?: InputMaybe<ContentVersion>;
};

export type QueryCustomerArgs = {
	storeContext: StoreContextInput;
};

export type QueryCustomerExistsArgs = {
	email: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type QueryOrderArgs = {
	orderId: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type QueryOrderConfirmationArgs = {
	orderId: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type QueryOrdersArgs = {
	page?: InputMaybe<Scalars["Int"]["input"]>;
	pageSize?: InputMaybe<Scalars["Int"]["input"]>;
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	storeContext: StoreContextInput;
};

export type QueryPageArgs = {
	path: Scalars["String"]["input"];
	previewID?: InputMaybe<Scalars["String"]["input"]>;
	storeContext: StoreContextInput;
	version?: InputMaybe<ContentVersion>;
};

export type QueryPagesArgs = {
	page?: Scalars["Int"]["input"];
	pageSize?: Scalars["Int"]["input"];
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	sort?: InputMaybe<PageSortOrder>;
	storeContext: StoreContextInput;
	types?: InputMaybe<Array<PageType>>;
	version?: InputMaybe<ContentVersion>;
};

export type QueryProductArgs = {
	slug: Scalars["String"]["input"];
	storeContext: StoreContextInput;
};

export type QueryProductListingPageArgs = {
	path: Scalars["String"]["input"];
	previewID?: InputMaybe<Scalars["String"]["input"]>;
	storeContext: StoreContextInput;
	version?: InputMaybe<ContentVersion>;
};

export type QueryProductSearchArgs = {
	categoryId?: InputMaybe<Scalars["String"]["input"]>;
	filters?: InputMaybe<Array<FacetFilterInput>>;
	page?: Scalars["Int"]["input"];
	pageSize?: Scalars["Int"]["input"];
	prefilters?: InputMaybe<Array<FilterConditionInput>>;
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	sort: ProductSortOrder;
	storeContext: StoreContextInput;
};

export type QueryProductsArgs = {
	page?: Scalars["Int"]["input"];
	pageSize?: Scalars["Int"]["input"];
	storeContext: StoreContextInput;
};

export type QuerySiteArgs = {
	previewID?: InputMaybe<Scalars["String"]["input"]>;
	storeContext: StoreContextInput;
	version?: InputMaybe<ContentVersion>;
};

export type RangeFacet = {
	key: Scalars["String"]["output"];
	label: Scalars["String"]["output"];
	max: Scalars["Int"]["output"];
	min: Scalars["Int"]["output"];
	selectedMax?: Maybe<Scalars["Int"]["output"]>;
	selectedMin?: Maybe<Scalars["Int"]["output"]>;
};

export type ResourceLink = {
	hash?: Maybe<Scalars["String"]["output"]>;
	name?: Maybe<Scalars["String"]["output"]>;
	newTab?: Maybe<Scalars["Boolean"]["output"]>;
	pathname?: Maybe<Scalars["String"]["output"]>;
	type: ResourceLinkType;
	url: Scalars["String"]["output"];
};

export type ResourceLinkType = "asset" | "email" | "external" | "internal";

export type ResourceMeta = {
	description?: Maybe<Scalars["String"]["output"]>;
	title?: Maybe<Scalars["String"]["output"]>;
};

export type Reviews = {
	/** The number of reviews for the product. */
	amount: Scalars["Int"]["output"];
	/** The average rating for the product. */
	averageRating: Scalars["Float"]["output"];
};

export type RichText = {
	content: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	renderer?: Maybe<RichTextRenderer>;
};

export type RichTextRenderer = "contentful" | "storyblok";

/** Defines the available options for a salutation, including gender-neutral options. */
export type Salutation = "MR" | "MS" | "MX" | "NONE";

export type ShippingMethod = {
	/** Shipping method enabled */
	enabled: Scalars["Boolean"]["output"];
	/** Unique key of the shipping method */
	key: Scalars["String"]["output"];
	/** Shipping method price */
	price: Money;
};

export type Site = {
	footerMenu?: Maybe<Array<MenuBlock>>;
	id: Scalars["ID"]["output"];
	infoPages?: Maybe<InfoPages>;
	logo?: Maybe<ContentAsset>;
	mainMenu?: Maybe<Array<MenuItem>>;
	paymentMethods?: Maybe<Array<ContentAsset>>;
	socials?: Maybe<Socials>;
	usps?: Maybe<Array<Usps>>;
};

export type Socials = {
	facebook?: Maybe<Scalars["String"]["output"]>;
	instagram?: Maybe<Scalars["String"]["output"]>;
	linkedIn?: Maybe<Scalars["String"]["output"]>;
	twitter?: Maybe<Scalars["String"]["output"]>;
	youtube?: Maybe<Scalars["String"]["output"]>;
};

export type Store = {
	id: Scalars["ID"]["output"];
	name: Scalars["String"]["output"];
};

export type StoreContext = {
	/** The currency used in the store context. */
	currency: Scalars["String"]["output"];
	/** The locale of the store context. For example: en-US. */
	locale: Scalars["String"]["output"];
	/** The store key associated with the store context. */
	storeKey: Scalars["String"]["output"];
};

export type StoreContextInput = {
	/** The currency used in the store context. */
	currency: Scalars["String"]["input"];
	/** The locale of the store context. For example: en-US. */
	locale: Scalars["String"]["input"];
	/** The store key associated with the store context. */
	storeKey: Scalars["String"]["input"];
};

export type Tax = {
	/** Rate of the tax line item as percentage from 0 to 1. */
	rate: Scalars["Float"]["output"];
	/** Value in money of the tax line item. */
	value: Money;
};

export type TaxedPrice = {
	gross: Money;
	net: Money;
	tax: Tax;
};

/** A total price consists of items that can have different tax rates. */
export type TaxedTotalPrice = {
	gross: Money;
	net: Money;
	taxes: Array<Tax>;
};

export type Teaser = {
	content: Scalars["String"]["output"];
	id: Scalars["ID"]["output"];
	image: ContentAsset;
	link: ResourceLink;
	title: Scalars["String"]["output"];
};

export type TeaserImagePosition =
	/** Left */
	| "left"
	/** Right */
	| "right";

export type TeaserRow = {
	id: Scalars["ID"]["output"];
	items: Array<Teaser>;
	title: Scalars["String"]["output"];
	/** If empty, no view more button will be shown */
	viewMoreLink?: Maybe<ResourceLink>;
};

export type ThreeColumns = {
	column1: Array<ColumnBlock>;
	column2: Array<ColumnBlock>;
	column3: Array<ColumnBlock>;
	id: Scalars["ID"]["output"];
};

export type ToggleFacet = {
	key: Scalars["String"]["output"];
	label: Scalars["String"]["output"];
	options: Array<FacetValue>;
};

export type TranslatedPath = {
	locale: Scalars["String"]["output"];
	path: Scalars["String"]["output"];
};

export type TwoColumns = {
	column1: Array<ColumnBlock>;
	column2: Array<ColumnBlock>;
	id: Scalars["ID"]["output"];
	ratio: TwoColumnsRatio;
};

export type TwoColumnsRatio =
	/** 50 / 50 */
	| "equal"
	/** 60 / 40 */
	| "leftBig"
	/** 40 / 60 */
	| "rightBig";

export type Usp = {
	id: Scalars["ID"]["output"];
	link?: Maybe<ResourceLink>;
	title: Scalars["String"]["output"];
};

export type UspList = {
	id: Scalars["ID"]["output"];
	items: Array<Usp>;
};

export type Usps = {
	id: Scalars["ID"]["output"];
	usp1: Scalars["String"]["output"];
	usp2: Scalars["String"]["output"];
	usp3: Scalars["String"]["output"];
	usp4?: Maybe<Scalars["String"]["output"]>;
};

export type Join__Graph = "ACCOUNT" | "CATALOG" | "CHECKOUT" | "CMS" | "ORDER";

export type Link__Purpose =
	/** `EXECUTION` features provide metadata necessary for operation execution. */
	| "EXECUTION"
	/** `SECURITY` features provide metadata necessary to securely resolve fields. */
	| "SECURITY";

export type StoreContentPreviewData = {
	id?: Maybe<Scalars["ID"]["output"]>;
};

export type StoreContentPreviewDataMutationVariables = Exact<{
	path: Scalars["String"]["input"];
	data: Scalars["String"]["input"];
}>;

export type StoreContentPreviewDataMutation = {
	storeContentPreview: { id?: string | null };
};

export type ApplePayPaymentMethodFragment = {
	id: string;
	name: string;
	provider: string;
	extra?:
		| {
				type: string;
				brands?: Array<string> | null;
				configuration?: { merchantId: string; merchantName: string } | null;
		  }
		| {}
		| null;
};

export type ValidateApplePayPaymentMutationVariables = Exact<{
	storeContext: StoreContextInput;
	paymentContext: ApplePayPaymentContextInput;
}>;

export type ValidateApplePayPaymentMutation = {
	validateApplePayPayment: { data: string };
};

export type CreateApplePayOrderMutationVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type CreateApplePayOrderMutation = {
	checkoutComplete: { orderId: string; orderNumber: string };
};

export type ApplePayPaymentCreateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	paymentContext: PaymentContextInput;
}>;

export type ApplePayPaymentCreateMutation = {
	createPayment: { redirectURL: string };
};

export type UpdateCheckoutAddressMutationVariables = Exact<{
	storeContext: StoreContextInput;
	setCustomerEmail?: InputMaybe<Scalars["String"]["input"]>;
	setBillingAddress?: InputMaybe<AddressInput>;
	setShippingAddress?: InputMaybe<AddressInput>;
}>;

export type UpdateCheckoutAddressMutation = {
	checkoutUpdate: {
		customerEmail?: string | null;
		id: { version: number; id: string };
		availablePaymentMethods: Array<{
			name: string;
			paymentMethod: Array<{
				id: string;
				name: string;
				provider: string;
				extra?:
					| {
							type: string;
							brands?: Array<string> | null;
							configuration?: {
								merchantId: string;
								merchantName: string;
							} | null;
					  }
					| {}
					| null;
			}>;
		}>;
		billingAddress?: { id?: string | null } | null;
		lineItems: Array<{
			id: string;
			productName: string;
			quantity: number;
			price: { gross: { centAmount: number; currency: Currency } };
		}>;
		total: { gross: { centAmount: number; currency: Currency } };
	};
};

export type CheckoutCustomerExistsQueryVariables = Exact<{
	storeContext: StoreContextInput;
	email: Scalars["String"]["input"];
}>;

export type CheckoutCustomerExistsQuery = {
	customerExists: { result: boolean };
};

export type CustomerCreateEmailStepMutationVariables = Exact<{
	storeContext: StoreContextInput;
	customer: CustomerCreateInput;
}>;

export type CustomerCreateEmailStepMutation = {
	customerCreate: { createdAt: string };
};

export type CheckoutCartUpdateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id?: InputMaybe<CartIdentifierInput>;
	setCustomerEmail?: InputMaybe<Scalars["String"]["input"]>;
	setBillingAddress?: InputMaybe<AddressInput>;
	setShippingAddress?: InputMaybe<AddressInput>;
	setPaymentMethod?: InputMaybe<PaymentMethodInput>;
}>;

export type CheckoutCartUpdateMutation = {
	checkoutUpdate: {
		customerEmail?: string | null;
		id: { version: number; id: string };
		availablePaymentMethods: Array<{
			name: string;
			paymentMethod: Array<{
				id: string;
				name: string;
				provider: string;
				extra?:
					| {
							type: string;
							brands?: Array<string> | null;
							configuration?: {
								merchantId: string;
								merchantName: string;
							} | null;
					  }
					| {}
					| null;
			}>;
		}>;
		billingAddress?: { id?: string | null } | null;
		lineItems: Array<{
			id: string;
			productName: string;
			quantity: number;
			price: { gross: { centAmount: number; currency: Currency } };
		}>;
		total: { gross: { centAmount: number; currency: Currency } };
	};
};

export type GetCartForCheckoutQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCartForCheckoutQuery = {
	cart?: {
		customerEmail?: string | null;
		id: { version: number; id: string };
		availablePaymentMethods: Array<{
			name: string;
			paymentMethod: Array<{
				id: string;
				name: string;
				provider: string;
				extra?:
					| {
							type: string;
							brands?: Array<string> | null;
							configuration?: {
								merchantId: string;
								merchantName: string;
							} | null;
					  }
					| {}
					| null;
			}>;
		}>;
		billingAddress?: { id?: string | null } | null;
		lineItems: Array<{
			id: string;
			productName: string;
			quantity: number;
			price: { gross: { centAmount: number; currency: Currency } };
		}>;
		total: { gross: { centAmount: number; currency: Currency } };
	} | null;
};

export type CheckoutCartFragmentFragment = {
	customerEmail?: string | null;
	id: { version: number; id: string };
	availablePaymentMethods: Array<{
		name: string;
		paymentMethod: Array<{
			id: string;
			name: string;
			provider: string;
			extra?:
				| {
						type: string;
						brands?: Array<string> | null;
						configuration?: { merchantId: string; merchantName: string } | null;
				  }
				| {}
				| null;
		}>;
	}>;
	billingAddress?: { id?: string | null } | null;
	lineItems: Array<{
		id: string;
		productName: string;
		quantity: number;
		price: { gross: { centAmount: number; currency: Currency } };
	}>;
	total: { gross: { centAmount: number; currency: Currency } };
};

export type FooterInfoPagesFragmentFragment = {
	infoPages?: {
		privacy?: { url: string; name?: string | null } | null;
		terms?: { url: string; name?: string | null } | null;
		cookies?: { url: string; name?: string | null } | null;
	} | null;
};

export type GetSimpleSiteQueryVariables = Exact<{
	storeContext: StoreContextInput;
	version: ContentVersion;
	previewID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetSimpleSiteQuery = {
	site?: {
		logo?: { alt?: string | null; filename: string } | null;
		infoPages?: {
			privacy?: { url: string; name?: string | null } | null;
			terms?: { url: string; name?: string | null } | null;
			cookies?: { url: string; name?: string | null } | null;
			business?: { url: string; name?: string | null } | null;
			service?: { url: string; name?: string | null } | null;
		} | null;
		mainMenu?: Array<{
			__typename: "MenuItem";
			id: string;
			label?: string | null;
			subMenuItems?: Array<{
				__typename: "MenuItem";
				id: string;
				label?: string | null;
				subMenuItems?: Array<{
					__typename: "MenuItem";
					id: string;
					label?: string | null;
					target?:
						| { __typename: "ProductCategory"; name: string; path: string }
						| { __typename: "ResourceLink"; url: string }
						| null;
				}> | null;
				target?:
					| { __typename: "ProductCategory"; name: string; path: string }
					| { __typename: "ResourceLink"; url: string }
					| null;
			}> | null;
			target?:
				| { __typename: "ProductCategory"; name: string; path: string }
				| { __typename: "ResourceLink"; url: string }
				| null;
		}> | null;
	} | null;
};

export type CheckoutSummaryFragmentFragment = {
	id: { id: string; version: number };
	lineItems: Array<{
		id: string;
		quantity: number;
		productSlug?: string | null;
		productName: string;
		addedAt?: string | null;
		variant: {
			name: string;
			sku: string;
			primaryImage?: { url: string; alt?: string | null } | null;
		};
	}>;
	subTotal: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		taxes: Array<{
			rate: number;
			value: { centAmount: number; currency: Currency };
		}>;
	};
	total: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		taxes: Array<{
			rate: number;
			value: { centAmount: number; currency: Currency };
		}>;
	};
	shippingCosts?: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		tax: { rate: number; value: { centAmount: number; currency: Currency } };
	} | null;
};

export type BillingAddressFragmentFragment = {
	id?: string | null;
	email?: string | null;
	givenName?: string | null;
	familyName?: string | null;
	streetName?: string | null;
	streetNumber?: string | null;
	streetNumberSuffix?: string | null;
	postalCode: string;
	city?: string | null;
	phone?: string | null;
	country: string;
	company?: string | null;
};

export type GetCheckoutInformationQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCheckoutInformationQuery = {
	cart?: {
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		id: { id: string; version: number };
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
	} | null;
};

export type UpdateCheckoutInformationMutationVariables = Exact<{
	storeContext: StoreContextInput;
	setBillingAddress?: InputMaybe<AddressInput>;
}>;

export type UpdateCheckoutInformationMutation = {
	checkoutUpdate: {
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
	};
};

export type CustomerExistsQueryVariables = Exact<{
	storeContext: StoreContextInput;
	email: Scalars["String"]["input"];
}>;

export type CustomerExistsQuery = { customerExists: { result: boolean } };

export type GetCheckoutLoginQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCheckoutLoginQuery = {
	cart?: {
		customerEmail?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		id: { id: string; version: number };
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
	} | null;
};

export type UpdateCheckoutLoginMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id: CartIdentifierInput;
	setCustomerEmail?: InputMaybe<Scalars["String"]["input"]>;
	setBillingAddress?: InputMaybe<AddressInput>;
}>;

export type UpdateCheckoutLoginMutation = {
	checkoutUpdate: { id: { id: string; version: number } };
};

export type CheckoutCustomerLoginMutationVariables = Exact<{
	storeContext: StoreContextInput;
	email: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
}>;

export type CheckoutCustomerLoginMutation = {
	customerLogin: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type GetCheckoutPaymentQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCheckoutPaymentQuery = {
	cart?: {
		availablePaymentMethods: Array<{
			name: string;
			paymentMethod: Array<{
				id: string;
				name: string;
				provider: string;
				issuers?: Array<{ id: string; name: string }> | null;
			}>;
		}>;
		selectedPaymentMethod?: {
			id: string;
			name: string;
			provider: string;
			issuers?: Array<{ id: string; name: string }> | null;
		} | null;
		id: { id: string; version: number };
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
	} | null;
};

export type PaymentMethodFragment = {
	id: string;
	name: string;
	provider: string;
	issuers?: Array<{ id: string; name: string }> | null;
};

export type PaymentCreateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	paymentContext: PaymentContextInput;
}>;

export type PaymentCreateMutation = { createPayment: { redirectURL: string } };

export type CreateOrderMutationVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type CreateOrderMutation = {
	checkoutComplete: { orderId: string; orderNumber: string };
};

type FacetsFragment_OptionsFacet_Fragment = {
	__typename: "OptionsFacet";
	key: string;
	label: string;
	options: Array<{
		key: string;
		label: string;
		count: number;
		selected: boolean;
	}>;
};

type FacetsFragment_RangeFacet_Fragment = {
	__typename: "RangeFacet";
	key: string;
	label: string;
	min: number;
	max: number;
	selectedMin?: number | null;
	selectedMax?: number | null;
};

type FacetsFragment_ToggleFacet_Fragment = {
	__typename: "ToggleFacet";
	key: string;
	label: string;
	options: Array<{
		key: string;
		label: string;
		count: number;
		selected: boolean;
	}>;
};

export type FacetsFragmentFragment =
	| FacetsFragment_OptionsFacet_Fragment
	| FacetsFragment_RangeFacet_Fragment
	| FacetsFragment_ToggleFacet_Fragment;

export type OptionsFacetFragmentFragment = {
	__typename: "OptionsFacet";
	key: string;
	label: string;
	options: Array<{
		key: string;
		label: string;
		count: number;
		selected: boolean;
	}>;
};

export type RangeFacetFragmentFragment = {
	__typename: "RangeFacet";
	key: string;
	label: string;
	min: number;
	max: number;
	selectedMin?: number | null;
	selectedMax?: number | null;
};

export type ToggleFacetFragmentFragment = {
	__typename: "ToggleFacet";
	key: string;
	label: string;
	options: Array<{
		key: string;
		label: string;
		count: number;
		selected: boolean;
	}>;
};

export type ProductListingConfigFragment = {
	categoryId: string;
	defaultOrder?: ProductSortOrder | null;
	prefilters?: Array<{ key: string; value: string }> | null;
};

export type ProductsResultFragment = {
	total: number;
	results: Array<{
		name: string;
		slug: string;
		ribbon?: { kind?: string | null; label?: string | null } | null;
		image?: { url: string; alt?: string | null } | null;
		startingPrice?: {
			gross: { centAmount: number; currency: Currency };
		} | null;
		primaryVariant: {
			id: string;
			sku?: string | null;
			availability?: ProductAvailability | null;
			seller?: string | null;
			price?: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
			} | null;
		};
	}>;
	facets?: Array<
		| {
				__typename: "OptionsFacet";
				key: string;
				label: string;
				options: Array<{
					key: string;
					label: string;
					count: number;
					selected: boolean;
				}>;
		  }
		| {
				__typename: "RangeFacet";
				key: string;
				label: string;
				min: number;
				max: number;
				selectedMin?: number | null;
				selectedMax?: number | null;
		  }
		| {
				__typename: "ToggleFacet";
				key: string;
				label: string;
				options: Array<{
					key: string;
					label: string;
					count: number;
					selected: boolean;
				}>;
		  }
	> | null;
};

export type GetCategoryPageQueryVariables = Exact<{
	storeContext: StoreContextInput;
	path: Scalars["String"]["input"];
}>;

export type GetCategoryPageQuery = {
	categoryPageByPath?: {
		meta?: {
			title?: string | null;
			description?: string | null;
			keywords?: Array<string> | null;
		} | null;
		translatedPaths?: Array<{ locale: string; path: string }> | null;
		breadcrumbs: Array<{ name: string; slug: string; type: BreadcrumbType }>;
		category: { id: string; name: string; description?: string | null };
		productListingConfig: {
			categoryId: string;
			defaultOrder?: ProductSortOrder | null;
			prefilters?: Array<{ key: string; value: string }> | null;
		};
	} | null;
};

export type CategoryPageFragmentFragment = {
	meta?: {
		title?: string | null;
		description?: string | null;
		keywords?: Array<string> | null;
	} | null;
	translatedPaths?: Array<{ locale: string; path: string }> | null;
	breadcrumbs: Array<{ name: string; slug: string; type: BreadcrumbType }>;
	category: { id: string; name: string; description?: string | null };
	productListingConfig: {
		categoryId: string;
		defaultOrder?: ProductSortOrder | null;
		prefilters?: Array<{ key: string; value: string }> | null;
	};
};

export type GetProductsForCategoryQueryVariables = Exact<{
	storeContext: StoreContextInput;
	filters?: InputMaybe<Array<FacetFilterInput> | FacetFilterInput>;
	prefilters?: InputMaybe<Array<FilterConditionInput> | FilterConditionInput>;
	categoryId: Scalars["String"]["input"];
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	pageSize: Scalars["Int"]["input"];
	page: Scalars["Int"]["input"];
	sort: ProductSortOrder;
}>;

export type GetProductsForCategoryQuery = {
	productSearch: {
		total: number;
		results: Array<{
			name: string;
			slug: string;
			ribbon?: { kind?: string | null; label?: string | null } | null;
			image?: { url: string; alt?: string | null } | null;
			startingPrice?: {
				gross: { centAmount: number; currency: Currency };
			} | null;
			primaryVariant: {
				id: string;
				sku?: string | null;
				availability?: ProductAvailability | null;
				seller?: string | null;
				price?: {
					net: { centAmount: number; currency: Currency };
					gross: { centAmount: number; currency: Currency };
				} | null;
			};
		}>;
		facets?: Array<
			| {
					__typename: "OptionsFacet";
					key: string;
					label: string;
					options: Array<{
						key: string;
						label: string;
						count: number;
						selected: boolean;
					}>;
			  }
			| {
					__typename: "RangeFacet";
					key: string;
					label: string;
					min: number;
					max: number;
					selectedMin?: number | null;
					selectedMax?: number | null;
			  }
			| {
					__typename: "ToggleFacet";
					key: string;
					label: string;
					options: Array<{
						key: string;
						label: string;
						count: number;
						selected: boolean;
					}>;
			  }
		> | null;
	};
};

export type GetProductListingPageQueryVariables = Exact<{
	storeContext: StoreContextInput;
	path: Scalars["String"]["input"];
	previewID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetProductListingPageQuery = {
	productListingPage?: {
		__typename: "ProductListingPage";
		id: string;
		name: string;
		translatedPaths?: Array<{ locale: string; path: string }> | null;
		seo?: {
			__typename: "ContentSEO";
			title?: string | null;
			description?: string | null;
			ogTitle?: string | null;
			ogDescription?: string | null;
			ogImage?: string | null;
			twitterTitle?: string | null;
			twitterDescription?: string | null;
			twitterImage?: string | null;
		} | null;
		breadcrumbs?: Array<{
			name: string;
			slug: string;
			type: BreadcrumbType;
		}> | null;
		hero?: {
			__typename: "Hero";
			id: string;
			title: string;
			imagePosition: ImagePosition;
			imageStyle: ImageStyle;
			bodyText?: {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
			} | null;
			buttons?: Array<{
				__typename: "Button";
				id: string;
				title: string;
				variant: ButtonVariant;
				link: { url: string; newTab?: boolean | null; hash?: string | null };
			}> | null;
			image: { filename: string; alt?: string | null };
		} | null;
		usps?: {
			id: string;
			usp1: string;
			usp2: string;
			usp3: string;
			usp4?: string | null;
		} | null;
		productListingConfig: {
			categoryId: string;
			defaultOrder?: ProductSortOrder | null;
			prefilters?: Array<{ key: string; value: string }> | null;
		};
		teaserRow?: {
			__typename: "TeaserRow";
			id: string;
			title: string;
			viewMoreLink?: { url: string; newTab?: boolean | null } | null;
			items: Array<{
				__typename: "Teaser";
				id: string;
				content: string;
				title: string;
				image: { alt?: string | null; filename: string };
				link: { url: string };
			}>;
		} | null;
	} | null;
};

export type ProductListingPageFragment = {
	__typename: "ProductListingPage";
	id: string;
	name: string;
	translatedPaths?: Array<{ locale: string; path: string }> | null;
	seo?: {
		__typename: "ContentSEO";
		title?: string | null;
		description?: string | null;
		ogTitle?: string | null;
		ogDescription?: string | null;
		ogImage?: string | null;
		twitterTitle?: string | null;
		twitterDescription?: string | null;
		twitterImage?: string | null;
	} | null;
	breadcrumbs?: Array<{
		name: string;
		slug: string;
		type: BreadcrumbType;
	}> | null;
	hero?: {
		__typename: "Hero";
		id: string;
		title: string;
		imagePosition: ImagePosition;
		imageStyle: ImageStyle;
		bodyText?: {
			__typename: "RichText";
			id: string;
			renderer?: RichTextRenderer | null;
			content: string;
		} | null;
		buttons?: Array<{
			__typename: "Button";
			id: string;
			title: string;
			variant: ButtonVariant;
			link: { url: string; newTab?: boolean | null; hash?: string | null };
		}> | null;
		image: { filename: string; alt?: string | null };
	} | null;
	usps?: {
		id: string;
		usp1: string;
		usp2: string;
		usp3: string;
		usp4?: string | null;
	} | null;
	productListingConfig: {
		categoryId: string;
		defaultOrder?: ProductSortOrder | null;
		prefilters?: Array<{ key: string; value: string }> | null;
	};
	teaserRow?: {
		__typename: "TeaserRow";
		id: string;
		title: string;
		viewMoreLink?: { url: string; newTab?: boolean | null } | null;
		items: Array<{
			__typename: "Teaser";
			id: string;
			content: string;
			title: string;
			image: { alt?: string | null; filename: string };
			link: { url: string };
		}>;
	} | null;
};

export type GetProductsOldQueryVariables = Exact<{
	storeContext: StoreContextInput;
	sort: ProductSortOrder;
	filters?: InputMaybe<Array<FacetFilterInput> | FacetFilterInput>;
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	pageSize: Scalars["Int"]["input"];
	page: Scalars["Int"]["input"];
}>;

export type GetProductsOldQuery = {
	productSearch: {
		total: number;
		results: Array<{
			name: string;
			slug: string;
			ribbon?: { kind?: string | null; label?: string | null } | null;
			image?: { url: string; alt?: string | null } | null;
			startingPrice?: {
				gross: { centAmount: number; currency: Currency };
			} | null;
			primaryVariant: {
				id: string;
				sku?: string | null;
				availability?: ProductAvailability | null;
				seller?: string | null;
				price?: {
					net: { centAmount: number; currency: Currency };
					gross: { centAmount: number; currency: Currency };
				} | null;
			};
		}>;
		facets?: Array<
			| {
					__typename: "OptionsFacet";
					key: string;
					label: string;
					options: Array<{
						key: string;
						label: string;
						count: number;
						selected: boolean;
					}>;
			  }
			| {
					__typename: "RangeFacet";
					key: string;
					label: string;
					min: number;
					max: number;
					selectedMin?: number | null;
					selectedMax?: number | null;
			  }
			| {
					__typename: "ToggleFacet";
					key: string;
					label: string;
					options: Array<{
						key: string;
						label: string;
						count: number;
						selected: boolean;
					}>;
			  }
		> | null;
	};
};

export type GetProductsQueryVariables = Exact<{
	storeContext: StoreContextInput;
	sort: ProductSortOrder;
	filters?: InputMaybe<Array<FacetFilterInput> | FacetFilterInput>;
	searchTerm?: InputMaybe<Scalars["String"]["input"]>;
	pageSize: Scalars["Int"]["input"];
	page: Scalars["Int"]["input"];
}>;

export type GetProductsQuery = {
	productSearch: {
		total: number;
		results: Array<{
			name: string;
			slug: string;
			ribbon?: { kind?: string | null; label?: string | null } | null;
			image?: { url: string; alt?: string | null } | null;
			startingPrice?: {
				gross: { centAmount: number; currency: Currency };
			} | null;
			primaryVariant: {
				id: string;
				sku?: string | null;
				availability?: ProductAvailability | null;
				seller?: string | null;
				price?: {
					net: { centAmount: number; currency: Currency };
					gross: { centAmount: number; currency: Currency };
				} | null;
			};
		}>;
		facets?: Array<
			| {
					__typename: "OptionsFacet";
					key: string;
					label: string;
					options: Array<{
						key: string;
						label: string;
						count: number;
						selected: boolean;
					}>;
			  }
			| {
					__typename: "RangeFacet";
					key: string;
					label: string;
					min: number;
					max: number;
					selectedMin?: number | null;
					selectedMax?: number | null;
			  }
			| {
					__typename: "ToggleFacet";
					key: string;
					label: string;
					options: Array<{
						key: string;
						label: string;
						count: number;
						selected: boolean;
					}>;
			  }
		> | null;
	};
};

export type FooterMenuFragmentFragment = {
	id: string;
	label: string;
	menuLinks: Array<{ name?: string | null; url: string }>;
};

export type PaymentFragmentFragment = {
	paymentMethods?: Array<{ filename: string; alt?: string | null }> | null;
};

export type FooterSocialsFragmentFragment = {
	socials?: {
		facebook?: string | null;
		instagram?: string | null;
		linkedIn?: string | null;
		twitter?: string | null;
		youtube?: string | null;
	} | null;
};

export type CustomerBusinessUnitsQueryQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type CustomerBusinessUnitsQueryQuery = {
	customer?: {
		businessUnits?: Array<{
			id: string;
			name: string;
			stores?: Array<{ id: string; name: string }> | null;
		}> | null;
	} | null;
};

export type GetCartQuantityQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCartQuantityQuery = {
	cart?: { lineItems: Array<{ quantity: number }> } | null;
};

export type HeaderFragmentFragment = {
	logo?: { alt?: string | null; filename: string } | null;
	infoPages?: {
		business?: { url: string; name?: string | null } | null;
		service?: { url: string; name?: string | null } | null;
	} | null;
	mainMenu?: Array<{
		__typename: "MenuItem";
		id: string;
		label?: string | null;
		subMenuItems?: Array<{
			__typename: "MenuItem";
			id: string;
			label?: string | null;
			subMenuItems?: Array<{
				__typename: "MenuItem";
				id: string;
				label?: string | null;
				target?:
					| { __typename: "ProductCategory"; name: string; path: string }
					| { __typename: "ResourceLink"; url: string }
					| null;
			}> | null;
			target?:
				| { __typename: "ProductCategory"; name: string; path: string }
				| { __typename: "ResourceLink"; url: string }
				| null;
		}> | null;
		target?:
			| { __typename: "ProductCategory"; name: string; path: string }
			| { __typename: "ResourceLink"; url: string }
			| null;
	}> | null;
};

export type MainMenuFragmentFragment = {
	mainMenu?: Array<{
		__typename: "MenuItem";
		id: string;
		label?: string | null;
		subMenuItems?: Array<{
			__typename: "MenuItem";
			id: string;
			label?: string | null;
			subMenuItems?: Array<{
				__typename: "MenuItem";
				id: string;
				label?: string | null;
				target?:
					| { __typename: "ProductCategory"; name: string; path: string }
					| { __typename: "ResourceLink"; url: string }
					| null;
			}> | null;
			target?:
				| { __typename: "ProductCategory"; name: string; path: string }
				| { __typename: "ResourceLink"; url: string }
				| null;
		}> | null;
		target?:
			| { __typename: "ProductCategory"; name: string; path: string }
			| { __typename: "ResourceLink"; url: string }
			| null;
	}> | null;
};

export type TopMenuItemFragmentFragment = {
	__typename: "MenuItem";
	id: string;
	label?: string | null;
	subMenuItems?: Array<{
		__typename: "MenuItem";
		id: string;
		label?: string | null;
		subMenuItems?: Array<{
			__typename: "MenuItem";
			id: string;
			label?: string | null;
			target?:
				| { __typename: "ProductCategory"; name: string; path: string }
				| { __typename: "ResourceLink"; url: string }
				| null;
		}> | null;
		target?:
			| { __typename: "ProductCategory"; name: string; path: string }
			| { __typename: "ResourceLink"; url: string }
			| null;
	}> | null;
	target?:
		| { __typename: "ProductCategory"; name: string; path: string }
		| { __typename: "ResourceLink"; url: string }
		| null;
};

export type SubMenuItemFragmentFragment = {
	__typename: "MenuItem";
	id: string;
	label?: string | null;
	subMenuItems?: Array<{
		__typename: "MenuItem";
		id: string;
		label?: string | null;
		target?:
			| { __typename: "ProductCategory"; name: string; path: string }
			| { __typename: "ResourceLink"; url: string }
			| null;
	}> | null;
	target?:
		| { __typename: "ProductCategory"; name: string; path: string }
		| { __typename: "ResourceLink"; url: string }
		| null;
};

export type MenuItemFieldsFragmentFragment = {
	__typename: "MenuItem";
	id: string;
	label?: string | null;
	target?:
		| { __typename: "ProductCategory"; name: string; path: string }
		| { __typename: "ResourceLink"; url: string }
		| null;
};

export type HeaderInfoPagesFragmentFragment = {
	infoPages?: {
		business?: { url: string; name?: string | null } | null;
		service?: { url: string; name?: string | null } | null;
	} | null;
};

export type GetSiteQueryVariables = Exact<{
	storeContext: StoreContextInput;
	version: ContentVersion;
	previewID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetSiteQuery = {
	site?: {
		footerMenu?: Array<{
			id: string;
			label: string;
			menuLinks: Array<{ name?: string | null; url: string }>;
		}> | null;
		logo?: { alt?: string | null; filename: string } | null;
		paymentMethods?: Array<{ filename: string; alt?: string | null }> | null;
		infoPages?: {
			privacy?: { url: string; name?: string | null } | null;
			terms?: { url: string; name?: string | null } | null;
			cookies?: { url: string; name?: string | null } | null;
			business?: { url: string; name?: string | null } | null;
			service?: { url: string; name?: string | null } | null;
		} | null;
		socials?: {
			facebook?: string | null;
			instagram?: string | null;
			linkedIn?: string | null;
			twitter?: string | null;
			youtube?: string | null;
		} | null;
		mainMenu?: Array<{
			__typename: "MenuItem";
			id: string;
			label?: string | null;
			subMenuItems?: Array<{
				__typename: "MenuItem";
				id: string;
				label?: string | null;
				subMenuItems?: Array<{
					__typename: "MenuItem";
					id: string;
					label?: string | null;
					target?:
						| { __typename: "ProductCategory"; name: string; path: string }
						| { __typename: "ResourceLink"; url: string }
						| null;
				}> | null;
				target?:
					| { __typename: "ProductCategory"; name: string; path: string }
					| { __typename: "ResourceLink"; url: string }
					| null;
			}> | null;
			target?:
				| { __typename: "ProductCategory"; name: string; path: string }
				| { __typename: "ResourceLink"; url: string }
				| null;
		}> | null;
	} | null;
};

export type SiteFragment = {
	footerMenu?: Array<{
		id: string;
		label: string;
		menuLinks: Array<{ name?: string | null; url: string }>;
	}> | null;
	logo?: { alt?: string | null; filename: string } | null;
	paymentMethods?: Array<{ filename: string; alt?: string | null }> | null;
	infoPages?: {
		privacy?: { url: string; name?: string | null } | null;
		terms?: { url: string; name?: string | null } | null;
		cookies?: { url: string; name?: string | null } | null;
		business?: { url: string; name?: string | null } | null;
		service?: { url: string; name?: string | null } | null;
	} | null;
	socials?: {
		facebook?: string | null;
		instagram?: string | null;
		linkedIn?: string | null;
		twitter?: string | null;
		youtube?: string | null;
	} | null;
	mainMenu?: Array<{
		__typename: "MenuItem";
		id: string;
		label?: string | null;
		subMenuItems?: Array<{
			__typename: "MenuItem";
			id: string;
			label?: string | null;
			subMenuItems?: Array<{
				__typename: "MenuItem";
				id: string;
				label?: string | null;
				target?:
					| { __typename: "ProductCategory"; name: string; path: string }
					| { __typename: "ResourceLink"; url: string }
					| null;
			}> | null;
			target?:
				| { __typename: "ProductCategory"; name: string; path: string }
				| { __typename: "ResourceLink"; url: string }
				| null;
		}> | null;
		target?:
			| { __typename: "ProductCategory"; name: string; path: string }
			| { __typename: "ResourceLink"; url: string }
			| null;
	}> | null;
};

export type CustomerLoginMutationVariables = Exact<{
	storeContext: StoreContextInput;
	email: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
}>;

export type CustomerLoginMutation = {
	customerLogin: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CustomerPasswordResetRequestMutationVariables = Exact<{
	storeContext: StoreContextInput;
	email: Scalars["String"]["input"];
}>;

export type CustomerPasswordResetRequestMutation = {
	customerPasswordResetRequest?: boolean | null;
};

export type CustomerPasswordResetMutationVariables = Exact<{
	storeContext: StoreContextInput;
	password: Scalars["String"]["input"];
	token: Scalars["String"]["input"];
}>;

export type CustomerPasswordResetMutation = {
	customerPasswordReset: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CustomerCreateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	customer: CustomerCreateInput;
}>;

export type CustomerCreateMutation = {
	customerCreate: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type BusinessUnitsFragment = {
	businessUnits?: Array<{
		id: string;
		name: string;
		stores?: Array<{ id: string; name: string }> | null;
	}> | null;
};

export type SetBusinessContextMutationVariables = Exact<{
	businessUnitId: Scalars["ID"]["input"];
	storeKey: Scalars["String"]["input"];
}>;

export type SetBusinessContextMutation = { setActiveBusinessContext: boolean };

export type PreviewOrdersQueryQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type PreviewOrdersQueryQuery = {
	orders?: {
		total: number;
		results: Array<{
			orderNumber?: string | null;
			orderStatus?: OrderStatus | null;
			createdAt: string;
			id: { id: string };
			total: { net: { centAmount: number; currency: Currency } };
			lineItems: Array<{
				id: string;
				variant: { primaryImage?: { alt?: string | null; url: string } | null };
			}>;
		}>;
	} | null;
};

export type CustomerAddressCreateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	address: AddressInput;
}>;

export type CustomerAddressCreateMutation = {
	customerAddressCreate: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CustomerAddressFragmentFragment = {
	id?: string | null;
	email?: string | null;
	salutation?: Salutation | null;
	givenName?: string | null;
	familyName?: string | null;
	streetName?: string | null;
	streetNumber?: string | null;
	streetNumberSuffix?: string | null;
	postalCode: string;
	city?: string | null;
	phone?: string | null;
	country: string;
	company?: string | null;
};

export type CustomerAddressUpdateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	addressId: Scalars["ID"]["input"];
	address: AddressInput;
}>;

export type CustomerAddressUpdateMutation = {
	customerAddressUpdate: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CustomerAddressQueryQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type CustomerAddressQueryQuery = {
	customer?: {
		givenName?: string | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	} | null;
};

export type OrderDetailsFragmentFragment = {
	orderNumber?: string | null;
	orderStatus?: OrderStatus | null;
	createdAt: string;
	id: { id: string };
	lineItems: Array<{
		id: string;
		productName: string;
		quantity: number;
		variant: {
			name: string;
			sku: string;
			primaryImage?: { alt?: string | null; url: string } | null;
		};
		price: { net: { centAmount: number; currency: Currency } };
	}>;
	total: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	};
	subTotal: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	};
	shippingCosts?: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	} | null;
};

export type OrderLineItemFragmentFragment = {
	productName: string;
	quantity: number;
	price: { net: { centAmount: number; currency: Currency } };
	variant: {
		name: string;
		sku: string;
		primaryImage?: { url: string; alt?: string | null } | null;
	};
};

export type OrderMetadataFragmentFragment = {
	createdAt: string;
	orderNumber?: string | null;
	orderStatus?: OrderStatus | null;
};

export type OrderTotalsFragmentFragment = {
	total: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	};
	subTotal: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	};
	shippingCosts?: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	} | null;
};

export type OrderDetailQueryQueryVariables = Exact<{
	storeContext: StoreContextInput;
	orderId: Scalars["String"]["input"];
}>;

export type OrderDetailQueryQuery = {
	order?: {
		orderNumber?: string | null;
		orderStatus?: OrderStatus | null;
		createdAt: string;
		id: { id: string };
		lineItems: Array<{
			id: string;
			productName: string;
			quantity: number;
			variant: {
				name: string;
				sku: string;
				primaryImage?: { alt?: string | null; url: string } | null;
			};
			price: { net: { centAmount: number; currency: Currency } };
		}>;
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
		};
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
		} | null;
	} | null;
};

export type OrderCardFragmentFragment = {
	orderNumber?: string | null;
	orderStatus?: OrderStatus | null;
	createdAt: string;
	id: { id: string };
	total: { net: { centAmount: number; currency: Currency } };
	lineItems: Array<{
		id: string;
		variant: { primaryImage?: { alt?: string | null; url: string } | null };
	}>;
};

export type OverviewOrdersQueryQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type OverviewOrdersQueryQuery = {
	orders?: {
		total: number;
		results: Array<{
			orderNumber?: string | null;
			orderStatus?: OrderStatus | null;
			createdAt: string;
			id: { id: string };
			total: { net: { centAmount: number; currency: Currency } };
			lineItems: Array<{
				id: string;
				variant: { primaryImage?: { alt?: string | null; url: string } | null };
			}>;
		}>;
	} | null;
};

export type BillingAddressDisplayFragmentFragment = {
	id?: string | null;
	email?: string | null;
	salutation?: Salutation | null;
	givenName?: string | null;
	familyName?: string | null;
	streetName?: string | null;
	streetNumber?: string | null;
	streetNumberSuffix?: string | null;
	postalCode: string;
	city?: string | null;
	phone?: string | null;
	country: string;
	company?: string | null;
};

export type GetCustomerQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCustomerQuery = {
	customer?: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	} | null;
};

export type CustomerFragment = {
	givenName?: string | null;
	email: string;
	vatNumber?: string | null;
	billingAddress?: {
		id?: string | null;
		email?: string | null;
		salutation?: Salutation | null;
		givenName?: string | null;
		familyName?: string | null;
		streetName?: string | null;
		streetNumber?: string | null;
		streetNumberSuffix?: string | null;
		postalCode: string;
		city?: string | null;
		phone?: string | null;
		country: string;
		company?: string | null;
	} | null;
	addresses?: Array<{
		id?: string | null;
		email?: string | null;
		salutation?: Salutation | null;
		givenName?: string | null;
		familyName?: string | null;
		streetName?: string | null;
		streetNumber?: string | null;
		streetNumberSuffix?: string | null;
		postalCode: string;
		city?: string | null;
		phone?: string | null;
		country: string;
		company?: string | null;
	}> | null;
};

export type AddressFragment = {
	id?: string | null;
	email?: string | null;
	salutation?: Salutation | null;
	givenName?: string | null;
	familyName?: string | null;
	streetName?: string | null;
	streetNumber?: string | null;
	streetNumberSuffix?: string | null;
	postalCode: string;
	city?: string | null;
	phone?: string | null;
	country: string;
	company?: string | null;
};

export type CustomerBillingAddressUpdateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	billingAddress: AddressInput;
}>;

export type CustomerBillingAddressUpdateMutation = {
	customerInformationUpdate: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CustomerEmailUpdateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	email: Scalars["String"]["input"];
	password: Scalars["String"]["input"];
}>;

export type CustomerEmailUpdateMutation = {
	customerEmailUpdate: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CustomerPasswordUpdateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	password: Scalars["String"]["input"];
	newPassword: Scalars["String"]["input"];
}>;

export type CustomerPasswordUpdateMutation = {
	customerPasswordUpdate: {
		givenName?: string | null;
		email: string;
		vatNumber?: string | null;
		billingAddress?: {
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		} | null;
		addresses?: Array<{
			id?: string | null;
			email?: string | null;
			salutation?: Salutation | null;
			givenName?: string | null;
			familyName?: string | null;
			streetName?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode: string;
			city?: string | null;
			phone?: string | null;
			country: string;
			company?: string | null;
		}> | null;
	};
};

export type CartOverviewFragmentFragment = {
	lineItems: Array<{
		id: string;
		quantity: number;
		productSlug?: string | null;
		productName: string;
		addedAt?: string | null;
		availableStock: number;
		price: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		};
		variant: {
			name: string;
			sku: string;
			primaryImage?: { url: string; alt?: string | null } | null;
		};
	}>;
	total: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	};
	subTotal: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		taxes: Array<{
			rate: number;
			value: { centAmount: number; currency: Currency };
		}>;
	};
	shippingCosts?: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	} | null;
	discountCodes: Array<{
		id: string;
		code: string;
		amount: { centAmount: number; currency: Currency };
	}>;
	id: { id: string; version: number };
};

export type CartSummaryFragmentFragment = {
	lineItems: Array<{ quantity: number }>;
	subTotal: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		taxes: Array<{
			rate: number;
			value: { centAmount: number; currency: Currency };
		}>;
	};
	total: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	};
	shippingCosts?: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
	} | null;
	discountCodes: Array<{
		id: string;
		code: string;
		amount: { centAmount: number; currency: Currency };
	}>;
	id: { id: string; version: number };
};

export type CartTaxesFragmentFragment = {
	rate: number;
	value: { centAmount: number; currency: Currency };
};

export type AddDiscountCodeMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id: CartIdentifierInput;
	code: Scalars["String"]["input"];
}>;

export type AddDiscountCodeMutation = {
	cartDiscountCodeAdd: {
		id: { id: string; version: number };
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			availableStock: number;
			price: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
				tax: {
					rate: number;
					value: { centAmount: number; currency: Currency };
				};
			};
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		discountCodes: Array<{
			id: string;
			code: string;
			amount: { centAmount: number; currency: Currency };
		}>;
	};
};

export type LineItemFragmentFragment = {
	id: string;
	quantity: number;
	productSlug?: string | null;
	productName: string;
	addedAt?: string | null;
	availableStock: number;
	price: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		tax: { rate: number; value: { centAmount: number; currency: Currency } };
	};
	variant: {
		name: string;
		sku: string;
		primaryImage?: { url: string; alt?: string | null } | null;
	};
};

export type PriceDisplayFragmentFragment = {
	centAmount: number;
	currency: Currency;
};

export type RemoveDiscountCodeMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id: CartIdentifierInput;
	discountCodeId: Scalars["ID"]["input"];
}>;

export type RemoveDiscountCodeMutation = {
	cartDiscountCodeRemove: {
		id: { id: string; version: number };
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			availableStock: number;
			price: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
				tax: {
					rate: number;
					value: { centAmount: number; currency: Currency };
				};
			};
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		discountCodes: Array<{
			id: string;
			code: string;
			amount: { centAmount: number; currency: Currency };
		}>;
	};
};

export type RemoveDiscountCodeButtonFragmentFragment = {
	id: { id: string; version: number };
};

export type StockIndicatorFragmentFragment = { availableStock: number };

export type CartFragment = {
	id: { id: string; version: number };
	subTotal: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		taxes: Array<{
			rate: number;
			value: { centAmount: number; currency: Currency };
		}>;
	};
	total: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		taxes: Array<{
			rate: number;
			value: { centAmount: number; currency: Currency };
		}>;
	};
	shippingCosts?: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		tax: { rate: number; value: { centAmount: number; currency: Currency } };
	} | null;
	lineItems: Array<{
		id: string;
		quantity: number;
		productSlug?: string | null;
		productName: string;
		addedAt?: string | null;
		availableStock: number;
		price: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		};
		variant: {
			name: string;
			sku: string;
			primaryImage?: { url: string; alt?: string | null } | null;
		};
	}>;
	discountCodes: Array<{
		id: string;
		code: string;
		amount: { centAmount: number; currency: Currency };
	}>;
};

export type GetCartQueryVariables = Exact<{
	storeContext: StoreContextInput;
}>;

export type GetCartQuery = {
	cart?: {
		id: { id: string; version: number };
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			availableStock: number;
			price: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
				tax: {
					rate: number;
					value: { centAmount: number; currency: Currency };
				};
			};
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		discountCodes: Array<{
			id: string;
			code: string;
			amount: { centAmount: number; currency: Currency };
		}>;
	} | null;
};

export type CartLineItemsRemoveMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id: CartIdentifierInput;
	lineItemIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CartLineItemsRemoveMutation = {
	cartLineItemsRemove: {
		id: { id: string; version: number };
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			availableStock: number;
			price: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
				tax: {
					rate: number;
					value: { centAmount: number; currency: Currency };
				};
			};
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		discountCodes: Array<{
			id: string;
			code: string;
			amount: { centAmount: number; currency: Currency };
		}>;
	};
};

export type CartLineItemsUpdateMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id: CartIdentifierInput;
	lineItems: Array<CartLineItemUpdateInput> | CartLineItemUpdateInput;
}>;

export type CartLineItemsUpdateMutation = {
	cartLineItemsUpdate: {
		id: { id: string; version: number };
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			availableStock: number;
			price: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
				tax: {
					rate: number;
					value: { centAmount: number; currency: Currency };
				};
			};
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		discountCodes: Array<{
			id: string;
			code: string;
			amount: { centAmount: number; currency: Currency };
		}>;
	};
};

type ColumnBlockFragment_Buttons_Fragment = {};

type ColumnBlockFragment_FaqItemList_Fragment = {};

type ColumnBlockFragment_RichText_Fragment = {
	__typename: "RichText";
	id: string;
	renderer?: RichTextRenderer | null;
	content: string;
};

type ColumnBlockFragment_UspList_Fragment = {};

export type ColumnBlockFragmentFragment =
	| ColumnBlockFragment_Buttons_Fragment
	| ColumnBlockFragment_FaqItemList_Fragment
	| ColumnBlockFragment_RichText_Fragment
	| ColumnBlockFragment_UspList_Fragment;

export type ThreeColumnFragmentFragment = {
	__typename: "ThreeColumns";
	id: string;
	column1: Array<
		| {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
		  }
		| {}
	>;
	column2: Array<
		| {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
		  }
		| {}
	>;
	column3: Array<
		| {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
		  }
		| {}
	>;
};

export type TwoColumnFragmentFragment = {
	__typename: "TwoColumns";
	id: string;
	ratio: TwoColumnsRatio;
	column1: Array<
		| {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
		  }
		| {}
	>;
	column2: Array<
		| {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
		  }
		| {}
	>;
};

export type ContentTeaserFragmentFragment = {
	__typename: "ContentTeaser";
	id: string;
	title: string;
	content: string;
	imagePosition: TeaserImagePosition;
	link: { url: string; newTab?: boolean | null; hash?: string | null };
	image: { filename: string; alt?: string | null };
};

export type FaqItemListFragmentFragment = {
	__typename: "FaqItemList";
	id: string;
	title: string;
	faqItems: Array<{
		__typename: "FaqItem";
		id: string;
		title: string;
		content: {
			__typename: "RichText";
			id: string;
			renderer?: RichTextRenderer | null;
			content: string;
		};
	}>;
};

export type RichTextFragmentFragment = {
	__typename: "RichText";
	id: string;
	renderer?: RichTextRenderer | null;
	content: string;
};

export type UspListFragmentFragment = {
	__typename: "UspList";
	id: string;
	usps: Array<{
		__typename: "Usp";
		id: string;
		title: string;
		link?: {
			url: string;
			newTab?: boolean | null;
			hash?: string | null;
		} | null;
	}>;
};

export type GetContentPageQueryVariables = Exact<{
	storeContext: StoreContextInput;
	path: Scalars["String"]["input"];
	previewID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetContentPageQuery = {
	contentPage?: {
		__typename: "ContentPage";
		id: string;
		name: string;
		translatedPaths?: Array<{ locale: string; path: string }> | null;
		breadcrumbs?: Array<{
			name: string;
			slug: string;
			type: BreadcrumbType;
		}> | null;
		seo?: {
			__typename: "ContentSEO";
			title?: string | null;
			description?: string | null;
			ogTitle?: string | null;
			ogDescription?: string | null;
			ogImage?: string | null;
			twitterTitle?: string | null;
			twitterDescription?: string | null;
			twitterImage?: string | null;
		} | null;
		hero?: {
			__typename: "Hero";
			id: string;
			title: string;
			imagePosition: ImagePosition;
			imageStyle: ImageStyle;
			bodyText?: {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
			} | null;
			buttons?: Array<{
				__typename: "Button";
				id: string;
				title: string;
				variant: ButtonVariant;
				link: { url: string; newTab?: boolean | null; hash?: string | null };
			}> | null;
			image: { filename: string; alt?: string | null };
		} | null;
		usps?: {
			id: string;
			usp1: string;
			usp2: string;
			usp3: string;
			usp4?: string | null;
		} | null;
		body?: Array<
			| {
					__typename: "ContentTeaser";
					id: string;
					title: string;
					content: string;
					imagePosition: TeaserImagePosition;
					link: { url: string; newTab?: boolean | null; hash?: string | null };
					image: { filename: string; alt?: string | null };
			  }
			| {
					__typename: "FaqItemList";
					id: string;
					title: string;
					faqItems: Array<{
						__typename: "FaqItem";
						id: string;
						title: string;
						content: {
							__typename: "RichText";
							id: string;
							renderer?: RichTextRenderer | null;
							content: string;
						};
					}>;
			  }
			| {
					__typename: "ProductRow";
					id: string;
					title: string;
					viewMoreLink?: { url: string; newTab?: boolean | null } | null;
					products: Array<{
						__typename: "Product";
						id: string;
						name: string;
						slug: string;
						ribbon?: { kind?: string | null; label?: string | null } | null;
						image?: { url: string; alt?: string | null } | null;
						startingPrice?: {
							gross: { centAmount: number; currency: Currency };
						} | null;
						primaryVariant: {
							id: string;
							sku?: string | null;
							availability?: ProductAvailability | null;
							seller?: string | null;
							price?: {
								net: { centAmount: number; currency: Currency };
								gross: { centAmount: number; currency: Currency };
							} | null;
						};
					}>;
			  }
			| {
					__typename: "RichText";
					id: string;
					renderer?: RichTextRenderer | null;
					content: string;
			  }
			| {
					__typename: "TeaserRow";
					id: string;
					title: string;
					viewMoreLink?: { url: string; newTab?: boolean | null } | null;
					items: Array<{
						__typename: "Teaser";
						id: string;
						content: string;
						title: string;
						image: { alt?: string | null; filename: string };
						link: { url: string };
					}>;
			  }
			| {
					__typename: "ThreeColumns";
					id: string;
					column1: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
					column2: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
					column3: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
			  }
			| {
					__typename: "TwoColumns";
					id: string;
					ratio: TwoColumnsRatio;
					column1: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
					column2: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
			  }
			| {
					__typename: "UspList";
					id: string;
					usps: Array<{
						__typename: "Usp";
						id: string;
						title: string;
						link?: {
							url: string;
							newTab?: boolean | null;
							hash?: string | null;
						} | null;
					}>;
			  }
			| {}
			| null
		> | null;
	} | null;
};

export type ContentPageFragmentFragment = {
	__typename: "ContentPage";
	id: string;
	name: string;
	translatedPaths?: Array<{ locale: string; path: string }> | null;
	breadcrumbs?: Array<{
		name: string;
		slug: string;
		type: BreadcrumbType;
	}> | null;
	seo?: {
		__typename: "ContentSEO";
		title?: string | null;
		description?: string | null;
		ogTitle?: string | null;
		ogDescription?: string | null;
		ogImage?: string | null;
		twitterTitle?: string | null;
		twitterDescription?: string | null;
		twitterImage?: string | null;
	} | null;
	hero?: {
		__typename: "Hero";
		id: string;
		title: string;
		imagePosition: ImagePosition;
		imageStyle: ImageStyle;
		bodyText?: {
			__typename: "RichText";
			id: string;
			renderer?: RichTextRenderer | null;
			content: string;
		} | null;
		buttons?: Array<{
			__typename: "Button";
			id: string;
			title: string;
			variant: ButtonVariant;
			link: { url: string; newTab?: boolean | null; hash?: string | null };
		}> | null;
		image: { filename: string; alt?: string | null };
	} | null;
	usps?: {
		id: string;
		usp1: string;
		usp2: string;
		usp3: string;
		usp4?: string | null;
	} | null;
	body?: Array<
		| {
				__typename: "ContentTeaser";
				id: string;
				title: string;
				content: string;
				imagePosition: TeaserImagePosition;
				link: { url: string; newTab?: boolean | null; hash?: string | null };
				image: { filename: string; alt?: string | null };
		  }
		| {
				__typename: "FaqItemList";
				id: string;
				title: string;
				faqItems: Array<{
					__typename: "FaqItem";
					id: string;
					title: string;
					content: {
						__typename: "RichText";
						id: string;
						renderer?: RichTextRenderer | null;
						content: string;
					};
				}>;
		  }
		| {
				__typename: "ProductRow";
				id: string;
				title: string;
				viewMoreLink?: { url: string; newTab?: boolean | null } | null;
				products: Array<{
					__typename: "Product";
					id: string;
					name: string;
					slug: string;
					ribbon?: { kind?: string | null; label?: string | null } | null;
					image?: { url: string; alt?: string | null } | null;
					startingPrice?: {
						gross: { centAmount: number; currency: Currency };
					} | null;
					primaryVariant: {
						id: string;
						sku?: string | null;
						availability?: ProductAvailability | null;
						seller?: string | null;
						price?: {
							net: { centAmount: number; currency: Currency };
							gross: { centAmount: number; currency: Currency };
						} | null;
					};
				}>;
		  }
		| {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
		  }
		| {
				__typename: "TeaserRow";
				id: string;
				title: string;
				viewMoreLink?: { url: string; newTab?: boolean | null } | null;
				items: Array<{
					__typename: "Teaser";
					id: string;
					content: string;
					title: string;
					image: { alt?: string | null; filename: string };
					link: { url: string };
				}>;
		  }
		| {
				__typename: "ThreeColumns";
				id: string;
				column1: Array<
					| {
							__typename: "RichText";
							id: string;
							renderer?: RichTextRenderer | null;
							content: string;
					  }
					| {}
				>;
				column2: Array<
					| {
							__typename: "RichText";
							id: string;
							renderer?: RichTextRenderer | null;
							content: string;
					  }
					| {}
				>;
				column3: Array<
					| {
							__typename: "RichText";
							id: string;
							renderer?: RichTextRenderer | null;
							content: string;
					  }
					| {}
				>;
		  }
		| {
				__typename: "TwoColumns";
				id: string;
				ratio: TwoColumnsRatio;
				column1: Array<
					| {
							__typename: "RichText";
							id: string;
							renderer?: RichTextRenderer | null;
							content: string;
					  }
					| {}
				>;
				column2: Array<
					| {
							__typename: "RichText";
							id: string;
							renderer?: RichTextRenderer | null;
							content: string;
					  }
					| {}
				>;
		  }
		| {
				__typename: "UspList";
				id: string;
				usps: Array<{
					__typename: "Usp";
					id: string;
					title: string;
					link?: {
						url: string;
						newTab?: boolean | null;
						hash?: string | null;
					} | null;
				}>;
		  }
		| {}
		| null
	> | null;
};

export type ProductBreadcrumbFragmentFragment = {
	name: string;
	slug: string;
	primaryCategory?: {
		name: string;
		path: string;
		ancestors: Array<{ name: string; path: string }>;
	} | null;
};

type ButtonSelectorGroupFieldFragment_ProductVariantDimensionColorOption_Fragment =
	{
		value: string;
		label: string;
		selected: boolean;
		product?: { slug: string } | null;
		variant?: { sku?: string | null } | null;
	};

type ButtonSelectorGroupFieldFragment_ProductVariantDimensionImageOption_Fragment =
	{
		value: string;
		label: string;
		selected: boolean;
		product?: { slug: string } | null;
		variant?: { sku?: string | null } | null;
	};

type ButtonSelectorGroupFieldFragment_ProductVariantDimensionTextOption_Fragment =
	{
		value: string;
		label: string;
		selected: boolean;
		product?: { slug: string } | null;
		variant?: { sku?: string | null } | null;
	};

export type ButtonSelectorGroupFieldFragmentFragment =
	| ButtonSelectorGroupFieldFragment_ProductVariantDimensionColorOption_Fragment
	| ButtonSelectorGroupFieldFragment_ProductVariantDimensionImageOption_Fragment
	| ButtonSelectorGroupFieldFragment_ProductVariantDimensionTextOption_Fragment;

export type ColorSelectorGroupFieldFragmentFragment = {
	__typename: "ProductVariantDimensionColorOption";
	value: string;
	label: string;
	selected: boolean;
	color: string;
	product?: { slug: string } | null;
	variant?: { sku?: string | null } | null;
};

type ImageSelectorGroupFieldFragment_ProductVariantDimensionColorOption_Fragment =
	{
		__typename: "ProductVariantDimensionColorOption";
		value: string;
		label: string;
		selected: boolean;
		product?: { slug: string } | null;
		variant?: {
			sku?: string | null;
			primaryImage?: { url: string; alt?: string | null } | null;
		} | null;
	};

type ImageSelectorGroupFieldFragment_ProductVariantDimensionImageOption_Fragment =
	{
		__typename: "ProductVariantDimensionImageOption";
		value: string;
		label: string;
		selected: boolean;
		product?: { slug: string } | null;
		variant?: {
			sku?: string | null;
			primaryImage?: { url: string; alt?: string | null } | null;
		} | null;
	};

type ImageSelectorGroupFieldFragment_ProductVariantDimensionTextOption_Fragment =
	{
		__typename: "ProductVariantDimensionTextOption";
		value: string;
		label: string;
		selected: boolean;
		product?: { slug: string } | null;
		variant?: {
			sku?: string | null;
			primaryImage?: { url: string; alt?: string | null } | null;
		} | null;
	};

export type ImageSelectorGroupFieldFragmentFragment =
	| ImageSelectorGroupFieldFragment_ProductVariantDimensionColorOption_Fragment
	| ImageSelectorGroupFieldFragment_ProductVariantDimensionImageOption_Fragment
	| ImageSelectorGroupFieldFragment_ProductVariantDimensionTextOption_Fragment;

export type ProductFormFragmentFragment = {
	name: string;
	label: string;
	options: Array<
		| {
				__typename: "ProductVariantDimensionColorOption";
				value: string;
				label: string;
				selected: boolean;
				color: string;
				product?: { slug: string } | null;
				variant?: {
					sku?: string | null;
					primaryImage?: { url: string; alt?: string | null } | null;
				} | null;
		  }
		| {
				__typename: "ProductVariantDimensionImageOption";
				value: string;
				label: string;
				selected: boolean;
				product?: { slug: string } | null;
				variant?: {
					sku?: string | null;
					primaryImage?: { url: string; alt?: string | null } | null;
				} | null;
		  }
		| {
				__typename: "ProductVariantDimensionTextOption";
				value: string;
				label: string;
				selected: boolean;
				product?: { slug: string } | null;
				variant?: {
					sku?: string | null;
					primaryImage?: { url: string; alt?: string | null } | null;
				} | null;
		  }
	>;
};

export type ProductGalleryImageFragmentFragment = {
	url: string;
	alt?: string | null;
};

export type GetProductDetailPageQueryVariables = Exact<{
	storeContext: StoreContextInput;
	slug: Scalars["String"]["input"];
	selectedSku?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetProductDetailPageQuery = {
	product?: {
		name: string;
		slug: string;
		brand?: string | null;
		localizedSlugs: Array<{ locale: string; slug: string }>;
		meta?: { title?: string | null; description?: string | null } | null;
		usps?: {
			id: string;
			usp1: string;
			usp2: string;
			usp3: string;
			usp4?: string | null;
		} | null;
		ribbon?: { kind?: string | null; label?: string | null } | null;
		primaryVariant: { images?: Array<{ url: string }> | null };
		primaryCategory?: {
			name: string;
			slug: string;
			path: string;
			ancestors: Array<{ name: string; path: string }>;
		} | null;
		variant: {
			id: string;
			name?: string | null;
			sku?: string | null;
			availability?: ProductAvailability | null;
			deliveryTime?: string | null;
			description?: string | null;
			seller?: string | null;
			price?: {
				gross: { centAmount: number; currency: Currency };
				net: { centAmount: number; currency: Currency };
			} | null;
			images?: Array<{ url: string; alt?: string | null }> | null;
			specifications?: Array<{
				name: string;
				label: string;
				attributes: Array<
					| {
							__typename: "ProductSpecificationBooleanAttribute";
							label: string;
							name: string;
							description?: string | null;
							boolValue: boolean;
					  }
					| {
							__typename: "ProductSpecificationDateTimeAttribute";
							label: string;
							name: string;
							description?: string | null;
							dateTimeValue: string;
					  }
					| {
							__typename: "ProductSpecificationEnumAttribute";
							label: string;
							name: string;
							description?: string | null;
							enumValue: { label: string; value: string };
					  }
					| {
							__typename: "ProductSpecificationEnumListAttribute";
							label: string;
							name: string;
							description?: string | null;
							enumListValue: Array<{ label: string; value: string } | null>;
					  }
					| {
							__typename: "ProductSpecificationNumberAttribute";
							label: string;
							name: string;
							description?: string | null;
							numberValue: number;
					  }
					| {
							__typename: "ProductSpecificationStringAttribute";
							label: string;
							name: string;
							description?: string | null;
							stringValue: string;
					  }
				>;
			}> | null;
		};
		variantDimensions?: Array<{
			name: string;
			label: string;
			options: Array<
				| {
						__typename: "ProductVariantDimensionColorOption";
						value: string;
						label: string;
						selected: boolean;
						color: string;
						product?: { slug: string } | null;
						variant?: {
							sku?: string | null;
							primaryImage?: { url: string; alt?: string | null } | null;
						} | null;
				  }
				| {
						__typename: "ProductVariantDimensionImageOption";
						value: string;
						label: string;
						selected: boolean;
						product?: { slug: string } | null;
						variant?: {
							sku?: string | null;
							primaryImage?: { url: string; alt?: string | null } | null;
						} | null;
				  }
				| {
						__typename: "ProductVariantDimensionTextOption";
						value: string;
						label: string;
						selected: boolean;
						product?: { slug: string } | null;
						variant?: {
							sku?: string | null;
							primaryImage?: { url: string; alt?: string | null } | null;
						} | null;
				  }
			>;
		} | null> | null;
		reviews?: { amount: number; averageRating: number } | null;
	} | null;
};

export type ProductDetailPageFragmentFragment = {
	name: string;
	slug: string;
	brand?: string | null;
	meta?: { title?: string | null; description?: string | null } | null;
	usps?: {
		id: string;
		usp1: string;
		usp2: string;
		usp3: string;
		usp4?: string | null;
	} | null;
	ribbon?: { kind?: string | null; label?: string | null } | null;
	primaryVariant: { images?: Array<{ url: string }> | null };
	primaryCategory?: {
		name: string;
		slug: string;
		path: string;
		ancestors: Array<{ name: string; path: string }>;
	} | null;
	variant: {
		id: string;
		name?: string | null;
		sku?: string | null;
		availability?: ProductAvailability | null;
		deliveryTime?: string | null;
		description?: string | null;
		seller?: string | null;
		price?: {
			gross: { centAmount: number; currency: Currency };
			net: { centAmount: number; currency: Currency };
		} | null;
		images?: Array<{ url: string; alt?: string | null }> | null;
		specifications?: Array<{
			name: string;
			label: string;
			attributes: Array<
				| {
						__typename: "ProductSpecificationBooleanAttribute";
						label: string;
						name: string;
						description?: string | null;
						boolValue: boolean;
				  }
				| {
						__typename: "ProductSpecificationDateTimeAttribute";
						label: string;
						name: string;
						description?: string | null;
						dateTimeValue: string;
				  }
				| {
						__typename: "ProductSpecificationEnumAttribute";
						label: string;
						name: string;
						description?: string | null;
						enumValue: { label: string; value: string };
				  }
				| {
						__typename: "ProductSpecificationEnumListAttribute";
						label: string;
						name: string;
						description?: string | null;
						enumListValue: Array<{ label: string; value: string } | null>;
				  }
				| {
						__typename: "ProductSpecificationNumberAttribute";
						label: string;
						name: string;
						description?: string | null;
						numberValue: number;
				  }
				| {
						__typename: "ProductSpecificationStringAttribute";
						label: string;
						name: string;
						description?: string | null;
						stringValue: string;
				  }
			>;
		}> | null;
	};
	variantDimensions?: Array<{
		name: string;
		label: string;
		options: Array<
			| {
					__typename: "ProductVariantDimensionColorOption";
					value: string;
					label: string;
					selected: boolean;
					color: string;
					product?: { slug: string } | null;
					variant?: {
						sku?: string | null;
						primaryImage?: { url: string; alt?: string | null } | null;
					} | null;
			  }
			| {
					__typename: "ProductVariantDimensionImageOption";
					value: string;
					label: string;
					selected: boolean;
					product?: { slug: string } | null;
					variant?: {
						sku?: string | null;
						primaryImage?: { url: string; alt?: string | null } | null;
					} | null;
			  }
			| {
					__typename: "ProductVariantDimensionTextOption";
					value: string;
					label: string;
					selected: boolean;
					product?: { slug: string } | null;
					variant?: {
						sku?: string | null;
						primaryImage?: { url: string; alt?: string | null } | null;
					} | null;
			  }
		>;
	} | null> | null;
	reviews?: { amount: number; averageRating: number } | null;
};

type SpecificationAttributeFragment_ProductSpecificationBooleanAttribute_Fragment =
	{
		__typename: "ProductSpecificationBooleanAttribute";
		label: string;
		name: string;
		description?: string | null;
		boolValue: boolean;
	};

type SpecificationAttributeFragment_ProductSpecificationDateTimeAttribute_Fragment =
	{
		__typename: "ProductSpecificationDateTimeAttribute";
		label: string;
		name: string;
		description?: string | null;
		dateTimeValue: string;
	};

type SpecificationAttributeFragment_ProductSpecificationEnumAttribute_Fragment =
	{
		__typename: "ProductSpecificationEnumAttribute";
		label: string;
		name: string;
		description?: string | null;
		enumValue: { label: string; value: string };
	};

type SpecificationAttributeFragment_ProductSpecificationEnumListAttribute_Fragment =
	{
		__typename: "ProductSpecificationEnumListAttribute";
		label: string;
		name: string;
		description?: string | null;
		enumListValue: Array<{ label: string; value: string } | null>;
	};

type SpecificationAttributeFragment_ProductSpecificationNumberAttribute_Fragment =
	{
		__typename: "ProductSpecificationNumberAttribute";
		label: string;
		name: string;
		description?: string | null;
		numberValue: number;
	};

type SpecificationAttributeFragment_ProductSpecificationStringAttribute_Fragment =
	{
		__typename: "ProductSpecificationStringAttribute";
		label: string;
		name: string;
		description?: string | null;
		stringValue: string;
	};

export type SpecificationAttributeFragmentFragment =
	| SpecificationAttributeFragment_ProductSpecificationBooleanAttribute_Fragment
	| SpecificationAttributeFragment_ProductSpecificationDateTimeAttribute_Fragment
	| SpecificationAttributeFragment_ProductSpecificationEnumAttribute_Fragment
	| SpecificationAttributeFragment_ProductSpecificationEnumListAttribute_Fragment
	| SpecificationAttributeFragment_ProductSpecificationNumberAttribute_Fragment
	| SpecificationAttributeFragment_ProductSpecificationStringAttribute_Fragment;

export type ProductVariantFragmentFragment = {
	id: string;
	name?: string | null;
	sku?: string | null;
	availability?: ProductAvailability | null;
	deliveryTime?: string | null;
	description?: string | null;
	seller?: string | null;
	images?: Array<{ url: string; alt?: string | null }> | null;
	specifications?: Array<{
		name: string;
		label: string;
		attributes: Array<
			| {
					__typename: "ProductSpecificationBooleanAttribute";
					label: string;
					name: string;
					description?: string | null;
					boolValue: boolean;
			  }
			| {
					__typename: "ProductSpecificationDateTimeAttribute";
					label: string;
					name: string;
					description?: string | null;
					dateTimeValue: string;
			  }
			| {
					__typename: "ProductSpecificationEnumAttribute";
					label: string;
					name: string;
					description?: string | null;
					enumValue: { label: string; value: string };
			  }
			| {
					__typename: "ProductSpecificationEnumListAttribute";
					label: string;
					name: string;
					description?: string | null;
					enumListValue: Array<{ label: string; value: string } | null>;
			  }
			| {
					__typename: "ProductSpecificationNumberAttribute";
					label: string;
					name: string;
					description?: string | null;
					numberValue: number;
			  }
			| {
					__typename: "ProductSpecificationStringAttribute";
					label: string;
					name: string;
					description?: string | null;
					stringValue: string;
			  }
		>;
	}> | null;
};

export type GetHomePageQueryVariables = Exact<{
	storeContext: StoreContextInput;
	version: ContentVersion;
	previewID?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetHomePageQuery = {
	contentPage?: {
		__typename: "ContentPage";
		id: string;
		name: string;
		seo?: {
			__typename: "ContentSEO";
			title?: string | null;
			description?: string | null;
			ogTitle?: string | null;
			ogDescription?: string | null;
			ogImage?: string | null;
			twitterTitle?: string | null;
			twitterDescription?: string | null;
			twitterImage?: string | null;
		} | null;
		hero?: {
			__typename: "Hero";
			id: string;
			title: string;
			imagePosition: ImagePosition;
			imageStyle: ImageStyle;
			bodyText?: {
				__typename: "RichText";
				id: string;
				renderer?: RichTextRenderer | null;
				content: string;
			} | null;
			buttons?: Array<{
				__typename: "Button";
				id: string;
				title: string;
				variant: ButtonVariant;
				link: { url: string; newTab?: boolean | null; hash?: string | null };
			}> | null;
			image: { filename: string; alt?: string | null };
		} | null;
		usps?: {
			id: string;
			usp1: string;
			usp2: string;
			usp3: string;
			usp4?: string | null;
		} | null;
		body?: Array<
			| {
					__typename: "RichText";
					id: string;
					renderer?: RichTextRenderer | null;
					content: string;
			  }
			| {
					__typename: "TeaserRow";
					id: string;
					title: string;
					viewMoreLink?: { url: string; newTab?: boolean | null } | null;
					items: Array<{
						__typename: "Teaser";
						id: string;
						content: string;
						title: string;
						image: { alt?: string | null; filename: string };
						link: { url: string };
					}>;
			  }
			| {
					__typename: "ThreeColumns";
					id: string;
					column1: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
					column2: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
					column3: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
			  }
			| {
					__typename: "TwoColumns";
					id: string;
					ratio: TwoColumnsRatio;
					column1: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
					column2: Array<
						| {
								__typename: "RichText";
								id: string;
								renderer?: RichTextRenderer | null;
								content: string;
						  }
						| {}
					>;
			  }
			| {}
			| null
		> | null;
	} | null;
};

export type GetContentSlugsQueryVariables = Exact<{
	storeContext: StoreContextInput;
	pageSize: Scalars["Int"]["input"];
	page: Scalars["Int"]["input"];
	version?: InputMaybe<ContentVersion>;
}>;

export type GetContentSlugsQuery = {
	pages: {
		total: number;
		results: Array<
			| { __typename: "ContentPage"; name: string; path: string }
			| { __typename: "ProductListingPage"; name: string; path: string }
		>;
	};
};

export type GetProductSlugsQueryVariables = Exact<{
	storeContext: StoreContextInput;
	pageSize: Scalars["Int"]["input"];
	page: Scalars["Int"]["input"];
}>;

export type GetProductSlugsQuery = {
	products: { total: number; results: Array<{ slug: string }> };
};

export type ContextLineItemFragmentFragment = {
	id: string;
	quantity: number;
	productSlug?: string | null;
	productName: string;
	addedAt?: string | null;
	price: {
		net: { centAmount: number; currency: Currency };
		gross: { centAmount: number; currency: Currency };
		tax: { rate: number; value: { centAmount: number; currency: Currency } };
	};
	variant: {
		name: string;
		sku: string;
		primaryImage?: { url: string; alt?: string | null } | null;
	};
};

export type BreadcrumbFragmentFragment = {
	name: string;
	slug: string;
	type: BreadcrumbType;
};

export type CrossSellProductCardFragmentFragment = {
	name: string;
	slug: string;
	ribbon?: { kind?: string | null; label?: string | null } | null;
	image?: { url: string; alt?: string | null } | null;
	primaryVariant: {
		id: string;
		sku?: string | null;
		availability?: ProductAvailability | null;
		price?: { gross: { centAmount: number; currency: Currency } } | null;
	};
};

export type FaqItemFragmentFragment = {
	__typename: "FaqItem";
	id: string;
	title: string;
	content: {
		__typename: "RichText";
		id: string;
		renderer?: RichTextRenderer | null;
		content: string;
	};
};

export type HeroFragmentFragment = {
	__typename: "Hero";
	id: string;
	title: string;
	imagePosition: ImagePosition;
	imageStyle: ImageStyle;
	bodyText?: {
		__typename: "RichText";
		id: string;
		renderer?: RichTextRenderer | null;
		content: string;
	} | null;
	buttons?: Array<{
		__typename: "Button";
		id: string;
		title: string;
		variant: ButtonVariant;
		link: { url: string; newTab?: boolean | null; hash?: string | null };
	}> | null;
	image: { filename: string; alt?: string | null };
};

export type PriceFragmentFragment = { centAmount: number; currency: Currency };

export type ProductCardFragment = {
	name: string;
	slug: string;
	ribbon?: { kind?: string | null; label?: string | null } | null;
	image?: { url: string; alt?: string | null } | null;
	startingPrice?: { gross: { centAmount: number; currency: Currency } } | null;
	primaryVariant: {
		id: string;
		sku?: string | null;
		availability?: ProductAvailability | null;
		seller?: string | null;
		price?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
		} | null;
	};
};

export type ProductRowFragmentFragment = {
	__typename: "ProductRow";
	id: string;
	title: string;
	viewMoreLink?: { url: string; newTab?: boolean | null } | null;
	products: Array<{
		__typename: "Product";
		id: string;
		name: string;
		slug: string;
		ribbon?: { kind?: string | null; label?: string | null } | null;
		image?: { url: string; alt?: string | null } | null;
		startingPrice?: {
			gross: { centAmount: number; currency: Currency };
		} | null;
		primaryVariant: {
			id: string;
			sku?: string | null;
			availability?: ProductAvailability | null;
			seller?: string | null;
			price?: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
			} | null;
		};
	}>;
};

export type TeaserRowFragmentFragment = {
	__typename: "TeaserRow";
	id: string;
	title: string;
	viewMoreLink?: { url: string; newTab?: boolean | null } | null;
	items: Array<{
		__typename: "Teaser";
		id: string;
		content: string;
		title: string;
		image: { alt?: string | null; filename: string };
		link: { url: string };
	}>;
};

export type UspsFragmentFragment = {
	id: string;
	usp1: string;
	usp2: string;
	usp3: string;
	usp4?: string | null;
};

export type UspFragmentFragment = {
	__typename: "Usp";
	id: string;
	title: string;
	link?: { url: string; newTab?: boolean | null; hash?: string | null } | null;
};

export type CartLineItemsAddMutationVariables = Exact<{
	storeContext: StoreContextInput;
	id?: InputMaybe<CartIdentifierInput>;
	lineItems: Array<CartLineItemCreateInput> | CartLineItemCreateInput;
}>;

export type CartLineItemsAddMutation = {
	cartLineItemsAdd: {
		id: { id: string; version: number };
		subTotal: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		total: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			taxes: Array<{
				rate: number;
				value: { centAmount: number; currency: Currency };
			}>;
		};
		shippingCosts?: {
			net: { centAmount: number; currency: Currency };
			gross: { centAmount: number; currency: Currency };
			tax: { rate: number; value: { centAmount: number; currency: Currency } };
		} | null;
		lineItems: Array<{
			id: string;
			quantity: number;
			productSlug?: string | null;
			productName: string;
			addedAt?: string | null;
			availableStock: number;
			price: {
				net: { centAmount: number; currency: Currency };
				gross: { centAmount: number; currency: Currency };
				tax: {
					rate: number;
					value: { centAmount: number; currency: Currency };
				};
			};
			variant: {
				name: string;
				sku: string;
				primaryImage?: { url: string; alt?: string | null } | null;
			};
		}>;
		discountCodes: Array<{
			id: string;
			code: string;
			amount: { centAmount: number; currency: Currency };
		}>;
	};
};

export type AddressValidationResultFragment = {
	valid: boolean;
	suggestions?: Array<{
		city?: string | null;
		country?: string | null;
		streetNumber?: string | null;
		streetNumberSuffix?: string | null;
		postalCode?: string | null;
		streetName?: string | null;
	} | null> | null;
};

export type GetAddressValidateQueryVariables = Exact<{
	storeContext: StoreContextInput;
	address: AddressInput;
}>;

export type GetAddressValidateQuery = {
	addressValidate?: {
		valid: boolean;
		suggestions?: Array<{
			city?: string | null;
			country?: string | null;
			streetNumber?: string | null;
			streetNumberSuffix?: string | null;
			postalCode?: string | null;
			streetName?: string | null;
		} | null> | null;
	} | null;
};

export class TypedDocumentString<TResult, TVariables>
	extends String
	implements DocumentTypeDecoration<TResult, TVariables>
{
	__apiType?: DocumentTypeDecoration<TResult, TVariables>["__apiType"];

	constructor(
		private value: string,
		public __meta__?: Record<string, any>,
	) {
		super(value);
	}

	toString(): string & DocumentTypeDecoration<TResult, TVariables> {
		return this.value;
	}
}
export const ApplePayPaymentMethodFragmentDoc = new TypedDocumentString(
	`
    fragment ApplePayPaymentMethod on PaymentMethod {
  id
  name
  provider
  extra {
    ... on ApplePayExtra {
      type
      brands
      configuration {
        merchantId
        merchantName
      }
    }
  }
}
    `,
	{ fragmentName: "ApplePayPaymentMethod" },
) as unknown as TypedDocumentString<ApplePayPaymentMethodFragment, unknown>;
export const PriceFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment PriceFragment on Money {
  centAmount
  currency
}
    `,
	{ fragmentName: "PriceFragment" },
) as unknown as TypedDocumentString<PriceFragmentFragment, unknown>;
export const CheckoutCartFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CheckoutCartFragment on Cart {
  id {
    version
    id
  }
  availablePaymentMethods(device: web) {
    name
    paymentMethod {
      ...ApplePayPaymentMethod
    }
  }
  billingAddress {
    id
  }
  customerEmail
  lineItems {
    id
    productName
    quantity
    price {
      gross {
        ...PriceFragment
      }
    }
  }
  total {
    gross {
      ...PriceFragment
    }
  }
}
    fragment ApplePayPaymentMethod on PaymentMethod {
  id
  name
  provider
  extra {
    ... on ApplePayExtra {
      type
      brands
      configuration {
        merchantId
        merchantName
      }
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "CheckoutCartFragment" },
) as unknown as TypedDocumentString<CheckoutCartFragmentFragment, unknown>;
export const CheckoutSummaryFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CheckoutSummaryFragment on Cart {
  id {
    id
    version
  }
  lineItems {
    id
    quantity
    productSlug
    productName
    addedAt
    variant {
      name
      sku
      primaryImage {
        url
        alt
      }
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "CheckoutSummaryFragment" },
) as unknown as TypedDocumentString<CheckoutSummaryFragmentFragment, unknown>;
export const BillingAddressFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment BillingAddressFragment on Address {
  id
  email
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
    `,
	{ fragmentName: "BillingAddressFragment" },
) as unknown as TypedDocumentString<BillingAddressFragmentFragment, unknown>;
export const PaymentMethodFragmentDoc = new TypedDocumentString(
	`
    fragment PaymentMethod on PaymentMethod {
  id
  name
  provider
  issuers {
    id
    name
  }
}
    `,
	{ fragmentName: "PaymentMethod" },
) as unknown as TypedDocumentString<PaymentMethodFragment, unknown>;
export const ProductCardFragmentDoc = new TypedDocumentString(
	`
    fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "ProductCard" },
) as unknown as TypedDocumentString<ProductCardFragment, unknown>;
export const OptionsFacetFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment OptionsFacetFragment on OptionsFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
    `,
	{ fragmentName: "OptionsFacetFragment" },
) as unknown as TypedDocumentString<OptionsFacetFragmentFragment, unknown>;
export const RangeFacetFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment RangeFacetFragment on RangeFacet {
  __typename
  key
  label
  min
  max
  selectedMin
  selectedMax
}
    `,
	{ fragmentName: "RangeFacetFragment" },
) as unknown as TypedDocumentString<RangeFacetFragmentFragment, unknown>;
export const ToggleFacetFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ToggleFacetFragment on ToggleFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
    `,
	{ fragmentName: "ToggleFacetFragment" },
) as unknown as TypedDocumentString<ToggleFacetFragmentFragment, unknown>;
export const FacetsFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment FacetsFragment on Facet {
  ...OptionsFacetFragment
  ...RangeFacetFragment
  ...ToggleFacetFragment
}
    fragment OptionsFacetFragment on OptionsFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment RangeFacetFragment on RangeFacet {
  __typename
  key
  label
  min
  max
  selectedMin
  selectedMax
}
fragment ToggleFacetFragment on ToggleFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}`,
	{ fragmentName: "FacetsFragment" },
) as unknown as TypedDocumentString<FacetsFragmentFragment, unknown>;
export const ProductsResultFragmentDoc = new TypedDocumentString(
	`
    fragment ProductsResult on ProductsResult {
  total
  results {
    ...ProductCard
  }
  facets {
    ...FacetsFragment
  }
}
    fragment FacetsFragment on Facet {
  ...OptionsFacetFragment
  ...RangeFacetFragment
  ...ToggleFacetFragment
}
fragment OptionsFacetFragment on OptionsFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment RangeFacetFragment on RangeFacet {
  __typename
  key
  label
  min
  max
  selectedMin
  selectedMax
}
fragment ToggleFacetFragment on ToggleFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}`,
	{ fragmentName: "ProductsResult" },
) as unknown as TypedDocumentString<ProductsResultFragment, unknown>;
export const BreadcrumbFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}
    `,
	{ fragmentName: "BreadcrumbFragment" },
) as unknown as TypedDocumentString<BreadcrumbFragmentFragment, unknown>;
export const ProductListingConfigFragmentDoc = new TypedDocumentString(
	`
    fragment ProductListingConfig on ProductListingConfig {
  categoryId
  defaultOrder
  prefilters {
    key
    value
  }
}
    `,
	{ fragmentName: "ProductListingConfig" },
) as unknown as TypedDocumentString<ProductListingConfigFragment, unknown>;
export const CategoryPageFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CategoryPageFragment on CategoryPage {
  meta {
    title
    description
    keywords
  }
  translatedPaths {
    locale
    path
  }
  breadcrumbs {
    ...BreadcrumbFragment
  }
  category {
    id
    name
    description
  }
  productListingConfig {
    ...ProductListingConfig
  }
}
    fragment ProductListingConfig on ProductListingConfig {
  categoryId
  defaultOrder
  prefilters {
    key
    value
  }
}
fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}`,
	{ fragmentName: "CategoryPageFragment" },
) as unknown as TypedDocumentString<CategoryPageFragmentFragment, unknown>;
export const RichTextFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
    `,
	{ fragmentName: "RichTextFragment" },
) as unknown as TypedDocumentString<RichTextFragmentFragment, unknown>;
export const HeroFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment HeroFragment on Hero {
  __typename
  id
  title
  imagePosition
  imageStyle
  bodyText {
    ...RichTextFragment
  }
  buttons {
    __typename
    id
    title
    variant
    link {
      url
      newTab
      hash
    }
  }
  image {
    filename
    alt
  }
}
    fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}`,
	{ fragmentName: "HeroFragment" },
) as unknown as TypedDocumentString<HeroFragmentFragment, unknown>;
export const UspsFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment UspsFragment on Usps {
  id
  usp1
  usp2
  usp3
  usp4
}
    `,
	{ fragmentName: "UspsFragment" },
) as unknown as TypedDocumentString<UspsFragmentFragment, unknown>;
export const TeaserRowFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment TeaserRowFragment on TeaserRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  items {
    __typename
    id
    content
    image {
      alt
      filename
    }
    link {
      url
    }
    title
  }
}
    `,
	{ fragmentName: "TeaserRowFragment" },
) as unknown as TypedDocumentString<TeaserRowFragmentFragment, unknown>;
export const ProductListingPageFragmentDoc = new TypedDocumentString(
	`
    fragment ProductListingPage on ProductListingPage {
  __typename
  id
  name
  translatedPaths {
    locale
    path
  }
  seo {
    __typename
    title
    description
    ogTitle
    ogDescription
    ogImage
    twitterTitle
    twitterDescription
    twitterImage
  }
  breadcrumbs {
    ...BreadcrumbFragment
  }
  hero {
    ...HeroFragment
  }
  usps {
    ...UspsFragment
  }
  productListingConfig {
    ...ProductListingConfig
  }
  teaserRow {
    ...TeaserRowFragment
  }
}
    fragment ProductListingConfig on ProductListingConfig {
  categoryId
  defaultOrder
  prefilters {
    key
    value
  }
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}
fragment HeroFragment on Hero {
  __typename
  id
  title
  imagePosition
  imageStyle
  bodyText {
    ...RichTextFragment
  }
  buttons {
    __typename
    id
    title
    variant
    link {
      url
      newTab
      hash
    }
  }
  image {
    filename
    alt
  }
}
fragment TeaserRowFragment on TeaserRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  items {
    __typename
    id
    content
    image {
      alt
      filename
    }
    link {
      url
    }
    title
  }
}
fragment UspsFragment on Usps {
  id
  usp1
  usp2
  usp3
  usp4
}`,
	{ fragmentName: "ProductListingPage" },
) as unknown as TypedDocumentString<ProductListingPageFragment, unknown>;
export const HeaderInfoPagesFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment HeaderInfoPagesFragment on Site {
  infoPages {
    business {
      url
      name
    }
    service {
      url
      name
    }
  }
}
    `,
	{ fragmentName: "HeaderInfoPagesFragment" },
) as unknown as TypedDocumentString<HeaderInfoPagesFragmentFragment, unknown>;
export const MenuItemFieldsFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}
    `,
	{ fragmentName: "MenuItemFieldsFragment" },
) as unknown as TypedDocumentString<MenuItemFieldsFragmentFragment, unknown>;
export const SubMenuItemFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
    fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}`,
	{ fragmentName: "SubMenuItemFragment" },
) as unknown as TypedDocumentString<SubMenuItemFragmentFragment, unknown>;
export const TopMenuItemFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment TopMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...SubMenuItemFragment
  }
}
    fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}`,
	{ fragmentName: "TopMenuItemFragment" },
) as unknown as TypedDocumentString<TopMenuItemFragmentFragment, unknown>;
export const MainMenuFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment MainMenuFragment on Site {
  mainMenu {
    ...TopMenuItemFragment
  }
}
    fragment TopMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...SubMenuItemFragment
  }
}
fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}`,
	{ fragmentName: "MainMenuFragment" },
) as unknown as TypedDocumentString<MainMenuFragmentFragment, unknown>;
export const HeaderFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment HeaderFragment on Site {
  logo {
    alt
    filename
  }
  ...HeaderInfoPagesFragment
  ...MainMenuFragment
}
    fragment MainMenuFragment on Site {
  mainMenu {
    ...TopMenuItemFragment
  }
}
fragment TopMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...SubMenuItemFragment
  }
}
fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}
fragment HeaderInfoPagesFragment on Site {
  infoPages {
    business {
      url
      name
    }
    service {
      url
      name
    }
  }
}`,
	{ fragmentName: "HeaderFragment" },
) as unknown as TypedDocumentString<HeaderFragmentFragment, unknown>;
export const PaymentFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment PaymentFragment on Site {
  paymentMethods {
    filename
    alt
  }
}
    `,
	{ fragmentName: "PaymentFragment" },
) as unknown as TypedDocumentString<PaymentFragmentFragment, unknown>;
export const FooterInfoPagesFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment FooterInfoPagesFragment on Site {
  infoPages {
    privacy {
      url
      name
    }
    terms {
      url
      name
    }
    cookies {
      url
      name
    }
  }
}
    `,
	{ fragmentName: "FooterInfoPagesFragment" },
) as unknown as TypedDocumentString<FooterInfoPagesFragmentFragment, unknown>;
export const FooterSocialsFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment FooterSocialsFragment on Site {
  socials {
    facebook
    instagram
    linkedIn
    twitter
    youtube
  }
}
    `,
	{ fragmentName: "FooterSocialsFragment" },
) as unknown as TypedDocumentString<FooterSocialsFragmentFragment, unknown>;
export const FooterMenuFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment FooterMenuFragment on MenuBlock {
  id
  label
  menuLinks {
    name
    url
  }
}
    `,
	{ fragmentName: "FooterMenuFragment" },
) as unknown as TypedDocumentString<FooterMenuFragmentFragment, unknown>;
export const SiteFragmentDoc = new TypedDocumentString(
	`
    fragment Site on Site {
  ...HeaderFragment
  ...PaymentFragment
  ...FooterInfoPagesFragment
  ...FooterSocialsFragment
  footerMenu {
    ...FooterMenuFragment
  }
}
    fragment FooterInfoPagesFragment on Site {
  infoPages {
    privacy {
      url
      name
    }
    terms {
      url
      name
    }
    cookies {
      url
      name
    }
  }
}
fragment FooterMenuFragment on MenuBlock {
  id
  label
  menuLinks {
    name
    url
  }
}
fragment PaymentFragment on Site {
  paymentMethods {
    filename
    alt
  }
}
fragment FooterSocialsFragment on Site {
  socials {
    facebook
    instagram
    linkedIn
    twitter
    youtube
  }
}
fragment HeaderFragment on Site {
  logo {
    alt
    filename
  }
  ...HeaderInfoPagesFragment
  ...MainMenuFragment
}
fragment MainMenuFragment on Site {
  mainMenu {
    ...TopMenuItemFragment
  }
}
fragment TopMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...SubMenuItemFragment
  }
}
fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}
fragment HeaderInfoPagesFragment on Site {
  infoPages {
    business {
      url
      name
    }
    service {
      url
      name
    }
  }
}`,
	{ fragmentName: "Site" },
) as unknown as TypedDocumentString<SiteFragment, unknown>;
export const BusinessUnitsFragmentDoc = new TypedDocumentString(
	`
    fragment BusinessUnits on Customer {
  businessUnits {
    id
    name
    stores {
      id
      name
    }
  }
}
    `,
	{ fragmentName: "BusinessUnits" },
) as unknown as TypedDocumentString<BusinessUnitsFragment, unknown>;
export const CustomerAddressFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CustomerAddressFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
    `,
	{ fragmentName: "CustomerAddressFragment" },
) as unknown as TypedDocumentString<CustomerAddressFragmentFragment, unknown>;
export const OrderLineItemFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment OrderLineItemFragment on LineItem {
  productName
  quantity
  price {
    net {
      ...PriceFragment
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "OrderLineItemFragment" },
) as unknown as TypedDocumentString<OrderLineItemFragmentFragment, unknown>;
export const OrderCardFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment OrderCardFragment on Order {
  id {
    id
  }
  orderNumber
  orderStatus
  createdAt
  total {
    net {
      ...PriceFragment
    }
  }
  lineItems {
    id
    variant {
      primaryImage {
        alt
        url
      }
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "OrderCardFragment" },
) as unknown as TypedDocumentString<OrderCardFragmentFragment, unknown>;
export const OrderMetadataFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment OrderMetadataFragment on Order {
  createdAt
  orderNumber
  orderStatus
}
    `,
	{ fragmentName: "OrderMetadataFragment" },
) as unknown as TypedDocumentString<OrderMetadataFragmentFragment, unknown>;
export const OrderTotalsFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment OrderTotalsFragment on Order {
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "OrderTotalsFragment" },
) as unknown as TypedDocumentString<OrderTotalsFragmentFragment, unknown>;
export const OrderDetailsFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment OrderDetailsFragment on Order {
  id {
    id
  }
  lineItems {
    ...OrderLineItemFragment
  }
  ...OrderCardFragment
  ...OrderMetadataFragment
  ...OrderTotalsFragment
}
    fragment OrderLineItemFragment on LineItem {
  productName
  quantity
  price {
    net {
      ...PriceFragment
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment OrderMetadataFragment on Order {
  createdAt
  orderNumber
  orderStatus
}
fragment OrderTotalsFragment on Order {
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
}
fragment OrderCardFragment on Order {
  id {
    id
  }
  orderNumber
  orderStatus
  createdAt
  total {
    net {
      ...PriceFragment
    }
  }
  lineItems {
    id
    variant {
      primaryImage {
        alt
        url
      }
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "OrderDetailsFragment" },
) as unknown as TypedDocumentString<OrderDetailsFragmentFragment, unknown>;
export const AddressFragmentDoc = new TypedDocumentString(
	`
    fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
    `,
	{ fragmentName: "Address" },
) as unknown as TypedDocumentString<AddressFragment, unknown>;
export const BillingAddressDisplayFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
    `,
	{ fragmentName: "BillingAddressDisplayFragment" },
) as unknown as TypedDocumentString<
	BillingAddressDisplayFragmentFragment,
	unknown
>;
export const CustomerFragmentDoc = new TypedDocumentString(
	`
    fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`,
	{ fragmentName: "Customer" },
) as unknown as TypedDocumentString<CustomerFragment, unknown>;
export const PriceDisplayFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment PriceDisplayFragment on Money {
  centAmount
  currency
}
    `,
	{ fragmentName: "PriceDisplayFragment" },
) as unknown as TypedDocumentString<PriceDisplayFragmentFragment, unknown>;
export const StockIndicatorFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment StockIndicatorFragment on LineItem {
  availableStock
}
    `,
	{ fragmentName: "StockIndicatorFragment" },
) as unknown as TypedDocumentString<StockIndicatorFragmentFragment, unknown>;
export const LineItemFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
    fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "LineItemFragment" },
) as unknown as TypedDocumentString<LineItemFragmentFragment, unknown>;
export const RemoveDiscountCodeButtonFragmentFragmentDoc =
	new TypedDocumentString(
		`
    fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
    `,
		{ fragmentName: "RemoveDiscountCodeButtonFragment" },
	) as unknown as TypedDocumentString<
		RemoveDiscountCodeButtonFragmentFragment,
		unknown
	>;
export const CartTaxesFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "CartTaxesFragment" },
) as unknown as TypedDocumentString<CartTaxesFragmentFragment, unknown>;
export const CartSummaryFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
    fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "CartSummaryFragment" },
) as unknown as TypedDocumentString<CartSummaryFragmentFragment, unknown>;
export const CartOverviewFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
    fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "CartOverviewFragment" },
) as unknown as TypedDocumentString<CartOverviewFragmentFragment, unknown>;
export const CartFragmentDoc = new TypedDocumentString(
	`
    fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "Cart" },
) as unknown as TypedDocumentString<CartFragment, unknown>;
export const ColumnBlockFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ColumnBlockFragment on ColumnBlock {
  ...RichTextFragment
}
    fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}`,
	{ fragmentName: "ColumnBlockFragment" },
) as unknown as TypedDocumentString<ColumnBlockFragmentFragment, unknown>;
export const TwoColumnFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment TwoColumnFragment on TwoColumns {
  __typename
  id
  ratio
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
}
    fragment ColumnBlockFragment on ColumnBlock {
  ...RichTextFragment
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}`,
	{ fragmentName: "TwoColumnFragment" },
) as unknown as TypedDocumentString<TwoColumnFragmentFragment, unknown>;
export const ThreeColumnFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ThreeColumnFragment on ThreeColumns {
  __typename
  id
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
  column3 {
    ...ColumnBlockFragment
  }
}
    fragment ColumnBlockFragment on ColumnBlock {
  ...RichTextFragment
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}`,
	{ fragmentName: "ThreeColumnFragment" },
) as unknown as TypedDocumentString<ThreeColumnFragmentFragment, unknown>;
export const ProductRowFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ProductRowFragment on ProductRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  products {
    __typename
    id
    ...ProductCard
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}`,
	{ fragmentName: "ProductRowFragment" },
) as unknown as TypedDocumentString<ProductRowFragmentFragment, unknown>;
export const FaqItemFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment FaqItemFragment on FaqItem {
  __typename
  id
  title
  content {
    ...RichTextFragment
  }
}
    fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}`,
	{ fragmentName: "FaqItemFragment" },
) as unknown as TypedDocumentString<FaqItemFragmentFragment, unknown>;
export const FaqItemListFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment FaqItemListFragment on FaqItemList {
  __typename
  id
  title
  faqItems: items {
    ...FaqItemFragment
  }
}
    fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
fragment FaqItemFragment on FaqItem {
  __typename
  id
  title
  content {
    ...RichTextFragment
  }
}`,
	{ fragmentName: "FaqItemListFragment" },
) as unknown as TypedDocumentString<FaqItemListFragmentFragment, unknown>;
export const UspFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment UspFragment on Usp {
  __typename
  id
  title
  link {
    url
    newTab
    hash
  }
}
    `,
	{ fragmentName: "UspFragment" },
) as unknown as TypedDocumentString<UspFragmentFragment, unknown>;
export const UspListFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment UspListFragment on UspList {
  __typename
  id
  usps: items {
    ...UspFragment
  }
}
    fragment UspFragment on Usp {
  __typename
  id
  title
  link {
    url
    newTab
    hash
  }
}`,
	{ fragmentName: "UspListFragment" },
) as unknown as TypedDocumentString<UspListFragmentFragment, unknown>;
export const ContentTeaserFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ContentTeaserFragment on ContentTeaser {
  __typename
  id
  title
  content
  link {
    url
    newTab
    hash
  }
  image {
    filename
    alt
  }
  imagePosition
}
    `,
	{ fragmentName: "ContentTeaserFragment" },
) as unknown as TypedDocumentString<ContentTeaserFragmentFragment, unknown>;
export const ContentPageFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ContentPageFragment on ContentPage {
  translatedPaths {
    locale
    path
  }
  breadcrumbs {
    ...BreadcrumbFragment
  }
  __typename
  id
  name
  seo {
    __typename
    title
    description
    ogTitle
    ogDescription
    ogImage
    twitterTitle
    twitterDescription
    twitterImage
  }
  hero {
    ...HeroFragment
  }
  usps {
    ...UspsFragment
  }
  body {
    ...TeaserRowFragment
    ...RichTextFragment
    ...TwoColumnFragment
    ...ThreeColumnFragment
    ...ProductRowFragment
    ...FaqItemListFragment
    ...UspListFragment
    ...ContentTeaserFragment
  }
}
    fragment ColumnBlockFragment on ColumnBlock {
  ...RichTextFragment
}
fragment ThreeColumnFragment on ThreeColumns {
  __typename
  id
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
  column3 {
    ...ColumnBlockFragment
  }
}
fragment TwoColumnFragment on TwoColumns {
  __typename
  id
  ratio
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
}
fragment ContentTeaserFragment on ContentTeaser {
  __typename
  id
  title
  content
  link {
    url
    newTab
    hash
  }
  image {
    filename
    alt
  }
  imagePosition
}
fragment FaqItemListFragment on FaqItemList {
  __typename
  id
  title
  faqItems: items {
    ...FaqItemFragment
  }
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
fragment UspListFragment on UspList {
  __typename
  id
  usps: items {
    ...UspFragment
  }
}
fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}
fragment FaqItemFragment on FaqItem {
  __typename
  id
  title
  content {
    ...RichTextFragment
  }
}
fragment HeroFragment on Hero {
  __typename
  id
  title
  imagePosition
  imageStyle
  bodyText {
    ...RichTextFragment
  }
  buttons {
    __typename
    id
    title
    variant
    link {
      url
      newTab
      hash
    }
  }
  image {
    filename
    alt
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}
fragment ProductRowFragment on ProductRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  products {
    __typename
    id
    ...ProductCard
  }
}
fragment TeaserRowFragment on TeaserRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  items {
    __typename
    id
    content
    image {
      alt
      filename
    }
    link {
      url
    }
    title
  }
}
fragment UspsFragment on Usps {
  id
  usp1
  usp2
  usp3
  usp4
}
fragment UspFragment on Usp {
  __typename
  id
  title
  link {
    url
    newTab
    hash
  }
}`,
	{ fragmentName: "ContentPageFragment" },
) as unknown as TypedDocumentString<ContentPageFragmentFragment, unknown>;
export const ProductGalleryImageFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ProductGalleryImageFragment on ProductImage {
  url
  alt
}
    `,
	{ fragmentName: "ProductGalleryImageFragment" },
) as unknown as TypedDocumentString<
	ProductGalleryImageFragmentFragment,
	unknown
>;
export const SpecificationAttributeFragmentFragmentDoc =
	new TypedDocumentString(
		`
    fragment SpecificationAttributeFragment on ProductSpecificationAttribute {
  ... on ProductSpecificationBooleanAttribute {
    __typename
    label
    name
    description
    boolValue: value
  }
  ... on ProductSpecificationNumberAttribute {
    __typename
    label
    name
    description
    numberValue: value
  }
  ... on ProductSpecificationStringAttribute {
    __typename
    label
    name
    description
    stringValue: value
  }
  ... on ProductSpecificationEnumAttribute {
    __typename
    label
    name
    description
    enumValue: value {
      label
      value
    }
  }
  ... on ProductSpecificationEnumListAttribute {
    __typename
    label
    name
    description
    enumListValue: values {
      label
      value
    }
  }
  ... on ProductSpecificationDateTimeAttribute {
    __typename
    label
    name
    description
    dateTimeValue: value
  }
}
    `,
		{ fragmentName: "SpecificationAttributeFragment" },
	) as unknown as TypedDocumentString<
		SpecificationAttributeFragmentFragment,
		unknown
	>;
export const ProductVariantFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ProductVariantFragment on ProductVariant {
  id
  name
  sku
  availability
  images {
    ...ProductGalleryImageFragment
  }
  deliveryTime
  description
  seller
  specifications {
    name
    label
    attributes {
      ...SpecificationAttributeFragment
    }
  }
}
    fragment ProductGalleryImageFragment on ProductImage {
  url
  alt
}
fragment SpecificationAttributeFragment on ProductSpecificationAttribute {
  ... on ProductSpecificationBooleanAttribute {
    __typename
    label
    name
    description
    boolValue: value
  }
  ... on ProductSpecificationNumberAttribute {
    __typename
    label
    name
    description
    numberValue: value
  }
  ... on ProductSpecificationStringAttribute {
    __typename
    label
    name
    description
    stringValue: value
  }
  ... on ProductSpecificationEnumAttribute {
    __typename
    label
    name
    description
    enumValue: value {
      label
      value
    }
  }
  ... on ProductSpecificationEnumListAttribute {
    __typename
    label
    name
    description
    enumListValue: values {
      label
      value
    }
  }
  ... on ProductSpecificationDateTimeAttribute {
    __typename
    label
    name
    description
    dateTimeValue: value
  }
}`,
	{ fragmentName: "ProductVariantFragment" },
) as unknown as TypedDocumentString<ProductVariantFragmentFragment, unknown>;
export const ColorSelectorGroupFieldFragmentFragmentDoc =
	new TypedDocumentString(
		`
    fragment ColorSelectorGroupFieldFragment on ProductVariantDimensionColorOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
  color
}
    `,
		{ fragmentName: "ColorSelectorGroupFieldFragment" },
	) as unknown as TypedDocumentString<
		ColorSelectorGroupFieldFragmentFragment,
		unknown
	>;
export const ButtonSelectorGroupFieldFragmentFragmentDoc =
	new TypedDocumentString(
		`
    fragment ButtonSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
}
    `,
		{ fragmentName: "ButtonSelectorGroupFieldFragment" },
	) as unknown as TypedDocumentString<
		ButtonSelectorGroupFieldFragmentFragment,
		unknown
	>;
export const ImageSelectorGroupFieldFragmentFragmentDoc =
	new TypedDocumentString(
		`
    fragment ImageSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
    primaryImage {
      url
      alt
    }
  }
}
    `,
		{ fragmentName: "ImageSelectorGroupFieldFragment" },
	) as unknown as TypedDocumentString<
		ImageSelectorGroupFieldFragmentFragment,
		unknown
	>;
export const ProductFormFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ProductFormFragment on ProductVariantDimension {
  name
  label
  options {
    __typename
    ...ColorSelectorGroupFieldFragment
    ...ButtonSelectorGroupFieldFragment
    ...ImageSelectorGroupFieldFragment
  }
}
    fragment ButtonSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
}
fragment ColorSelectorGroupFieldFragment on ProductVariantDimensionColorOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
  color
}
fragment ImageSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
    primaryImage {
      url
      alt
    }
  }
}`,
	{ fragmentName: "ProductFormFragment" },
) as unknown as TypedDocumentString<ProductFormFragmentFragment, unknown>;
export const ProductBreadcrumbFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ProductBreadcrumbFragment on Product {
  name
  slug
  primaryCategory {
    name
    path
    ancestors {
      name
      path
    }
  }
}
    `,
	{ fragmentName: "ProductBreadcrumbFragment" },
) as unknown as TypedDocumentString<ProductBreadcrumbFragmentFragment, unknown>;
export const ProductDetailPageFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ProductDetailPageFragment on Product {
  meta {
    title
    description
  }
  usps {
    id
    usp1
    usp2
    usp3
    usp4
  }
  name
  slug
  brand
  ribbon {
    kind
    label
  }
  primaryVariant {
    images {
      url
    }
  }
  primaryCategory {
    name
    slug
  }
  variant(sku: $selectedSku) {
    ...ProductVariantFragment
    price {
      gross {
        ...PriceFragment
      }
      net {
        ...PriceFragment
      }
    }
  }
  variantDimensions(selectedSku: $selectedSku) {
    ...ProductFormFragment
  }
  reviews {
    amount
    averageRating
  }
  ...ProductBreadcrumbFragment
}
    fragment ProductBreadcrumbFragment on Product {
  name
  slug
  primaryCategory {
    name
    path
    ancestors {
      name
      path
    }
  }
}
fragment ButtonSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
}
fragment ColorSelectorGroupFieldFragment on ProductVariantDimensionColorOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
  color
}
fragment ImageSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment ProductFormFragment on ProductVariantDimension {
  name
  label
  options {
    __typename
    ...ColorSelectorGroupFieldFragment
    ...ButtonSelectorGroupFieldFragment
    ...ImageSelectorGroupFieldFragment
  }
}
fragment ProductGalleryImageFragment on ProductImage {
  url
  alt
}
fragment SpecificationAttributeFragment on ProductSpecificationAttribute {
  ... on ProductSpecificationBooleanAttribute {
    __typename
    label
    name
    description
    boolValue: value
  }
  ... on ProductSpecificationNumberAttribute {
    __typename
    label
    name
    description
    numberValue: value
  }
  ... on ProductSpecificationStringAttribute {
    __typename
    label
    name
    description
    stringValue: value
  }
  ... on ProductSpecificationEnumAttribute {
    __typename
    label
    name
    description
    enumValue: value {
      label
      value
    }
  }
  ... on ProductSpecificationEnumListAttribute {
    __typename
    label
    name
    description
    enumListValue: values {
      label
      value
    }
  }
  ... on ProductSpecificationDateTimeAttribute {
    __typename
    label
    name
    description
    dateTimeValue: value
  }
}
fragment ProductVariantFragment on ProductVariant {
  id
  name
  sku
  availability
  images {
    ...ProductGalleryImageFragment
  }
  deliveryTime
  description
  seller
  specifications {
    name
    label
    attributes {
      ...SpecificationAttributeFragment
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "ProductDetailPageFragment" },
) as unknown as TypedDocumentString<ProductDetailPageFragmentFragment, unknown>;
export const ContextLineItemFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment ContextLineItemFragment on LineItem {
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "ContextLineItemFragment" },
) as unknown as TypedDocumentString<ContextLineItemFragmentFragment, unknown>;
export const CrossSellProductCardFragmentFragmentDoc = new TypedDocumentString(
	`
    fragment CrossSellProductCardFragment on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  primaryVariant {
    id
    sku
    availability
    price {
      gross {
        ...PriceFragment
      }
    }
  }
}
    fragment PriceFragment on Money {
  centAmount
  currency
}`,
	{ fragmentName: "CrossSellProductCardFragment" },
) as unknown as TypedDocumentString<
	CrossSellProductCardFragmentFragment,
	unknown
>;
export const AddressValidationResultFragmentDoc = new TypedDocumentString(
	`
    fragment AddressValidationResult on AddressValidationResult {
  valid
  suggestions {
    city
    country
    streetNumber
    streetNumberSuffix
    postalCode
    streetName
  }
}
    `,
	{ fragmentName: "AddressValidationResult" },
) as unknown as TypedDocumentString<AddressValidationResultFragment, unknown>;
export const StoreContentPreviewDataDocument = new TypedDocumentString(`
    mutation StoreContentPreviewData($path: String!, $data: String!) {
  storeContentPreview(data: $data, path: $path) {
    id
  }
}
    `) as unknown as TypedDocumentString<
	StoreContentPreviewDataMutation,
	StoreContentPreviewDataMutationVariables
>;
export const ValidateApplePayPaymentDocument = new TypedDocumentString(`
    mutation ValidateApplePayPayment($storeContext: StoreContextInput!, $paymentContext: ApplePayPaymentContextInput!) {
  validateApplePayPayment(
    storeContext: $storeContext
    paymentContext: $paymentContext
  ) {
    data
  }
}
    `) as unknown as TypedDocumentString<
	ValidateApplePayPaymentMutation,
	ValidateApplePayPaymentMutationVariables
>;
export const CreateApplePayOrderDocument = new TypedDocumentString(`
    mutation CreateApplePayOrder($storeContext: StoreContextInput!) {
  checkoutComplete(storeContext: $storeContext) {
    orderId
    orderNumber
  }
}
    `) as unknown as TypedDocumentString<
	CreateApplePayOrderMutation,
	CreateApplePayOrderMutationVariables
>;
export const ApplePayPaymentCreateDocument = new TypedDocumentString(`
    mutation ApplePayPaymentCreate($storeContext: StoreContextInput!, $paymentContext: PaymentContextInput!) {
  createPayment(storeContext: $storeContext, paymentContext: $paymentContext) {
    redirectURL
  }
}
    `) as unknown as TypedDocumentString<
	ApplePayPaymentCreateMutation,
	ApplePayPaymentCreateMutationVariables
>;
export const UpdateCheckoutAddressDocument = new TypedDocumentString(`
    mutation UpdateCheckoutAddress($storeContext: StoreContextInput!, $setCustomerEmail: String, $setBillingAddress: AddressInput, $setShippingAddress: AddressInput) {
  checkoutUpdate(
    storeContext: $storeContext
    setBillingAddress: $setBillingAddress
    setShippingAddress: $setShippingAddress
    setCustomerEmail: $setCustomerEmail
  ) {
    ...CheckoutCartFragment
  }
}
    fragment ApplePayPaymentMethod on PaymentMethod {
  id
  name
  provider
  extra {
    ... on ApplePayExtra {
      type
      brands
      configuration {
        merchantId
        merchantName
      }
    }
  }
}
fragment CheckoutCartFragment on Cart {
  id {
    version
    id
  }
  availablePaymentMethods(device: web) {
    name
    paymentMethod {
      ...ApplePayPaymentMethod
    }
  }
  billingAddress {
    id
  }
  customerEmail
  lineItems {
    id
    productName
    quantity
    price {
      gross {
        ...PriceFragment
      }
    }
  }
  total {
    gross {
      ...PriceFragment
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	UpdateCheckoutAddressMutation,
	UpdateCheckoutAddressMutationVariables
>;
export const CheckoutCustomerExistsDocument = new TypedDocumentString(`
    query CheckoutCustomerExists($storeContext: StoreContextInput!, $email: String!) {
  customerExists(storeContext: $storeContext, email: $email) {
    result
  }
}
    `) as unknown as TypedDocumentString<
	CheckoutCustomerExistsQuery,
	CheckoutCustomerExistsQueryVariables
>;
export const CustomerCreateEmailStepDocument = new TypedDocumentString(`
    mutation CustomerCreateEmailStep($storeContext: StoreContextInput!, $customer: CustomerCreateInput!) {
  customerCreate(storeContext: $storeContext, customer: $customer) {
    createdAt
  }
}
    `) as unknown as TypedDocumentString<
	CustomerCreateEmailStepMutation,
	CustomerCreateEmailStepMutationVariables
>;
export const CheckoutCartUpdateDocument = new TypedDocumentString(`
    mutation checkoutCartUpdate($storeContext: StoreContextInput!, $id: CartIdentifierInput, $setCustomerEmail: String, $setBillingAddress: AddressInput, $setShippingAddress: AddressInput, $setPaymentMethod: PaymentMethodInput) {
  checkoutUpdate(
    storeContext: $storeContext
    id: $id
    setCustomerEmail: $setCustomerEmail
    setBillingAddress: $setBillingAddress
    setShippingAddress: $setShippingAddress
    setPaymentMethod: $setPaymentMethod
  ) {
    ...CheckoutCartFragment
  }
}
    fragment ApplePayPaymentMethod on PaymentMethod {
  id
  name
  provider
  extra {
    ... on ApplePayExtra {
      type
      brands
      configuration {
        merchantId
        merchantName
      }
    }
  }
}
fragment CheckoutCartFragment on Cart {
  id {
    version
    id
  }
  availablePaymentMethods(device: web) {
    name
    paymentMethod {
      ...ApplePayPaymentMethod
    }
  }
  billingAddress {
    id
  }
  customerEmail
  lineItems {
    id
    productName
    quantity
    price {
      gross {
        ...PriceFragment
      }
    }
  }
  total {
    gross {
      ...PriceFragment
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	CheckoutCartUpdateMutation,
	CheckoutCartUpdateMutationVariables
>;
export const GetCartForCheckoutDocument = new TypedDocumentString(`
    query GetCartForCheckout($storeContext: StoreContextInput!) {
  cart(storeContext: $storeContext) {
    ...CheckoutCartFragment
  }
}
    fragment ApplePayPaymentMethod on PaymentMethod {
  id
  name
  provider
  extra {
    ... on ApplePayExtra {
      type
      brands
      configuration {
        merchantId
        merchantName
      }
    }
  }
}
fragment CheckoutCartFragment on Cart {
  id {
    version
    id
  }
  availablePaymentMethods(device: web) {
    name
    paymentMethod {
      ...ApplePayPaymentMethod
    }
  }
  billingAddress {
    id
  }
  customerEmail
  lineItems {
    id
    productName
    quantity
    price {
      gross {
        ...PriceFragment
      }
    }
  }
  total {
    gross {
      ...PriceFragment
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	GetCartForCheckoutQuery,
	GetCartForCheckoutQueryVariables
>;
export const GetSimpleSiteDocument = new TypedDocumentString(`
    query GetSimpleSite($storeContext: StoreContextInput!, $version: ContentVersion!, $previewID: String) {
  site(storeContext: $storeContext, version: $version, previewID: $previewID) {
    ...HeaderFragment
    ...FooterInfoPagesFragment
  }
}
    fragment FooterInfoPagesFragment on Site {
  infoPages {
    privacy {
      url
      name
    }
    terms {
      url
      name
    }
    cookies {
      url
      name
    }
  }
}
fragment HeaderFragment on Site {
  logo {
    alt
    filename
  }
  ...HeaderInfoPagesFragment
  ...MainMenuFragment
}
fragment MainMenuFragment on Site {
  mainMenu {
    ...TopMenuItemFragment
  }
}
fragment TopMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...SubMenuItemFragment
  }
}
fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}
fragment HeaderInfoPagesFragment on Site {
  infoPages {
    business {
      url
      name
    }
    service {
      url
      name
    }
  }
}`) as unknown as TypedDocumentString<
	GetSimpleSiteQuery,
	GetSimpleSiteQueryVariables
>;
export const GetCheckoutInformationDocument = new TypedDocumentString(`
    query GetCheckoutInformation($storeContext: StoreContextInput!) {
  cart(storeContext: $storeContext) {
    ...CheckoutSummaryFragment
    billingAddress {
      ...BillingAddressFragment
    }
  }
}
    fragment CheckoutSummaryFragment on Cart {
  id {
    id
    version
  }
  lineItems {
    id
    quantity
    productSlug
    productName
    addedAt
    variant {
      name
      sku
      primaryImage {
        url
        alt
      }
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment BillingAddressFragment on Address {
  id
  email
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	GetCheckoutInformationQuery,
	GetCheckoutInformationQueryVariables
>;
export const UpdateCheckoutInformationDocument = new TypedDocumentString(`
    mutation UpdateCheckoutInformation($storeContext: StoreContextInput!, $setBillingAddress: AddressInput) {
  checkoutUpdate(
    storeContext: $storeContext
    setBillingAddress: $setBillingAddress
    setShippingAddress: $setBillingAddress
  ) {
    billingAddress {
      ...BillingAddressFragment
    }
  }
}
    fragment BillingAddressFragment on Address {
  id
  email
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	UpdateCheckoutInformationMutation,
	UpdateCheckoutInformationMutationVariables
>;
export const CustomerExistsDocument = new TypedDocumentString(`
    query CustomerExists($storeContext: StoreContextInput!, $email: String!) {
  customerExists(storeContext: $storeContext, email: $email) {
    result
  }
}
    `) as unknown as TypedDocumentString<
	CustomerExistsQuery,
	CustomerExistsQueryVariables
>;
export const GetCheckoutLoginDocument = new TypedDocumentString(`
    query GetCheckoutLogin($storeContext: StoreContextInput!) {
  cart(storeContext: $storeContext) {
    ...CheckoutSummaryFragment
    customerEmail
    billingAddress {
      ...BillingAddressFragment
    }
  }
}
    fragment CheckoutSummaryFragment on Cart {
  id {
    id
    version
  }
  lineItems {
    id
    quantity
    productSlug
    productName
    addedAt
    variant {
      name
      sku
      primaryImage {
        url
        alt
      }
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment BillingAddressFragment on Address {
  id
  email
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	GetCheckoutLoginQuery,
	GetCheckoutLoginQueryVariables
>;
export const UpdateCheckoutLoginDocument = new TypedDocumentString(`
    mutation UpdateCheckoutLogin($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $setCustomerEmail: String, $setBillingAddress: AddressInput) {
  checkoutUpdate(
    storeContext: $storeContext
    id: $id
    setCustomerEmail: $setCustomerEmail
    setBillingAddress: $setBillingAddress
  ) {
    id {
      id
      version
    }
  }
}
    `) as unknown as TypedDocumentString<
	UpdateCheckoutLoginMutation,
	UpdateCheckoutLoginMutationVariables
>;
export const CheckoutCustomerLoginDocument = new TypedDocumentString(`
    mutation CheckoutCustomerLogin($storeContext: StoreContextInput!, $email: String!, $password: String!) {
  customerLogin(storeContext: $storeContext, email: $email, password: $password) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CheckoutCustomerLoginMutation,
	CheckoutCustomerLoginMutationVariables
>;
export const GetCheckoutPaymentDocument = new TypedDocumentString(`
    query GetCheckoutPayment($storeContext: StoreContextInput!) {
  cart(storeContext: $storeContext) {
    ...CheckoutSummaryFragment
    availablePaymentMethods(device: web) {
      name
      paymentMethod {
        ...PaymentMethod
      }
    }
    selectedPaymentMethod {
      ...PaymentMethod
    }
  }
}
    fragment CheckoutSummaryFragment on Cart {
  id {
    id
    version
  }
  lineItems {
    id
    quantity
    productSlug
    productName
    addedAt
    variant {
      name
      sku
      primaryImage {
        url
        alt
      }
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PaymentMethod on PaymentMethod {
  id
  name
  provider
  issuers {
    id
    name
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	GetCheckoutPaymentQuery,
	GetCheckoutPaymentQueryVariables
>;
export const PaymentCreateDocument = new TypedDocumentString(`
    mutation PaymentCreate($storeContext: StoreContextInput!, $paymentContext: PaymentContextInput!) {
  createPayment(storeContext: $storeContext, paymentContext: $paymentContext) {
    redirectURL
  }
}
    `) as unknown as TypedDocumentString<
	PaymentCreateMutation,
	PaymentCreateMutationVariables
>;
export const CreateOrderDocument = new TypedDocumentString(`
    mutation CreateOrder($storeContext: StoreContextInput!) {
  checkoutComplete(storeContext: $storeContext) {
    orderId
    orderNumber
  }
}
    `) as unknown as TypedDocumentString<
	CreateOrderMutation,
	CreateOrderMutationVariables
>;
export const GetCategoryPageDocument = new TypedDocumentString(`
    query GetCategoryPage($storeContext: StoreContextInput!, $path: String!) {
  categoryPageByPath(storeContext: $storeContext, path: $path) {
    ...CategoryPageFragment
  }
}
    fragment ProductListingConfig on ProductListingConfig {
  categoryId
  defaultOrder
  prefilters {
    key
    value
  }
}
fragment CategoryPageFragment on CategoryPage {
  meta {
    title
    description
    keywords
  }
  translatedPaths {
    locale
    path
  }
  breadcrumbs {
    ...BreadcrumbFragment
  }
  category {
    id
    name
    description
  }
  productListingConfig {
    ...ProductListingConfig
  }
}
fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}`) as unknown as TypedDocumentString<
	GetCategoryPageQuery,
	GetCategoryPageQueryVariables
>;
export const GetProductsForCategoryDocument = new TypedDocumentString(`
    query GetProductsForCategory($storeContext: StoreContextInput!, $filters: [FacetFilterInput!], $prefilters: [FilterConditionInput!], $categoryId: String!, $searchTerm: String, $pageSize: Int!, $page: Int!, $sort: ProductSortOrder!) {
  productSearch(
    storeContext: $storeContext
    filters: $filters
    prefilters: $prefilters
    categoryId: $categoryId
    searchTerm: $searchTerm
    pageSize: $pageSize
    page: $page
    sort: $sort
  ) {
    ...ProductsResult
  }
}
    fragment FacetsFragment on Facet {
  ...OptionsFacetFragment
  ...RangeFacetFragment
  ...ToggleFacetFragment
}
fragment OptionsFacetFragment on OptionsFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment RangeFacetFragment on RangeFacet {
  __typename
  key
  label
  min
  max
  selectedMin
  selectedMax
}
fragment ToggleFacetFragment on ToggleFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment ProductsResult on ProductsResult {
  total
  results {
    ...ProductCard
  }
  facets {
    ...FacetsFragment
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}`) as unknown as TypedDocumentString<
	GetProductsForCategoryQuery,
	GetProductsForCategoryQueryVariables
>;
export const GetProductListingPageDocument = new TypedDocumentString(`
    query GetProductListingPage($storeContext: StoreContextInput!, $path: String!, $previewID: String) {
  productListingPage(
    storeContext: $storeContext
    path: $path
    previewID: $previewID
  ) {
    ...ProductListingPage
  }
}
    fragment ProductListingConfig on ProductListingConfig {
  categoryId
  defaultOrder
  prefilters {
    key
    value
  }
}
fragment ProductListingPage on ProductListingPage {
  __typename
  id
  name
  translatedPaths {
    locale
    path
  }
  seo {
    __typename
    title
    description
    ogTitle
    ogDescription
    ogImage
    twitterTitle
    twitterDescription
    twitterImage
  }
  breadcrumbs {
    ...BreadcrumbFragment
  }
  hero {
    ...HeroFragment
  }
  usps {
    ...UspsFragment
  }
  productListingConfig {
    ...ProductListingConfig
  }
  teaserRow {
    ...TeaserRowFragment
  }
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}
fragment HeroFragment on Hero {
  __typename
  id
  title
  imagePosition
  imageStyle
  bodyText {
    ...RichTextFragment
  }
  buttons {
    __typename
    id
    title
    variant
    link {
      url
      newTab
      hash
    }
  }
  image {
    filename
    alt
  }
}
fragment TeaserRowFragment on TeaserRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  items {
    __typename
    id
    content
    image {
      alt
      filename
    }
    link {
      url
    }
    title
  }
}
fragment UspsFragment on Usps {
  id
  usp1
  usp2
  usp3
  usp4
}`) as unknown as TypedDocumentString<
	GetProductListingPageQuery,
	GetProductListingPageQueryVariables
>;
export const GetProductsOldDocument = new TypedDocumentString(`
    query GetProductsOld($storeContext: StoreContextInput!, $sort: ProductSortOrder!, $filters: [FacetFilterInput!], $searchTerm: String, $pageSize: Int!, $page: Int!) {
  productSearch(
    storeContext: $storeContext
    sort: $sort
    filters: $filters
    searchTerm: $searchTerm
    pageSize: $pageSize
    page: $page
  ) {
    ...ProductsResult
  }
}
    fragment FacetsFragment on Facet {
  ...OptionsFacetFragment
  ...RangeFacetFragment
  ...ToggleFacetFragment
}
fragment OptionsFacetFragment on OptionsFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment RangeFacetFragment on RangeFacet {
  __typename
  key
  label
  min
  max
  selectedMin
  selectedMax
}
fragment ToggleFacetFragment on ToggleFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment ProductsResult on ProductsResult {
  total
  results {
    ...ProductCard
  }
  facets {
    ...FacetsFragment
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}`) as unknown as TypedDocumentString<
	GetProductsOldQuery,
	GetProductsOldQueryVariables
>;
export const GetProductsDocument = new TypedDocumentString(`
    query GetProducts($storeContext: StoreContextInput!, $sort: ProductSortOrder!, $filters: [FacetFilterInput!], $searchTerm: String, $pageSize: Int!, $page: Int!) {
  productSearch(
    storeContext: $storeContext
    sort: $sort
    filters: $filters
    searchTerm: $searchTerm
    pageSize: $pageSize
    page: $page
  ) {
    ...ProductsResult
  }
}
    fragment FacetsFragment on Facet {
  ...OptionsFacetFragment
  ...RangeFacetFragment
  ...ToggleFacetFragment
}
fragment OptionsFacetFragment on OptionsFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment RangeFacetFragment on RangeFacet {
  __typename
  key
  label
  min
  max
  selectedMin
  selectedMax
}
fragment ToggleFacetFragment on ToggleFacet {
  __typename
  key
  label
  options {
    key
    label
    count
    selected
  }
}
fragment ProductsResult on ProductsResult {
  total
  results {
    ...ProductCard
  }
  facets {
    ...FacetsFragment
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}`) as unknown as TypedDocumentString<
	GetProductsQuery,
	GetProductsQueryVariables
>;
export const CustomerBusinessUnitsQueryDocument = new TypedDocumentString(`
    query CustomerBusinessUnitsQuery($storeContext: StoreContextInput!) {
  customer(storeContext: $storeContext) {
    ...BusinessUnits
  }
}
    fragment BusinessUnits on Customer {
  businessUnits {
    id
    name
    stores {
      id
      name
    }
  }
}`) as unknown as TypedDocumentString<
	CustomerBusinessUnitsQueryQuery,
	CustomerBusinessUnitsQueryQueryVariables
>;
export const GetCartQuantityDocument = new TypedDocumentString(`
    query GetCartQuantity($storeContext: StoreContextInput!) {
  cart(storeContext: $storeContext) {
    lineItems {
      quantity
    }
  }
}
    `) as unknown as TypedDocumentString<
	GetCartQuantityQuery,
	GetCartQuantityQueryVariables
>;
export const GetSiteDocument = new TypedDocumentString(`
    query GetSite($storeContext: StoreContextInput!, $version: ContentVersion!, $previewID: String) {
  site(storeContext: $storeContext, version: $version, previewID: $previewID) {
    ...Site
  }
}
    fragment FooterInfoPagesFragment on Site {
  infoPages {
    privacy {
      url
      name
    }
    terms {
      url
      name
    }
    cookies {
      url
      name
    }
  }
}
fragment FooterMenuFragment on MenuBlock {
  id
  label
  menuLinks {
    name
    url
  }
}
fragment PaymentFragment on Site {
  paymentMethods {
    filename
    alt
  }
}
fragment FooterSocialsFragment on Site {
  socials {
    facebook
    instagram
    linkedIn
    twitter
    youtube
  }
}
fragment HeaderFragment on Site {
  logo {
    alt
    filename
  }
  ...HeaderInfoPagesFragment
  ...MainMenuFragment
}
fragment MainMenuFragment on Site {
  mainMenu {
    ...TopMenuItemFragment
  }
}
fragment TopMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...SubMenuItemFragment
  }
}
fragment SubMenuItemFragment on MenuItem {
  ...MenuItemFieldsFragment
  subMenuItems {
    ...MenuItemFieldsFragment
  }
}
fragment MenuItemFieldsFragment on MenuItem {
  __typename
  id
  label
  target {
    ... on ResourceLink {
      url
      __typename
    }
    ... on ProductCategory {
      name
      path
      __typename
    }
  }
}
fragment HeaderInfoPagesFragment on Site {
  infoPages {
    business {
      url
      name
    }
    service {
      url
      name
    }
  }
}
fragment Site on Site {
  ...HeaderFragment
  ...PaymentFragment
  ...FooterInfoPagesFragment
  ...FooterSocialsFragment
  footerMenu {
    ...FooterMenuFragment
  }
}`) as unknown as TypedDocumentString<GetSiteQuery, GetSiteQueryVariables>;
export const CustomerLoginDocument = new TypedDocumentString(`
    mutation CustomerLogin($storeContext: StoreContextInput!, $email: String!, $password: String!) {
  customerLogin(storeContext: $storeContext, email: $email, password: $password) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerLoginMutation,
	CustomerLoginMutationVariables
>;
export const CustomerPasswordResetRequestDocument = new TypedDocumentString(`
    mutation CustomerPasswordResetRequest($storeContext: StoreContextInput!, $email: String!) {
  customerPasswordResetRequest(storeContext: $storeContext, email: $email)
}
    `) as unknown as TypedDocumentString<
	CustomerPasswordResetRequestMutation,
	CustomerPasswordResetRequestMutationVariables
>;
export const CustomerPasswordResetDocument = new TypedDocumentString(`
    mutation CustomerPasswordReset($storeContext: StoreContextInput!, $password: String!, $token: String!) {
  customerPasswordReset(
    storeContext: $storeContext
    password: $password
    token: $token
  ) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerPasswordResetMutation,
	CustomerPasswordResetMutationVariables
>;
export const CustomerCreateDocument = new TypedDocumentString(`
    mutation CustomerCreate($storeContext: StoreContextInput!, $customer: CustomerCreateInput!) {
  customerCreate(storeContext: $storeContext, customer: $customer) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerCreateMutation,
	CustomerCreateMutationVariables
>;
export const SetBusinessContextDocument = new TypedDocumentString(`
    mutation SetBusinessContext($businessUnitId: ID!, $storeKey: String!) {
  setActiveBusinessContext(businessUnitId: $businessUnitId, storeKey: $storeKey)
}
    `) as unknown as TypedDocumentString<
	SetBusinessContextMutation,
	SetBusinessContextMutationVariables
>;
export const PreviewOrdersQueryDocument = new TypedDocumentString(`
    query PreviewOrdersQuery($storeContext: StoreContextInput!) {
  orders(storeContext: $storeContext, pageSize: 3) {
    total
    results {
      ...OrderCardFragment
    }
  }
}
    fragment OrderCardFragment on Order {
  id {
    id
  }
  orderNumber
  orderStatus
  createdAt
  total {
    net {
      ...PriceFragment
    }
  }
  lineItems {
    id
    variant {
      primaryImage {
        alt
        url
      }
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	PreviewOrdersQueryQuery,
	PreviewOrdersQueryQueryVariables
>;
export const CustomerAddressCreateDocument = new TypedDocumentString(`
    mutation CustomerAddressCreate($storeContext: StoreContextInput!, $address: AddressInput!) {
  customerAddressCreate(storeContext: $storeContext, address: $address) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerAddressCreateMutation,
	CustomerAddressCreateMutationVariables
>;
export const CustomerAddressUpdateDocument = new TypedDocumentString(`
    mutation CustomerAddressUpdate($storeContext: StoreContextInput!, $addressId: ID!, $address: AddressInput!) {
  customerAddressUpdate(
    storeContext: $storeContext
    addressId: $addressId
    address: $address
  ) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerAddressUpdateMutation,
	CustomerAddressUpdateMutationVariables
>;
export const CustomerAddressQueryDocument = new TypedDocumentString(`
    query CustomerAddressQuery($storeContext: StoreContextInput!) {
  customer(storeContext: $storeContext) {
    givenName
    addresses {
      id
      email
      salutation
      givenName
      familyName
      streetName
      streetNumber
      streetNumberSuffix
      postalCode
      city
      phone
      country
      company
    }
  }
}
    `) as unknown as TypedDocumentString<
	CustomerAddressQueryQuery,
	CustomerAddressQueryQueryVariables
>;
export const OrderDetailQueryDocument = new TypedDocumentString(`
    query OrderDetailQuery($storeContext: StoreContextInput!, $orderId: String!) {
  order(storeContext: $storeContext, orderId: $orderId) {
    ...OrderDetailsFragment
  }
}
    fragment OrderDetailsFragment on Order {
  id {
    id
  }
  lineItems {
    ...OrderLineItemFragment
  }
  ...OrderCardFragment
  ...OrderMetadataFragment
  ...OrderTotalsFragment
}
fragment OrderLineItemFragment on LineItem {
  productName
  quantity
  price {
    net {
      ...PriceFragment
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment OrderMetadataFragment on Order {
  createdAt
  orderNumber
  orderStatus
}
fragment OrderTotalsFragment on Order {
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
}
fragment OrderCardFragment on Order {
  id {
    id
  }
  orderNumber
  orderStatus
  createdAt
  total {
    net {
      ...PriceFragment
    }
  }
  lineItems {
    id
    variant {
      primaryImage {
        alt
        url
      }
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	OrderDetailQueryQuery,
	OrderDetailQueryQueryVariables
>;
export const OverviewOrdersQueryDocument = new TypedDocumentString(`
    query OverviewOrdersQuery($storeContext: StoreContextInput!) {
  orders(storeContext: $storeContext) {
    total
    results {
      ...OrderCardFragment
    }
  }
}
    fragment OrderCardFragment on Order {
  id {
    id
  }
  orderNumber
  orderStatus
  createdAt
  total {
    net {
      ...PriceFragment
    }
  }
  lineItems {
    id
    variant {
      primaryImage {
        alt
        url
      }
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	OverviewOrdersQueryQuery,
	OverviewOrdersQueryQueryVariables
>;
export const GetCustomerDocument = new TypedDocumentString(`
    query GetCustomer($storeContext: StoreContextInput!) {
  customer(storeContext: $storeContext) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	GetCustomerQuery,
	GetCustomerQueryVariables
>;
export const CustomerBillingAddressUpdateDocument = new TypedDocumentString(`
    mutation CustomerBillingAddressUpdate($storeContext: StoreContextInput!, $billingAddress: AddressInput!) {
  customerInformationUpdate(
    storeContext: $storeContext
    billingAddress: $billingAddress
  ) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerBillingAddressUpdateMutation,
	CustomerBillingAddressUpdateMutationVariables
>;
export const CustomerEmailUpdateDocument = new TypedDocumentString(`
    mutation CustomerEmailUpdate($storeContext: StoreContextInput!, $email: String!, $password: String!) {
  customerEmailUpdate(
    storeContext: $storeContext
    email: $email
    password: $password
  ) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerEmailUpdateMutation,
	CustomerEmailUpdateMutationVariables
>;
export const CustomerPasswordUpdateDocument = new TypedDocumentString(`
    mutation CustomerPasswordUpdate($storeContext: StoreContextInput!, $password: String!, $newPassword: String!) {
  customerPasswordUpdate(
    storeContext: $storeContext
    password: $password
    newPassword: $newPassword
  ) {
    ...Customer
  }
}
    fragment BillingAddressDisplayFragment on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}
fragment Customer on Customer {
  givenName
  email
  vatNumber
  billingAddress {
    ...Address
    ...BillingAddressDisplayFragment
  }
  addresses {
    ...Address
  }
}
fragment Address on Address {
  id
  email
  salutation
  givenName
  familyName
  streetName
  streetNumber
  streetNumberSuffix
  postalCode
  city
  phone
  country
  company
}`) as unknown as TypedDocumentString<
	CustomerPasswordUpdateMutation,
	CustomerPasswordUpdateMutationVariables
>;
export const AddDiscountCodeDocument = new TypedDocumentString(`
    mutation AddDiscountCode($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $code: String!) {
  cartDiscountCodeAdd(storeContext: $storeContext, id: $id, code: $code) {
    ...Cart
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	AddDiscountCodeMutation,
	AddDiscountCodeMutationVariables
>;
export const RemoveDiscountCodeDocument = new TypedDocumentString(`
    mutation RemoveDiscountCode($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $discountCodeId: ID!) {
  cartDiscountCodeRemove(
    storeContext: $storeContext
    id: $id
    discountCodeId: $discountCodeId
  ) {
    ...Cart
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	RemoveDiscountCodeMutation,
	RemoveDiscountCodeMutationVariables
>;
export const GetCartDocument = new TypedDocumentString(`
    query GetCart($storeContext: StoreContextInput!) {
  cart(storeContext: $storeContext) {
    ...Cart
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<GetCartQuery, GetCartQueryVariables>;
export const CartLineItemsRemoveDocument = new TypedDocumentString(`
    mutation CartLineItemsRemove($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $lineItemIds: [String!]!) {
  cartLineItemsRemove(
    storeContext: $storeContext
    id: $id
    lineItemIds: $lineItemIds
  ) {
    ...Cart
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	CartLineItemsRemoveMutation,
	CartLineItemsRemoveMutationVariables
>;
export const CartLineItemsUpdateDocument = new TypedDocumentString(`
    mutation CartLineItemsUpdate($storeContext: StoreContextInput!, $id: CartIdentifierInput!, $lineItems: [CartLineItemUpdateInput!]!) {
  cartLineItemsUpdate(storeContext: $storeContext, id: $id, lineItems: $lineItems) {
    ...Cart
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	CartLineItemsUpdateMutation,
	CartLineItemsUpdateMutationVariables
>;
export const GetContentPageDocument = new TypedDocumentString(`
    query GetContentPage($storeContext: StoreContextInput!, $path: String!, $previewID: String) {
  contentPage(storeContext: $storeContext, path: $path, previewID: $previewID) {
    ...ContentPageFragment
  }
}
    fragment ColumnBlockFragment on ColumnBlock {
  ...RichTextFragment
}
fragment ThreeColumnFragment on ThreeColumns {
  __typename
  id
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
  column3 {
    ...ColumnBlockFragment
  }
}
fragment TwoColumnFragment on TwoColumns {
  __typename
  id
  ratio
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
}
fragment ContentTeaserFragment on ContentTeaser {
  __typename
  id
  title
  content
  link {
    url
    newTab
    hash
  }
  image {
    filename
    alt
  }
  imagePosition
}
fragment FaqItemListFragment on FaqItemList {
  __typename
  id
  title
  faqItems: items {
    ...FaqItemFragment
  }
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
fragment UspListFragment on UspList {
  __typename
  id
  usps: items {
    ...UspFragment
  }
}
fragment ContentPageFragment on ContentPage {
  translatedPaths {
    locale
    path
  }
  breadcrumbs {
    ...BreadcrumbFragment
  }
  __typename
  id
  name
  seo {
    __typename
    title
    description
    ogTitle
    ogDescription
    ogImage
    twitterTitle
    twitterDescription
    twitterImage
  }
  hero {
    ...HeroFragment
  }
  usps {
    ...UspsFragment
  }
  body {
    ...TeaserRowFragment
    ...RichTextFragment
    ...TwoColumnFragment
    ...ThreeColumnFragment
    ...ProductRowFragment
    ...FaqItemListFragment
    ...UspListFragment
    ...ContentTeaserFragment
  }
}
fragment BreadcrumbFragment on Breadcrumb {
  name
  slug
  type
}
fragment FaqItemFragment on FaqItem {
  __typename
  id
  title
  content {
    ...RichTextFragment
  }
}
fragment HeroFragment on Hero {
  __typename
  id
  title
  imagePosition
  imageStyle
  bodyText {
    ...RichTextFragment
  }
  buttons {
    __typename
    id
    title
    variant
    link {
      url
      newTab
      hash
    }
  }
  image {
    filename
    alt
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}
fragment ProductCard on Product {
  name
  slug
  ribbon {
    kind
    label
  }
  image {
    url
    alt
  }
  startingPrice {
    gross {
      ...PriceFragment
    }
  }
  primaryVariant {
    id
    sku
    availability
    seller
    price {
      net {
        ...PriceFragment
      }
      gross {
        ...PriceFragment
      }
    }
  }
}
fragment ProductRowFragment on ProductRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  products {
    __typename
    id
    ...ProductCard
  }
}
fragment TeaserRowFragment on TeaserRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  items {
    __typename
    id
    content
    image {
      alt
      filename
    }
    link {
      url
    }
    title
  }
}
fragment UspsFragment on Usps {
  id
  usp1
  usp2
  usp3
  usp4
}
fragment UspFragment on Usp {
  __typename
  id
  title
  link {
    url
    newTab
    hash
  }
}`) as unknown as TypedDocumentString<
	GetContentPageQuery,
	GetContentPageQueryVariables
>;
export const GetProductDetailPageDocument = new TypedDocumentString(`
    query GetProductDetailPage($storeContext: StoreContextInput!, $slug: String!, $selectedSku: String) {
  product(storeContext: $storeContext, slug: $slug) {
    ...ProductDetailPageFragment
    localizedSlugs {
      locale
      slug
    }
  }
}
    fragment ProductBreadcrumbFragment on Product {
  name
  slug
  primaryCategory {
    name
    path
    ancestors {
      name
      path
    }
  }
}
fragment ButtonSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
}
fragment ColorSelectorGroupFieldFragment on ProductVariantDimensionColorOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
  }
  color
}
fragment ImageSelectorGroupFieldFragment on BaseProductVariantDimensionOption {
  __typename
  value
  label
  selected
  product {
    slug
  }
  variant {
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment ProductFormFragment on ProductVariantDimension {
  name
  label
  options {
    __typename
    ...ColorSelectorGroupFieldFragment
    ...ButtonSelectorGroupFieldFragment
    ...ImageSelectorGroupFieldFragment
  }
}
fragment ProductGalleryImageFragment on ProductImage {
  url
  alt
}
fragment ProductDetailPageFragment on Product {
  meta {
    title
    description
  }
  usps {
    id
    usp1
    usp2
    usp3
    usp4
  }
  name
  slug
  brand
  ribbon {
    kind
    label
  }
  primaryVariant {
    images {
      url
    }
  }
  primaryCategory {
    name
    slug
  }
  variant(sku: $selectedSku) {
    ...ProductVariantFragment
    price {
      gross {
        ...PriceFragment
      }
      net {
        ...PriceFragment
      }
    }
  }
  variantDimensions(selectedSku: $selectedSku) {
    ...ProductFormFragment
  }
  reviews {
    amount
    averageRating
  }
  ...ProductBreadcrumbFragment
}
fragment SpecificationAttributeFragment on ProductSpecificationAttribute {
  ... on ProductSpecificationBooleanAttribute {
    __typename
    label
    name
    description
    boolValue: value
  }
  ... on ProductSpecificationNumberAttribute {
    __typename
    label
    name
    description
    numberValue: value
  }
  ... on ProductSpecificationStringAttribute {
    __typename
    label
    name
    description
    stringValue: value
  }
  ... on ProductSpecificationEnumAttribute {
    __typename
    label
    name
    description
    enumValue: value {
      label
      value
    }
  }
  ... on ProductSpecificationEnumListAttribute {
    __typename
    label
    name
    description
    enumListValue: values {
      label
      value
    }
  }
  ... on ProductSpecificationDateTimeAttribute {
    __typename
    label
    name
    description
    dateTimeValue: value
  }
}
fragment ProductVariantFragment on ProductVariant {
  id
  name
  sku
  availability
  images {
    ...ProductGalleryImageFragment
  }
  deliveryTime
  description
  seller
  specifications {
    name
    label
    attributes {
      ...SpecificationAttributeFragment
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	GetProductDetailPageQuery,
	GetProductDetailPageQueryVariables
>;
export const GetHomePageDocument = new TypedDocumentString(`
    query GetHomePage($storeContext: StoreContextInput!, $version: ContentVersion!, $previewID: String) {
  contentPage(
    path: ""
    storeContext: $storeContext
    version: $version
    previewID: $previewID
  ) {
    __typename
    id
    name
    seo {
      __typename
      title
      description
      ogTitle
      ogDescription
      ogImage
      twitterTitle
      twitterDescription
      twitterImage
    }
    hero {
      ...HeroFragment
    }
    usps {
      ...UspsFragment
    }
    body {
      ...TeaserRowFragment
      ...RichTextFragment
      ...TwoColumnFragment
      ...ThreeColumnFragment
    }
  }
}
    fragment ColumnBlockFragment on ColumnBlock {
  ...RichTextFragment
}
fragment ThreeColumnFragment on ThreeColumns {
  __typename
  id
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
  column3 {
    ...ColumnBlockFragment
  }
}
fragment TwoColumnFragment on TwoColumns {
  __typename
  id
  ratio
  column1 {
    ...ColumnBlockFragment
  }
  column2 {
    ...ColumnBlockFragment
  }
}
fragment RichTextFragment on RichText {
  __typename
  id
  renderer
  content
}
fragment HeroFragment on Hero {
  __typename
  id
  title
  imagePosition
  imageStyle
  bodyText {
    ...RichTextFragment
  }
  buttons {
    __typename
    id
    title
    variant
    link {
      url
      newTab
      hash
    }
  }
  image {
    filename
    alt
  }
}
fragment TeaserRowFragment on TeaserRow {
  __typename
  id
  title
  viewMoreLink {
    url
    newTab
  }
  items {
    __typename
    id
    content
    image {
      alt
      filename
    }
    link {
      url
    }
    title
  }
}
fragment UspsFragment on Usps {
  id
  usp1
  usp2
  usp3
  usp4
}`) as unknown as TypedDocumentString<
	GetHomePageQuery,
	GetHomePageQueryVariables
>;
export const GetContentSlugsDocument = new TypedDocumentString(`
    query GetContentSlugs($storeContext: StoreContextInput!, $pageSize: Int!, $page: Int!, $version: ContentVersion) {
  pages(
    storeContext: $storeContext
    pageSize: $pageSize
    page: $page
    version: $version
  ) {
    results {
      ... on AbstractPage {
        __typename
        name
        path
      }
    }
    total
  }
}
    `) as unknown as TypedDocumentString<
	GetContentSlugsQuery,
	GetContentSlugsQueryVariables
>;
export const GetProductSlugsDocument = new TypedDocumentString(`
    query GetProductSlugs($storeContext: StoreContextInput!, $pageSize: Int!, $page: Int!) {
  products(storeContext: $storeContext, pageSize: $pageSize, page: $page) {
    total
    results {
      slug
    }
  }
}
    `) as unknown as TypedDocumentString<
	GetProductSlugsQuery,
	GetProductSlugsQueryVariables
>;
export const CartLineItemsAddDocument = new TypedDocumentString(`
    mutation CartLineItemsAdd($storeContext: StoreContextInput!, $id: CartIdentifierInput, $lineItems: [CartLineItemCreateInput!]!) {
  cartLineItemsAdd(storeContext: $storeContext, id: $id, lineItems: $lineItems) {
    ...Cart
  }
}
    fragment CartOverviewFragment on Cart {
  lineItems {
    id
    ...LineItemFragment
  }
  total {
    net {
      ...PriceFragment
    }
  }
  ...CartSummaryFragment
}
fragment CartSummaryFragment on Cart {
  ...RemoveDiscountCodeButtonFragment
  lineItems {
    quantity
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      ...CartTaxesFragment
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
  }
  discountCodes {
    id
    code
    amount {
      ...PriceFragment
    }
  }
}
fragment CartTaxesFragment on Tax {
  rate
  value {
    ...PriceFragment
  }
}
fragment LineItemFragment on LineItem {
  ...StockIndicatorFragment
  id
  quantity
  productSlug
  productName
  addedAt
  price {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  variant {
    name
    sku
    primaryImage {
      url
      alt
    }
  }
}
fragment RemoveDiscountCodeButtonFragment on Cart {
  id {
    id
    version
  }
}
fragment StockIndicatorFragment on LineItem {
  availableStock
}
fragment Cart on Cart {
  ...CartOverviewFragment
  id {
    id
    version
  }
  subTotal {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  total {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    taxes {
      value {
        ...PriceFragment
      }
      rate
    }
  }
  shippingCosts {
    net {
      ...PriceFragment
    }
    gross {
      ...PriceFragment
    }
    tax {
      value {
        ...PriceFragment
      }
      rate
    }
  }
}
fragment PriceFragment on Money {
  centAmount
  currency
}`) as unknown as TypedDocumentString<
	CartLineItemsAddMutation,
	CartLineItemsAddMutationVariables
>;
export const GetAddressValidateDocument = new TypedDocumentString(`
    query GetAddressValidate($storeContext: StoreContextInput!, $address: AddressInput!) {
  addressValidate(storeContext: $storeContext, address: $address) {
    ...AddressValidationResult
  }
}
    fragment AddressValidationResult on AddressValidationResult {
  valid
  suggestions {
    city
    country
    streetNumber
    streetNumberSuffix
    postalCode
    streetName
  }
}`) as unknown as TypedDocumentString<
	GetAddressValidateQuery,
	GetAddressValidateQueryVariables
>;
